import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import VueCountdown from '@chenfengyuan/vue-countdown'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/main.scss'

import '@/plugins/cleave.js'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
Vue.config.productionTip = false
var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)

Vue.component('apexchart', VueApexCharts)

Vue.component(VueCountdown.name, VueCountdown)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
