<template>
  <div class="min-vh-100 position-relative border">
    <div
      class="bg-primary pt-46px pb-24px"
      style="border-bottom-right-radius: 12px; border-bottom-left-radius: 12px"
    >
      <div
        class="text-white text-center"
        style="font-size: 1.25rem; font-weight: 600"
      >
        ผู้ดูแลที่เหมาะกับเคสของคุณ
      </div>
      <div class="px-14px pt-32px">
        <div
          class="d-flex flex-column align-items-center justify-content-between bg-white px-18px py-20px"
          style="border-radius: 10px"
        >
          <span class="text-primary mb-14px" style="font-size: 1.75rem">{{
            price.nurseLevel | nurseLevel
          }}</span>
          <span class="text-primary" style="font-size: 1rem"
            >ราคาเริ่มต้น {{ price.price | currency }}/{{
              price.priceUnit | unit
            }}</span
          >

          <!-- <div class="d-flex align-items-center">
          
          <div
            class="bg-primary rounded-circle d-flex justify-content-center align-items-center"
            style="min-width: 40px; min-height: 40px"
          >
            <IconMoney />
          </div>
          <div
            class="ml-20px"
            style="
              font-size: 1rem;
              font-weight: 500;
              line-height: 1.38;
              letter-spacing: -0.36px;
              color: #333;
            "
          >
            {{ price.nurseLevel | nurseLevel }}
          </div>
        </div>
        <div
          class="align-self-center"
          style="font-size: 14px; font-weight: 500; color: #464a53"
        >
          เริ่มต้น {{ price.price | currency }}/{{ price.priceUnit | unit }}
        </div> -->
        </div>
        <div
          class="d-flex text-white align-items-center justify-content-center px-16px pt-8px"
          style="font-size: 0.688rem; text-align: center"
        >
          <div class="px-6px">
            *ราคาค่าบริการเป็นราคานำเสนอเบื้องต้นจากให้ข้อมูลจากแบบสอบถามเท่านั้น
          </div>
        </div>
      </div>
    </div>
    <div class="pt-40px">
      <div
        class="px-64px"
        style="font-size: 1.25rem; font-weight: 500; text-align: center"
      >
        เริ่มต้นวางแผนดูแลคนไข้ กับทีมพยาบาลผู้เชี่ยวชาญ
        <span class="text-primary">ฟรี</span>
      </div>
      <b-button
        class="d-flex align-items-center mx-15px mt-35px"
        href="tel:092-498-9779"
        style="
          min-width: 343px;
          min-height: 82px;
          border-radius: 10px;
          border: solid 1px #f2f3f4;
          background-color: #f3f5f8;
        "
      >
        <img
          alt=""
          src="@/assets/icons/contact.png"
          srcset="@/assets/icons/contact.png 2x, @/assets/icons/contact.png 3x"
          style="min-width: 40px; min-height: 40px"
        />
        <div class="d-flex flex-column w-100 ml-22px">
          <span class="text-left text-navy" style="font-size: 1.063rem"
            >โทรหาเรา
          </span>
        </div>
        <img
          alt=""
          src="@/assets/icons/icons-chevron-right.png"
          srcset="
            @/assets/icons/icons-chevron-right.png 2x,
            @/assets/icons/icons-chevron-right.png 3x
          "
          style="min-width: 20px; min-height: 20px"
        />
      </b-button>
      <b-button
        class="d-flex align-items-center mx-15px mt-35px"
        tag="a"
        style="
          min-width: 343px;
          min-height: 82px;
          border-radius: 10px;
          border: solid 1px #f2f3f4;
          background-color: #f3f5f8;
        "
        @click="openLineUrl"
      >
        <img
          alt=""
          src="@/assets/icons/line.png"
          srcset="@/assets/icons/line.png 2x, @/assets/icons/line.png 3x"
          style="min-width: 40px; min-height: 40px"
        />
        <div class="d-flex flex-column w-100 ml-22px">
          <span class="text-left text-navy" style="font-size: 1.063rem"
            >ติดต่อผ่าน LINE
          </span>
        </div>
        <img
          alt=""
          src="@/assets/icons/icons-chevron-right.png"
          srcset="
            @/assets/icons/icons-chevron-right.png 2x,
            @/assets/icons/icons-chevron-right.png 3x
          "
          style="min-width: 20px; min-height: 20px"
        />
      </b-button>
    </div>
    <!-- <div
      class="px-20px py-30px"
      style="
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #f4f5f7;
      "
    >
      <div
        class="px-30px py-24px d-flex flex-column"
        style="
          border-radius: 8px;
          box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
          background-color: #fff;
        "
      >
        <div
          class="d-flex justify-content-between pb-20px"
          style="border-bottom: solid 1px #e7e7e7"
        >
          <span
            style="
              font-size: 16px;
              font-weight: 600;
              line-height: 1.88;
              color: #333;
            "
            >อัตราค่าบริการพยาบาลระดับต่างๆ</span
          >
          <b-icon
            class="align-self-center"
            icon="chevron-down"
            font-scale="1"
            style="color: #d8d8d8"
            variant=""
          ></b-icon>
        </div>
        <div class="d-flex flex-column py-20px">
          <div
            v-for="(price, i) in prices"
            :key="i"
            class="d-flex justify-content-between py-20px"
            style="font-size: 14px; font-weight: 500; color: #464a53"
          >
            <div class="" style="max-width: 49.5%">
              {{ price.nurseLevel | nurseLevel }}
            </div>
            <span style="max-width: 49.5%"
              >เริ่มต้น {{ price.price | currency }}/{{
                price.priceUnit | unit
              }}</span
            >
          </div>
        </div>
        <div
          style="
            font-size: 11px;
            font-weight: 500;
            line-height: 4.91;
            color: #464a53;
          "
        >
          *ราคาที่นำเสนอ เป็นราคานำเสนอเบื้องต้นเท่านั้น
        </div>
        <b-button
          pill
          class="mt-3"
          size="lg"
          variant="primary"
          href="tel:092-498-9779"
          style="font-size: 0.875rem; font-weight: 500"
          >สนใจบริการโทร 092-498-9779</b-button
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import { getPrices } from '@/resources/assessment.provider.js'
// import IconMoney from '@/components/Icons/IconMoney.vue'
import liff from '@line/liff'
export default {
  name: 'Result',
  components: {
    // IconMoney,
  },
  props: {
    nurseLevel: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      price: {},
      prices: [],
      pricing: [
        {
          name: 'พยาบาลดูแผู้ป่วยวิกฤต',
          price: 'เริ่มต้น 40,000/เดือน',
        },
        {
          name: 'พยาบาลวิชาชีพ',
          price: 'เริ่มต้น 30,000/เดือน',
        },
        {
          name: 'ผู้ช่วยพยาบาล',
          price: 'เริ่มต้น 25,000/เดือน',
        },
        {
          name: 'พนักงานผู้ช่วยพยาบาล',
          price: 'เริ่มต้น 20,000/เดือน',
        },
        {
          name: 'ผู้ช่วยเหลือคนไข้',
          price: 'เริ่มต้น 15,000/เดือน',
        },
      ],
    }
  },
  async mounted() {
    try {
      const { data } = await getPrices()
      this.prices = data.data.filter((p) => p.serviceId === 1)
      const index = this.prices.findIndex(
        (price) => price.nurseLevel === this.nurseLevel
      )
      this.price = this.prices[index]
      this.prices.splice(index, -1)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    close() {
      liff.closeWindow()
    },
    openLineUrl() {
      const url = 'https://lin.ee/plTmL3Z'
      if (window.liff.getOS() === 'web') {
        window.open(url, '_blank')
      } else {
        window.liff.openWindow({
          url,
          external: false,
        })
      }
    },
  },
  filters: {
    nurseLevel(nurseLevel) {
      if (nurseLevel === 'ICU') return 'พยาบาลดูแลผู้ป่วยวิกฤต'
      if (nurseLevel === 'NURSE') return 'พยาบาลวิชาชีพ'
      if (nurseLevel === 'PN') return 'ผู้ช่วยพยาบาล'
      if (nurseLevel === 'NA') return 'ผู้ช่วยเหลือคนไข้'
      if (nurseLevel === 'CG') return 'ผู้ดูแล'
      return ''
    },
    unit(unit) {
      if (unit === 'TIME') return 'ครั้ง'
      if (unit === 'SHIFT') return 'กะ'
      if (unit === 'DAY') return 'วัน'
      if (unit === 'WEEK') return 'สัปดาห์'
      if (unit === 'MONTH') return 'เดือน'
      return ''
    },
    currency(price) {
      if (Number.isInteger(price))
        return new Intl.NumberFormat('th-TH', {
          style: 'currency',
          currency: 'THB',
        })
          .format(price)
          .replace('฿', '')
          .replace(/\D00(?=\D*$)/, '')
      return price
    },
  },
}
</script>
<style lang="scss" scoped>
.text-black {
  color: #333333;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
