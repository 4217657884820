import http from './index'
import liff from '@line/liff'

export async function login(req) {
  // get access token
  await liff.init({
    liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
  })
  let userId = ''
  // console.log(liff.getLanguage())
  // console.log(liff.getVersion())
  // console.log(liff.isInClient())
  // console.log(liff.isLoggedIn())
  // console.log(liff.getOS())
  // console.log(liff.getLineVersion())
  if (liff.isLoggedIn()) {
    try {
      const profile = await liff.getProfile()
      userId = profile.userId
    } catch (error) {
      console.log(error)
    }
  }
  return http.post('/api/customer-dashboard/login', req, {
    headers: {
      'line-user-id': userId,
    },
  })
}

export function loginState(req) {
  return http.post('/api/customer-dashboard/login-state', req, {})
}

export function changePassword(req) {
  return http.post('/api/customer-dashboard/change-password', req, {})
}

export function requestOTP(req) {
  return http.post('/api/customer-dashboard/request-otp', req, {})
}

export function getUserByLine(lineUserId) {
  return http.get('/api/customer-dashboard/user', {
    headers: {
      'line-user-id': lineUserId,
    },
  })
}

export function verifyOTP(req) {
  return http.post('/api/customer-dashboard/verify-otp', req, {})
}

export async function refreshToken() {
  // get access token
  try {
    const { data } = await http.post(
      '/api/customer-dashboard/refresh-token',
      {
        refreshToken: localStorage.getItem('refreshToken'),
      },
      {
        headers: {},
      }
    )
    localStorage.setItem('token', data.token)
    localStorage.setItem('refreshToken', data.refreshToken)
  } catch (error) {
    console.log(error)
  }
}
