<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="py-40px">
      <div class="d-flex flex-column">
        <span
          class="text-left text-navy pl-16px"
          style="font-weight: bold; font-size: 1.25rem"
        >
          นโยบายคุ้มครองข้อมูลส่วนบุคคล<br />
          (Data Protection Policy)<br />
          บริษัท มา-ดู-แล จำกัด
        </span>
        <span
          class="text-left text-gray mt-20px mx-16px"
          style="font-size: 1rem"
        >
          บริษัท มา-ดู-แล จำกัด (“บริษัท” หรือ “เรา”)
          มุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของคุณ
          ซึ่งข้อมูลต่อไปนี้คือแนวทางที่เราใช้ในการปกป้องข้อมูลส่วนบุคคลที่ท่านให้เรา
          ไม่ว่าจะเป็น การเก็บรวบรวม การใช้ การเปิดเผย เรียกรวมกันว่า
          (“การประมวลผล”) และในระหว่างการเยี่ยมชมเว็บไซต์
          <a href="https://www.ma-doo-lae.com" target="_blank"
            >https://www.ma-doo-lae.com</a
          >
          หรือใช้แอพพลิเคชั่นของเรา Line Official Account @madoolae
          หรือร้องขอการบริการจากเรา นโยบายความเป็นส่วนตัวนี้รวมถึงข้อมูลต่างๆ
          ที่สามารถระบุถึงตัวท่านได้ไม่ว่าทางตรงหรือทางอ้อม
          และไม่ว่าจะได้รับจากท่านโดยตรงหรือเป็นการส่งต่อมาจากบุคคลที่สามก็ตาม
          (“ข้อมูล”) หรือ (“ข้อมูลส่วนบุคคล”)
          สำหรับข้อมูลส่วนบุคคลด้านสุขภาพของท่านนั้น
          นอกเหนือจากการปฏิบัติตามนโยบายนี้แล้ว
          เราจะยึดถือปฏิบัติตามบทบัญญัติของกฎหมาย กฎ ประกาศ
          คำสั่งหรือระเบียบของหน่วยงานที่มีอำนาจกำกับดูแลที่เกี่ยวข้อง
          หรือที่จะมีการแก้ไขเพิ่มเติม เราในฐานะผู้ควบคุมข้อมูลส่วนบุคคลของท่าน
          ได้จัดทำนโยบายความเป็นส่วนตัวนี้ขึ้น
          ซึ่งถือเป็นส่วนหนึ่งของข้อกำหนดและเงื่อนไขสำหรับการให้บริการเว็บไซต์ของเรา
          รวมทั้งการเข้ารับบริการของท่าน
          โดยในการใช้บริการดังกล่าวในแต่ละครั้งให้ถือว่าท่านได้อ่านและตกลงยอมรับนโยบายความเป็นส่วนตัวนี้
          ซึ่งมีรายละเอียดดังต่อไปนี้
          <br /><br />
          <span style="font-weight: bold">การเก็บรวบรวมข้อมูลส่วนบุคคล</span>
          <br /><br />
          1.บริษัทเก็บข้อมูลส่วนบุคคลของท่าน
          ที่สามารถระบุตัวตนได้ทั้งทางตรงและทางอ้อม
          เพื่อประโยชน์ต่อท่านในระยะเวลาที่เหมาะสมจำเป็นต่อการให้บริการ
          ในกรณีที่ท่านเป็นผู้ให้ข้อมูลกับบริษัท หรือร้องขอการบริการจากบริษัท
          ผ่านช่องทางเว็บไซต์ แอพพลิเคชั่นหรือ ช่องทางอื่นใดของบริษัท อาทิเช่น
          การดูผลตรวจและติดตามอาการของผู้ป่วย การทำธุรกรรมแบบออนไลน์
          การสมัครรับจดหมายข่าว การขอรับความช่วยเหลือพิเศษ
          รวมไปถึงการทำธุรกรรมแบบออฟไลน์ เช่น การลงทะเบียนผู้รับบริการที่บริษัท
          หรือจากความสมัครใจของท่านใน การทำแบบสอบถาม (Survey)
          หรือการโต้ตอบทางจดหมายอิเล็กทรอนิกส์ (E-mail) หรือการกรอก/
          ให้ข้อมูลประกอบการสมัครงาน หรือช่องทางการสื่อสารอื่นๆ
          ระหว่างบริษัทและท่าน
          <br /><br />
          2.บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่านจากบุคคลที่สาม เช่น
          ธุรกิจในเครือข่าย ตัวแทนจำหน่าย หรือผู้ให้บริการของบริษัทที่เกี่ยวข้อง
          หน่วยงานภาครัฐ ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม
          ประเภทของข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวมจากท่านจะขึ้นอยู่กับสถานการณ์ของการเก็บรวบรวม
          และประเภทของการบริการที่ท่านร้องขอจากบริษัท
          ข้อมูลส่วนบุคคลของท่านจะถูกนำมาใช้เพื่อให้การทำธุรกรรมออนไลน์หรือออฟไลน์
          หรือบริการที่ได้รับการร้องขอเสร็จสมบูรณ์
          ซึ่งข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวมโดยตรงจากท่าน หรือจากบุคคลที่สาม
          มีดังนี้
          <br /><br />
          &emsp;● ข้อมูลระบุตัวตน เช่น ชื่อ ภาพถ่าย เพศ วันเดือนปีเกิด
          หนังสือเดินทาง หมายเลขบัตรประชาชน หรือหมายเลขที่สามารถระบุตัวตนอื่นๆ
          <br /><br />
          &emsp;● ข้อมูลสำหรับการติดต่อ เช่น ที่อยู่ หมายเลขโทรศัพท์ และอีเมลล์
          <br /><br />
          &emsp;● ข้อมูลการชำระเงิน เช่น ข้อมูลการเรียกเก็บเงิน
          ข้อมูลบัตรเครดิตหรือเดบิต และรายละเอียดบัญชี ธนาคาร
          <br /><br />
          &emsp;● ข้อมูลการเข้ารับบริการ เช่น ข้อมูลทางการแพทย์
          ข้อมูลส่วนบุคคลของญาติ ความต้องการ เกี่ยวกับการดูแลผู้ป่วย
          และบริการเสริมอื่นๆ
          <br /><br />
          &emsp;● ข้อมูลการเข้าร่วมกิจกรรมทางการตลาด เช่น
          ข้อมูลการลงทะเบียนเพื่อร่วมกิจกรรมกับบริษัท
          <br /><br />
          &emsp;● ข้อมูลสถิติ เช่น จำนวนผู้ใช้บริการ และการเข้าชมเว็บไซต์
          <br /><br />
          &emsp;● ข้อมูลจากการเข้าใช้เว็บไซต์ของบริษัท เช่น Online Appointment
          System, Line Official Account, Contact Us
          <br /><br />
          &emsp;● ข้อมูลด้านสุขภาพ รายงานที่เกี่ยวกับสุขภาพกาย และสุขภาพจิต
          การดูแลสุขภาพของท่าน ผลการทดสอบจากห้องทดลอง ห้องปฏิบัติการ
          และการวินิจฉัย
          <br /><br />
          &emsp;● ข้อมูลที่เกี่ยวข้องกับการใช้ยาและการแพ้ยาของท่าน
          <br /><br />
          &emsp;● ข้อมูล Feedback และผลการรักษาที่ท่านให้ไว้
          <br /><br />
          &emsp;บริษัทจะไม่เก็บและใช้ข้อมูลที่มีความละเอียดอ่อน ของท่าน เช่น
          เชื้อชาติ ความเชื่อทางศาสนา ประวัติอาชญากรรม
          เว้นแต่เป็นไปตามที่ข้อบังคับและกฎหมายกำหนด หรือโดยความยินยอมของท่าน
          <br /><br />
          <span style="font-weight: bold">การใช้ข้อมูลส่วนบุคคล</span>
          <br /><br />
          บริษัทจะใช้ข้อมูลส่วนบุคคลของท่าน ตามวัตถุประสงค์ดังต่อไปนี้
          <br /><br />
          &emsp;● จัดหาบริการ หรือส่งมอบบริการของบริษัท
          <br /><br />
          &emsp;● การส่งข่าวสาร แนะนำบริการของบริษัท
          <br /><br />
          &emsp;●
          การประสานงานและส่งต่อข้อมูลซึ่งจะช่วยให้การส่งต่อผู้ป่วย/ผู้ใช้บริการมีความรวดเร็วขึ้น
          <br /><br />
          &emsp;● การยืนยันตัวตนของผู้ป่วย/ผู้ใช้บริการ
          <br /><br />
          &emsp;● ส่งข้อความแจ้งเตือน หรือการเสนอความช่วยเหลือจากบริษัท
          <br /><br />
          &emsp;● อำนวยความสะดวกและนำเสนอรายการสิทธิประโยชน์ต่างๆ แก่ท่าน
          <br /><br />
          &emsp;● จุดประสงค์ด้านการตลาด การส่งเสริมการขาย และลูกค้าสัมพันธ์ เช่น
          การส่งข้อมูลเกี่ยวกับโปรโมชั่น ผลิตภัณฑ์และบริการ รายการส่งเสริมการขาย
          และธุรกิจพันธมิตร
          <br /><br />
          &emsp;● เพื่อเป็นช่องทางในการสื่อสาร ตอบคำถาม หรือตอบสนองข้อร้องเรียน
          <br /><br />
          &emsp;● สำรวจความพึงพอใจของลูกค้า วิจัยตลาด วิเคราะห์ทางสถิติ
          ประมวลผลและแสดงผลเพื่อเป็นข้อมูลในการปรับปรุงผลิตภัณฑ์และบริการ
          หรือสร้างสรรค์ผลิตภัณฑ์และบริการใหม่ๆ ให้แก่
          ผู้ใช้บริการได้รับประโยชน์ยิ่งขึ้น
          <br /><br />
          &emsp;● วัตถุประสงค์ทางบัญชีหรือทางการเงิน เช่น
          การตรวจสอบการชำระเงินผ่านบัตรเครดิต
          การเรียกเก็บเงินและการตรวจสอบความถูกต้อง การขอคืนเงิน
          <br /><br />
          &emsp;● เพื่อวัตถุประสงค์ในการสมัครงาน การเป็นพนักงาน
          หรือวัตถุประสงค์อื่นใดที่เกี่ยวข้อง
          <br /><br />
          &emsp;● ปฏิบัติตามกฎของบริษัท
          <br /><br />
          &emsp;● ปฏิบัติตามกฎหมาย ข้อกำหนด ระเบียบ ข้อบังคับ หรือการร้องขอใดๆ
          จากหน่วยงานภาครัฐ เช่น การปฏิบัติตามหมายเรียกพยาน หรือคำสั่งศาล
          หรือการร้องขออื่นๆ ที่ถูกต้องตามกฎหมาย
          <br /><br />
          &emsp;● วัตถุประสงค์อื่นๆ
          ที่สนับสนุนการดำเนินการตามวัตถุประสงค์ข้างต้น
          หรือที่ได้รับความยินยอมจากท่านเป็นครั้งคราว
          <br /><br />
          <span style="font-weight: bold">การเปิดเผยข้อมูลส่วนบุคคล</span>
          <br /><br />
          บริษัทจะไม่จัดสรร เปิดเผย และ/หรือ
          ถ่ายโอนข้อมูลและข้อมูลส่วนบุคคลของผู้ใช้บริการ หรือ ผู้ดูแลให้แก่นิติ
          บุคคลหรือบุคคลใดๆ
          ทั้งในประเทศไทยและต่างประเทศเพื่อวัตถุประสงค์ในการนำไปใช้โดยอิสระ
          เว้นแต่
          <br /><br />
          &emsp;1.ผู้ใช้บริการ หรือ ผู้ดูแล ได้ร้องขอ และให้อำนาจแก่บริษัท หรือ
          <br /><br />
          &emsp;2.ข้อมูลที่จัดสรรนั้นเป็นไปเพื่อช่วยให้การเริ่มต้นธุรกรรมของผู้ใช้บริการ
          หรือ ผู้ดูแลสำเร็จลุล่วง หรือ
          <br /><br />
          &emsp;3.ข้อมูลที่จัดสรรให้แก่นิติบุคคลหรือบุคคลใดๆ
          ที่บริษัทเป็นคู่สัญญา เพื่อวัตถุประสงค์ในการวิเคราะห์ ข้อมูล และ/หรือ
          การปรับปรุงการให้บริการ หรือ
          <br /><br />
          &emsp;4.เป็นการเปิดเผยข้อมูลตามที่กฎหมายอนุญาต
          การเปิดเผยข้อมูลตามที่กฎหมายกำหนด การเปิดเผยข้อมูล ตามคำสั่งศาล
          และ/หรือ การเปิดเผยข้อมูลตามคำสั่งของหน่วยงานรัฐที่เกี่ยวข้อง
          เพื่อประโยชน์ในการสอบสวนหรือพิจารณาคดี
          ซึ่งผู้ให้บริการสามารถเปิดเผยข้อมูลดังกล่าวได้โดยชอบด้วยกฎหมาย
          <br /><br />
          <span style="font-weight: bold"
            >การเชื่อมโยงไปยังเว็บไซต์บุคคลที่สาม</span
          >
          <br /><br />
          &emsp;เว็บไซต์และแอพพลิเคชั่นบนมือถือของบริษัท
          อาจมีลิงก์เชื่อมไปยังเว็บไซต์บุคคลที่สาม หากท่านไปตามลิงก์เหล่านี้
          นโยบายความเป็นส่วนตัวนี้ไม่มีผลกับเว็บไซต์ของบุคคลที่สาม
          โปรดทราบว่าบริษัท ไม่สามารถรับผิดชอบใดๆ
          ต่อการใช้ข้อมูลส่วนบุคคลของท่านโดยบุคคลที่สามดังกล่าว
          เนื่องจากอยู่นอกการควบคุมของบริษัท
          <br /><br />
          <span style="font-weight: bold">
            การเก็บรักษาข้อมูลส่วนบุคคล และความปลอดภัย</span
          >
          <br /><br />
          &emsp;บริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน
          บริษัทจึงกำหนดมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสม
          และสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
          การเข้าถึง ทำลาย ใช้
          เปลี่ยนแปลงแก้ไขหรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือไม่ชอบด้วยกฎหมายให้เป็นไปตามที่กำหนดนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัท
          <br /><br />
          &emsp;ข้อมูลส่วนบุคคลของท่านจะถูกเก็บรักษาไว้นานเท่าที่จำเป็น
          เพื่อวัตถุประสงค์ต่างๆ ที่อธิบายไว้ในนโยบายความเป็นส่วนตัวนี้
          หรือภายใต้ข้อบังคับของกฎหมาย หรือเพื่อการดำเนินการทางกฎหมาย
          เมื่อพ้นระยะเวลาดังกล่าวแล้ว
          หากท่านไม่แสดงความยินยอมให้เราทำการประมวลผลข้อมูลส่วนบุคคลของท่านต่อไป
          เราจะดำเนินการทำลายข้อมูลส่วนบุคคลนั้นตามขั้นตอนการทำลายข้อมูลของเราและจะดำเนินการให้เสร็จโดยไม่ชักช้า
          <br /><br />
          &emsp;บริษัทจะใช้มาตรการทางเทคนิค
          และการบริหารจัดการที่เหมาะสมเพื่อป้องกันและรักษาความปลอดภัยของข้อมูลส่วนบุคคลของท่านที่เราเก็บรวบรวม
          เช่น เราใช้โปรโตคอลความปลอดภัย (Secure Sockets Layer: SSL)
          สำหรับการเข้ารหัสข้อมูลผ่านทางอินเตอร์เน็ต
          และเราจะจำกัดการเข้าถึงข้อมูลของท่าน
          ไม่ว่าที่จัดเก็บในระบบอินเตอร์เน็ตหรือในรูปแบบเอกสารไว้เฉพาะบุคลากรที่มีความจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลนั้น
          และจัดเก็บในสถานที่ที่มีระบบป้องกันการเข้าถึงโดยจำกัดบุคคลที่สามารถเข้าถึงได้สำหรับข้อมูลส่วนบุคคลที่อยู่ในรูปแบบเอกสาร
          <br /><br />
          <span style="font-weight: bold">สิทธิของเจ้าของข้อมูลส่วนบุคคล</span>
          <br /><br />
          &emsp;1.สิทธิในการเพิกถอนความยินยอม (right to withdraw consent) :
          ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทได้
          ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัท
          <br /><br />
          &emsp;2.สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access) :
          ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่านและ ขอให้บริษัท
          ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน
          รวมถึงขอให้บริษัทเปิดเผยการได้มาซึ่ง
          ข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อบริษัท ได้
          <br /><br />
          &emsp;3.สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to
          rectification) : ท่านมีสิทธิในการขอให้บริษัทแก้ไขข้อมูลที่ไม่ถูกต้อง
          หรือเพิ่มเติมข้อมูลที่ไม่สมบูรณ์
          <br /><br />
          &emsp;4.สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure) :
          ท่านมีสิทธิในการขอให้บริษัท ทำการลบข้อมูลของท่านด้วยเหตุบางประการได้
          <br /><br />
          &emsp;5.สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of
          processing) :
          ท่านมีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้
          <br /><br />
          &emsp;6.สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data
          portability) :
          ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัท
          ไปยังผู้ควบคุมข้อมูลรายอื่น หรือตัวท่านเองด้วยเหตุบางประการได้
          <br /><br />
          &emsp;7.สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object)
          :
          ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการใด
          <br /><br />
          &emsp;ท่านสามารถร้องขอการเข้าถึงหรือขอให้อัปเดตและแก้ไขข้อมูลส่วนบุคคลของท่าน
          รวมถึงสิทธิอื่นใดข้างต้น
          หรือสิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่ใช้บังคับ เช่น
          ขอสำเนาข้อมูลส่วนบุคคลของท่าน
          หรือขอให้ระงับการใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
          กรณีเห็นว่าข้อมูลส่วนบุคคลของท่านถูกนำไปใช้เกินขอบเขตวัตถุประสงค์การใช้งานที่แจ้งให้ทราบข้างต้น
          หรือไม่ได้รับความยินยอมจากท่าน ท่านต้องทำเป็นลายลักษณ์อักษร
          พร้อมแนบสำเนาหลักฐานเพื่อแสดงตัวตน เช่น สำเนาบัตรประจำตัวประชาชน สำเนา
          Passport และข้อมูลการติดต่อกลับ โดยส่งไปรษณีย์ตามที่อยู่ต่อไปนี้
          <br /><br />
          บริษัท มา-ดู-แล จำกัด
          <br /><br />
          เลขที่ 210 ซอย เรวดี61 ตำบล ตลาดขวัญ อำเภอเมืองนนทบุรี จ.นนทบุรี 11000
          <br /><br />
          เบอร์โทรศัพท์ 092-498-9779
          <br /><br />
          การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล
          <br /><br />
          &emsp;บริษัทจะทำการพิจารณาทบทวนนโยบายคุ้มครองข้อมูลส่วนบุคคลเป็นประจำเพื่อให้สอดคล้องกับแนวปฏิบัติ
          กฎหมายและข้อบังคับที่เกี่ยวข้อง ทั้งนี้
          หากมีการเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล บริษัทจะแจ้ง
          ให้ท่านทราบด้วยการ อัพเดตข้อมูลลงในเว็บไซต์ของบริษัท
          <a href="https://www.ma-doo-lae.com">https://www.ma-doo-lae.com</a>
          โดยเร็วที่สุด
          ทั้งนี้หากท่านมีคำถามข้อเสนอแนะและข้อร้องเรียนเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลโปรดติดต่อได้ที่อีเมลล์
          <a href="">management@ma-doo-lae.com</a>
          <br /><br />
          นโยบายคุ้มครองข้อมูลส่วนบุคคลมีผลใช้บังคับตั้งแต่วันที่ 1 มิถุนายน
          2565
          <br />
        </span>
        <br />
        <span class="text-gray text-right mx-16px">บริษัท มา-ดู-แล จำกัด</span>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'CookiePolicy',
  components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pl-16px {
  padding-left: 16px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
