<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/occupational-therapist.png"
        srcset="
          @/assets/images/occupational-therapist.png 2x,
          @/assets/images/occupational-therapist.png 3x
        "
      />
    </div>
    <div class="pt-45px pb-32px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          นักกายภาพบำบัด (Physical Therapist - PT)
        </span>
        <span
          class="text-left text-navy mt-20px mx-16px"
          style="font-size: 1rem"
        >
          บริการทำกายภาพบำบัดที่บ้าน (Home Visit) ด้วยทีมงานนักกายภาพบำบัด
          ที่มีประสบการณ์สามารถทำกายภาพที่เหมาะสมกับคนไข้แต่ละประเภทเพื่อให้สมรรถภาพร่างกายของผู้ป่วยดีขึ้น
          สามารถช่วยเหลือตัวเองได้มากขึ้น ผู้ป่วยและญาติ ไม่ต้องเสียเวลาเดินทาง
          และรอคิวในการใช้บริการที่โรงพยาบาล ผู้ป่วยจะรู้สึกผ่อนคลายมากขึ้น
          ในการได้รับการดูแลที่บ้าน
        </span>
        <ServiceEvoluation price="1,500" />
      </div>
    </div>
    <ServiceProcess />
    <HealthEvoluation />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ServiceEvoluation from '@/components/ServiceEvoluation.vue'
import ServiceProcess from '@/components/ServiceProcess.vue'
export default {
  name: 'OccupationalTherapist',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ServiceEvoluation,
    ServiceProcess,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-32px {
  padding-bottom: 32px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-45px {
  padding-top: 45px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-navy {
  color: #01234b;
}
</style>
