<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/ambulance.png"
        srcset="
          @/assets/images/ambulance.png 2x,
          @/assets/images/ambulance.png 3x
        "
      />
    </div>
    <div class="pt-45px pb-32px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          บริการรถพยาบาล (Ambulance)
        </span>
        <span
          class="text-left text-navy mt-20px mx-16px"
          style="font-size: 1rem"
        >
          ● บริการรถพยาบาล
          โดยทีมพยาบาลวิชาชีพทั่วประเทศด้วยรถพยาบาลที่มีอุปกรณ์ครบครันพร้อมบุคลากร
          และเจ้าหน้าที่
          ที่ผ่านการอบรมหลักสูตรการช่วยเหลือฉุกเฉินมีความชำนาญในการเคลื่อนย้าย
          และดูแลผู้ป่วย<br />
          ● บริการรถพยาบาล อาทิ เคลื่อนย้ายผู้ป่วยตามแพทย์นัด
          กลับบ้านพักกรุงเทพและต่างจังหวัด รถพยาบาล Stand by ในงานแข่งขันกีฬา
          เป็นต้น
        </span>
        <ServiceEvoluation price="2,000" />
      </div>
    </div>
    <ServiceProcess />
    <HealthEvoluation />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ServiceEvoluation from '@/components/ServiceEvoluation.vue'
import ServiceProcess from '@/components/ServiceProcess.vue'
export default {
  name: 'Ambulance',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ServiceEvoluation,
    ServiceProcess,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-32px {
  padding-bottom: 32px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-45px {
  padding-top: 45px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-navy {
  color: #01234b;
}
</style>
