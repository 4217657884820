<template>
  <div id="menu" class="pt-20px menu-container d-flex flex-column min-vh-100">
    <div class="d-flex justify-content-between">
      <div class="ml-24px">
        <img
          alt=""
          src="@/assets/logo/logo-ma-doo-lae.png"
          srcset="
            @/assets/logo/logo-ma-doo-lae@2x.png 2x,
            @/assets/logo/logo-ma-doo-lae@3x.png 3x
          "
        />
      </div>
      <div
        class="cursor-pointer d-flex align-items-center mr-24px"
        @click="closeMenu"
      >
        <img
          alt=""
          src="@/assets/icons/close.png"
          srcset="
            @/assets/icons/close@2x.png 2x,
            @/assets/icons/close@3x.png 3x
          "
        />
      </div>
    </div>
    <div class="d-flex flex-column pt-50px pb-84px pl-40px">
      <span
        class=""
        style="font-size: 0.875rem; color: #4a9afa; font-weight: 500"
        >เมนู</span
      >
      <span
        class="cursor-pointer pt-30px"
        style="font-size: 1.25rem; font-weight: 500"
        @click="redirectTo('Home')"
      >
        หน้าแรก
      </span>
      <span
        class="cursor-pointer pt-20px"
        style="font-size: 1.25rem; font-weight: 500"
        @click="redirectTo('Service')"
      >
        บริการของเรา
      </span>
      <span
        class="cursor-pointer pt-20px"
        style="font-size: 1.25rem; font-weight: 500"
        @click="redirectTo('About')"
      >
        เกี่ยวกับเรา
      </span>
      <span
        class="cursor-pointer pt-20px"
        style="font-size: 1.25rem; font-weight: 500"
        @click="redirectTo('Contact')"
      >
        ติดต่อเรา
      </span>
    </div>
    <b-button
      class="d-flex align-items-center mx-40px"
      href="tel:092-498-9779"
      style="
        min-width: 295px;
        min-height: 70px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
        background-color: #f3f5f8;
      "
    >
      <img
        alt=""
        src="@/assets/icons/contact.png"
        srcset="@/assets/icons/contact.png 2x, @/assets/icons/contact.png 3x"
        style="min-width: 40px; min-height: 40px"
      />
      <div class="d-flex flex-column w-100 ml-22px">
        <span class="text-left text-navy" style="font-size: 1.0625rem"
          >โทรหาเรา
        </span>
      </div>
      <img
        alt=""
        src="@/assets/icons/icons-chevron-right.png"
        srcset="
          @/assets/icons/icons-chevron-right.png 2x,
          @/assets/icons/icons-chevron-right.png 3x
        "
        style="min-width: 20px; min-height: 20px"
      />
    </b-button>
    <b-button
      class="mx-40px mt-20px"
      style="
        min-width: 295px;
        min-height: 70px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
        background-color: #f3f5f8;
      "
      @click="openLineUrl"
    >
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="@/assets/icons/line.png"
          srcset="@/assets/icons/line.png 2x, @/assets/icons/line.png 3x"
          style="min-width: 40px; min-height: 40px"
        />
        <div class="d-flex flex-column w-100 ml-22px">
          <span class="text-left text-navy" style="font-size: 1.0625rem"
            >ติดต่อผ่าน LINE
          </span>
        </div>
        <img
          alt=""
          src="@/assets/icons/icons-chevron-right.png"
          srcset="
            @/assets/icons/icons-chevron-right.png 2x,
            @/assets/icons/icons-chevron-right.png 3x
          "
          style="min-width: 20px; min-height: 20px"
        />
      </div>
    </b-button>
    <div
      class="mx-40px mt-26px"
      style="min-width: 295px; height: 1px; border: solid 1px #e3e8f1"
    ></div>
    <b-button
      class="mx-40px mt-60px"
      style="
        min-width: 295px;
        min-height: 82px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
        background-color: #deeefe;
        position: relative;
      "
      @click="redirectTo('WorkWithUs')"
    >
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="@/assets/icons/people.png"
          srcset="@/assets/icons/people.png 2x, @/assets/icons/people.png 3x"
          style="position: absolute; min-width: 120px; min-height: 120px"
        />
        <div class="d-flex flex-column w-100" style="margin-left: 110px">
          <span
            class="text-left text-navy"
            style="font-size: 1.0625rem; font-weight: 500"
            >สำหรับพยาบาล
          </span>
          <span
            class="text-left text-navy"
            style="font-size: 1rem; font-weight: 300"
            >ร่วมงานกับเรา
          </span>
        </div>
        <img
          alt=""
          src="@/assets/icons/icons-chevron-right.png"
          srcset="
            @/assets/icons/icons-chevron-right.png 2x,
            @/assets/icons/icons-chevron-right.png 3x
          "
          style="min-width: 20px; min-height: 20px"
        />
      </div>
    </b-button>
  </div>
</template>
<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
export default {
  data() {
    return {
      isShow: false,
      targetElement: null,
    }
  },
  props: {
    clickClose: { type: Function },
  },
  methods: {
    closeMenu() {
      this.clickClose()
      clearAllBodyScrollLocks()
    },
    redirectTo(pathName) {
      if (this.$router.history.current.name !== pathName) {
        this.$router.push({
          name: pathName,
        })
      } else {
        this.clickClose()
      }
      clearAllBodyScrollLocks()
    },
    openLineUrl() {
      const url = 'https://lin.ee/plTmL3Z'
      if (window.liff.getOS() === 'web') {
        window.open(url, '_blank')
      } else {
        window.liff.openWindow({
          url,
          external: false,
        })
      }
    },
  },
  mounted() {
    this.targetElement = document.querySelector('#menu')
    disableBodyScroll(this.targetElement)
  },
}
</script>
<style lang="scss" scoped>
.menu-container {
  height: 100vh;
  overflow: auto;
  max-width: 640px;
  padding-bottom: 60px;
}
.pl-40px {
  padding-left: 40px;
}
.pl-40px {
  padding-bottom: 84px;
}
.ml-22px {
  margin-left: 22px;
}
.mx-40px {
  margin-left: 40px;
  margin-right: 40px;
}
.text-navy {
  color: #01234b;
}
</style>
