<template>
  <div id="app" class="overflow-hidden d-flex justify-content-center">
    <div
      style="
        max-width: 640px;
        min-width: 375px;
        width: 100%;
        background-color: #fff;
      "
    >
      <notifications
        position="top center"
        classes="my-notification"
        :width="notiWidth"
      />
      <consentsModal />
      <router-view> </router-view>
    </div>
  </div>
</template>
<script>
import liff from '@line/liff'
import consentsModal from './components/consentsModal/index.vue'
export default {
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    })
    // remove this
    // if (!liff.isLoggedIn()) {
    //   await liff.login()
    // }
    // console.log(liff.getLanguage())
    // console.log(liff.getVersion())
    // console.log(liff.isInClient())
    // console.log(liff.isLoggedIn())
    // console.log(liff.getOS())
    // console.log(liff.getLineVersion())
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: '',
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  computed: {
    notiWidth() {
      if (this.windowWidth > 640) return 640 * 0.85 + 'px'
      return this.windowWidth * 0.85 + 'px'
    },
  },
  components: { consentsModal },
}
</script>
<style lang="scss">
// @import 'assets/main.scss';
// @import '~bootstrap/scss/bootstrap.scss';
// @import '~bootstrap-vue/src/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Sarabun', sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
}
.cursor-pointer {
  cursor: pointer;
}
.form-control.is-valid {
  background-image: none !important;
}
.form-control.is-invalid {
  background-image: none !important;
}
.invalid-feedback {
  display: block !important;
  font-size: 0.8rem !important;
  min-height: 0.8rem !important;
}

.my-notification {
  min-width: 100% !important;
  margin-top: 90px;
  padding: 13px 20px 13px 20px;
  // style for title line
  border-radius: 8px;
  background-color: #79ba27 !important;
  .notification-title {
    font-family: Sarabun !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #fff;
  }

  // style for content
  .notification-content {
  }

  // additional styling hook when using`type` parameter, i.e. this.$notify({ type: 'success', message: 'Yay!' })
  &.success {
    background-color: #79ba27 !important;
  }
  &.info {
    background-color: #007bff !important;
  }
  &.error {
    background-color: #dc3545 !important;
  }
}
.validation-provider > div {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

</style>
