<template>
  <div class="profile">
    <NavProfile />
    <div class="px-24px pt-30px pb-37px">
      <div class="title-1">ตั้งค่าบัญชี</div>
      <div class="subtitle-1 mt-5px">
        หากต้องการแก้ไขข้อมูลกรุณาติดต่อเจ้าหน้าที่มา-ดู-แล
      </div>
      <div class="card pt-28px pb-20px px-20px my-20px">
        <div class="title-2 pb-8px">ข้อมูลทั่วไป</div>
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">ชื่อ - นามสกุล</div>
          <div class="content-1">{{ user.firstName }} {{ user.lastName }}</div>
        </div>
        <!-- <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">ชื่อเล่น</div>
          <div class="content-1">Pete</div>
        </div> -->
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">วัน เดือน ปีเกิด</div>
          <div class="content-1">{{ user.dateOfBirth | dob }}</div>
        </div>
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">เพศ</div>
          <div class="content-1">{{ gender }}</div>
        </div>
        <div
          class="py-12px d-flex justify-content-between cursor-pointer"
          style="border-bottom: solid 1px #e3e8f1"
          @click="
            $router.push({
              name: 'OTP',
              query: { username: user.mobile, setPasscode: true },
            })
          "
        >
          <div>
            <div class="label-1">รหัสผ่าน</div>
            <div class="content-1">* * * * * *</div>
          </div>
          <IconArrowChevronRight class="align-self-center" />
        </div>
      </div>
      <div class="card pt-28px pb-20px px-20px mt-20px">
        <div class="title-2 pb-8px">ข้อมูลติดต่อ</div>
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">เบอร์โทรศัพท์มือถือ</div>
          <div class="content-1">{{ user.mobile | mobile }}</div>
        </div>
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">อีเมล</div>
          <div class="content-1">{{ user.email }}</div>
        </div>
        <div class="py-12px" style="border-bottom: solid 1px #e3e8f1">
          <div class="label-1">ที่อยู่</div>
          <div class="content-1">
            {{ address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavProfile from '@/components/Layout/NavProfile'
import IconArrowChevronRight from '@/components/Icons/IconArrowChevronRight'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
export default {
  components: {
    NavProfile,
    IconArrowChevronRight,
  },
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    const token = localStorage.getItem('token')
    this.user = jwtDecode(token)
  },
  methods: {},
  computed: {
    gender() {
      if (this.user) {
        if (this.user.gender === 'MALE') return 'ชาย'
        if (this.user.gender === 'FEMALE') return 'หญิง'
        return '-'
      }
      return ''
    },
    address() {
      if (
        !this.user.address ||
        !this.user.province ||
        !this.user.district ||
        !this.user.subDistrict
      )
        return '-'
      return `${this.user.address} ${this.user.subDistrict}, ${this.user.district}, ${this.user.province} ${this.user.postCode}`
    },
  },
  filters: {
    defaultVal: (value) => {
      if (!value) return '-'
      return value
    },
    dateUnix: (value) => {
      if (!value) return '-'
      return moment.unix(value).format('DD MMMM YYYY')
    },
    dob: (value) => {
      if (!value) return '-'
      const date = moment.unix(value)
      return date.locale('th').add(543, 'year').format('DD MMMM YYYY')
    },
    mobile: (value) => {
      if (!value) return '-'
      return [3, 3, 4].reduce((mobile, length) => {
        const sub = value.slice(0, length)
        const rest = value.slice(length)
        mobile += sub
        if (rest.length > 0) mobile += '-'
        value = rest
        return mobile
      }, '')
    },
    age: (value) => {
      if (!value) return '-'
      const date = moment.unix(value)
      return `${moment().diff(date, 'year')} years old`
    },
  },
}
</script>

<style scoped>
.profile {
  background-color: #f4f5f7;
  min-height: 100vh;
}
.title-1 {
  font-family: Sarabun;
  font-size: 1.375rem;
  font-weight: bold;
  color: #0f0a39;
}
.subtitle-1 {
  font-family: Sarabun;
  font-size: 0.875rem;
  color: #7b7890;
}

.title-2 {
  font-family: Thonburi;
  font-size: 1.125rem;
  font-weight: bold;
  color: #0f0a39;
}
.label-1 {
  font-size: 0.875rem;
  color: #7b7890;
}
.content-1 {
  font-family: Sarabun;
  font-size: 1rem;
  font-weight: 500;
  color: #2f2f2f;
}
.card {
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
  background-color: #fff;
}
</style>
