<template>
  <div
    class="d-flex flex-column bg-primary mt-40px mx-20px"
    style="border-radius: 10px"
  >
    <span class="text-center text-white mt-40px" style="font-size: 1rem">
      ราคาบริการเริ่มต้น
    </span>
    <span class="text-center text-white mt-16px" style="font-size: 2rem">
      {{ this.price }}<span style="font-size: 1rem"> บาท/ครั้ง</span>
    </span>
    <b-button
      pill
      class="text-primary bg-white mt-24px"
      size="lg"
      variant="primary"
      style="
        font-size: 0.875rem;
        font-weight: 500;
        margin-left: 60px;
        margin-right: 60px;
        margin-bottom: 40px;
      "
      @click="goToPreAssessmentPage"
      >ทำแบบประเมินการให้บริการ</b-button
    >
  </div>
</template>
<script>
export default {
  props: {
    price: {
      type: String,
    },
  },
  methods: {
    goToPreAssessmentPage() {
      this.$router.push({
        name: 'PreAssessment',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.mt-40px {
  margin-top: 40px;
}
.mt-16px {
  margin-top: 16px;
}
</style>
