<template>
  <ValidationObserver
    tag="div"
    class="min-vh-100 position-relative border"
    ref="form"
    v-slot="{ invalid, validate }"
  >
    <div class="bg-primary px-20px pt-60px d-flex pb-26px">
      <div @click="prev" class="cursor-pointer">
        <IconArrowBack class="align-self-center" />
      </div>
      <div
        class="text-white w-100 text-center"
        style="font-size: 1.25rem font-weight: 600"
      >
        {{ title }}
      </div>
      <div style="width: 24px"></div>
    </div>
    <div class="px-20px pt-30px d-flex flex-column pb-100px">
      <div
        class="mb-18px"
        style="
          font-size: 1.25rem;
          color: #333333;
          font-weight: 600;
          line-height: 1.5;
        "
      >
        กรอกข้อมูลติดต่อ
      </div>
      <ValidationProvider
        vid="name"
        name="name"
        rules="required|max:200"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอก ชื่อ-นามสกุล',
          max: 'ความยาวไม่เกิน 200',
        }"
      >
        <div class="pt-24px">
          <div class="label-input mb-9px">ชื่อ - นามสกุล</div>
          <b-form-input
            id="name"
            v-model="name"
            :state="isValid(errors)"
            :maxlength="200"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="mobile"
        name="mobile"
        rules="required|length:12|mobileStartWithZero"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอกหมายเลขโทรศัพท์มือถือ',
          length: 'โปรดกรอกหมายเลขที่ถูกต้อง 10 หลัก',
        }"
      >
        <div class="pt-20px">
          <div class="label-input mb-9px">เบอร์โทรศัพท์มือถือ</div>
          <b-form-input
            id="phone"
            v-cleave="{
              blocks: [3, 3, 4],
              delimiter: '-',
            }"
            type="text"
            maxlength="12"
            v-model="mobile"
            :state="isValid(errors)"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="email"
        name="email"
        rules="required|email|max:200"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอกอีเมล',
        }"
      >
        <div class="pt-20px">
          <div class="label-input mb-9px">อีเมล</div>
          <b-form-input
            id="email"
            v-model="email"
            :state="isValid(errors)"
            :maxlength="200"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </div>
      </ValidationProvider>

      <ValidationProvider
        tag="div"
        v-if="!isAcceptedConsent"
        vid="consent-checkbox"
        name="consent-checkbox"
        rules="checkbox"
        v-slot="{ errors }"
        :customMessages="{
          required: 'โปรดกรอกอีเมล',
        }"
        mode="lazy"
        class="mt-27px"
      >
        <b-form-checkbox
          id="consent-checkbox"
          v-model="acceptConsent"
          name="consent-checkbox"
          :state="isValid(errors)"
          :value="true"
          :unchecked-value="false"
          :class="{ 'label-accept-consent-invalid': isValid(errors) }"
          class="label-accept-consent"
        >
          ท่านรับรองว่าข้อมูลดังกล่าวเป็นของท่านจริงและยินยอมให้บริษัทเก็บรวบรวมและใช้ข้อมูลข้างต้นในการติดต่อท่านเพื่อให้คำปรึกษาและนำเสนอข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัท
          โปรดอ่านเพิ่มเติมเกี่ยวกับประกาศนโยบายความเป็นส่วนตัวของบริษัทอย่างละเอียดได้ที่
          <b-link :to="{ name: 'DataProtectionPolicy' }" target="_blank"
            >นโยบายคุ้มครองข้อมูลส่วนบุคคล</b-link
          >
          และ
          <b-link :to="{ name: 'CookiePolicy' }" target="_blank"
            >นโยบายคุกกี้</b-link
          >
        </b-form-checkbox>
      </ValidationProvider>
    </div>
    <div
      class="d-flex justify-content-center position-absolute pb-35px w-100"
      style="bottom: 0"
    >
      <b-button
        v-if="invalid"
        @click="validate"
        pill
        size="lg"
        class="px-100px border-0 pt-12px pb-14px"
        variant=""
        style="font-size: 0.875rem; font-weight: 500; background-color: #d6d9de"
        >ยืนยัน</b-button
      >
      <b-button
        v-else
        @click="next"
        pill
        size="lg"
        class="px-100px border-0 pt-12px pb-14px"
        variant="primary"
        style="font-size: 0.875rem; font-weight: 500"
        >ยืนยัน</b-button
      >
    </div>
  </ValidationObserver>
</template>
<script>
import { postAssessment } from '@/resources/assessment.provider.js'
import IconArrowBack from '@/assets/icons/ic-arrow-back.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, length, max } from 'vee-validate/dist/rules'
import { getConsent, acceptConsent } from '@/resources/consent.provider.js'
extend('email', {
  ...email,
  message: 'โปรดกรอกอีเมลที่ถูกต้อง',
})
extend('required', {
  ...required,
  message: 'โปรดระบุ',
})
extend('max', max)
extend('mobileStartWithZero', {
  validate: (value) => /^0/.test(value),
  message: 'โปรดกรอกหมายเลขที่ถูกต้อง 10 หลัก',
})
extend('length', {
  ...length,
})
extend('checkbox', {
  validate: (value) => value,
  message: 'โปรดกรอกอีเมลที่ถูกต้อง',
})
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'Form',
  components: { IconArrowBack, ValidationProvider, ValidationObserver },
  data() {
    return {
      prevRoute: null,
      name: '',
      mobile: '',
      email: '',
      acceptConsent: false,
      isAcceptedConsent: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.prevRoute = from
      const { data } = await getConsent(
        'COOKIE_PDPA',
        localStorage.getItem('userId')
      )
      vm.isAcceptedConsent = data.acceptedVersion === data.version
    })
  },
  methods: {
    ...mapActions(['clearAssessment']),
    async next() {
      if (!this.isAcceptedConsent) {
        await this.accept()
      }

      const services = this.assessment.services.map((service) => service.id)
      let questionnaires = {}
      if (Object.entries(this.assessment.questionnaires).length >= 6) {
        for (const [key, value] of Object.entries(
          this.assessment.questionnaires
        )) {
          if (Array.isArray(value)) {
            questionnaires[`Q${key}`] = value.map((v) => {
              if (v.id === 999) {
                return { answer: v.id, textbox: v.value }
              }
              if (v.options && v.selected) {
                return { answer: v.id, subanswer: v.selected.id }
              }

              return {
                answer: v.id,
              }
            })
          } else {
            questionnaires[`Q${key}`] = [{ answer: value.id }]
          }
        }
        const request = {
          name: this.name,
          mobile: this.mobile.replaceAll('-', ''),
          email: this.email,
          serviceIds: services,
          ...questionnaires,
          userId: localStorage.getItem('userId'),
        }
        try {
          const { data } = await postAssessment(request)
          const price = data
          this.clearAssessment()
          this.$router.push({
            name: 'PreAssessmentResult',
            query: { nurseLevel: price.nurseLevel },
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        const request = {
          name: this.name,
          mobile: this.mobile.replaceAll('-', ''),
          email: this.email,
          serviceIds: services,
          q1: [{}],
          q2: [{}],
          q3: [{}],
          q4: [{}],
          q5: [{}],
          q6: [{}],
          userId: localStorage.getItem('userId'),
        }
        try {
          await postAssessment(request)
          this.clearAssessment()
          this.$router.push({
            name: 'PreAssessmentSuccess',
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    prev() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute)
      } else {
        this.$router.push({
          name: 'PreAssessment',
        })
      }
    },
    isValid(errors) {
      if (errors && errors.length > 0) return false
      return null
    },
    close() {
      window.close()
    },
    async accept() {
      if (!localStorage.getItem('userId')) {
        localStorage.setItem('userId', uuidv4())
      }
      try {
        await acceptConsent('COOKIE_PDPA', localStorage.getItem('userId'))
      } catch (error) {
        console.log(error)
      }
    },
  },
  computed: {
    ...mapGetters(['assessment']),
    title() {
      if (Object.entries(this.assessment.questionnaires).length >= 6)
        return 'แบบประเมินค่าบริการ'
      return 'หาพยาบาล Ma Doo Lae'
    },
  },
}
</script>
<style lang="scss" scoped>
.label-input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #858585;
}

.label-accept-consent {
  font-family: Sarabun;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #858585;
}
.label-accept-consent-invalid {
  color: #f00 !important;
}
</style>
