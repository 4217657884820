<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px" style="position: relative">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/contact.png"
        srcset="@/assets/images/contact.png 2x, @/assets/images/contact.png 3x"
      />
      <div
        class="text-white text-center centered"
        style="font-size: 1.25rem; font-weight: 600; width: 100%"
      >
        ให้การดูแลเป็นเรื่องง่าย<br />
        สำหรับคุณ
      </div>
    </div>
    <ValidationObserver
      class="d-flex flex-column mt-27px mx-20px"
      ref="form"
      v-slot="{ invalid, reset, validate }"
      style="border-radius: 10px; background-color: #01234b"
    >
      <span
        class="text-center text-white mt-23px"
        style="font-size: 1.25rem; font-weight: 600"
      >
        ติดต่อเรา
      </span>
      <span
        class="text-left text-white mt-20px pl-20px"
        style="font-size: 1.125rem; font-weight: 600"
      >
        กรอกข้อมูลเพื่อให้เราติดต่อกลับ
      </span>
      <ValidationProvider
        class="validation-provider"
        vid="name"
        name="name"
        rules="required|max:200"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอก ชื่อ-นามสกุล',
          max: 'ความยาวไม่เกิน 200',
        }"
      >
        <b-form-group
          label="ชื่อ - นามสกุล"
          label-for="name"
          class="text-white py-20px"
          style="font-size: 0.875rem"
        >
          <b-form-input
            id="name"
            v-model="name"
            :state="isValid(errors)"
            :maxlength="200"
            trim
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        class="validation-provider"
        vid="mobile"
        name="mobile"
        rules="required|length:12|mobileStartWithZero"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอกหมายเลขโทรศัพท์มือถือ',
          length: 'โปรดกรอกหมายเลขที่ถูกต้อง 10 หลัก',
        }"
      >
        <b-form-group
          label="เบอร์โทรศัพท์มือถือ"
          label-for="mobile"
          class="text-white py-20px"
          style="font-size: 0.875rem"
        >
          <b-form-input
            id="mobile"
            v-cleave="{
              blocks: [3, 3, 4],
              delimiter: '-',
            }"
            type="text"
            v-model="mobile"
            :state="isValid(errors)"
            :maxlength="12"
            trim
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        class="validation-provider"
        vid="email"
        name="email"
        rules="required|email|max:200"
        v-slot="{ errors }"
        mode="lazy"
        :customMessages="{
          required: 'โปรดกรอกอีเมล',
        }"
      >
        <b-form-group
          label="อีเมล"
          label-for="input-email"
          class="text-white py-20px"
          style="font-size: 0.875rem"
        >
          <b-form-input
            id="email"
            v-model="email"
            :state="isValid(errors)"
            :maxlength="200"
            trim
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-if="!isAcceptedConsent"
        vid="consent-checkbox"
        name="consent-checkbox"
        rules="checkbox"
        v-slot="{ errors }"
        :customMessages="{
          required: 'โปรดกรอกอีเมล',
        }"
        mode="lazy"
        class="mt-27px px-20px"
      >
        <b-form-checkbox
          id="consent-checkbox"
          v-model="acceptConsent"
          name="consent-checkbox"
          :state="isValid(errors)"
          :value="true"
          :unchecked-value="false"
          :class="{ 'label-accept-consent-invalid': isValid(errors) }"
          class="label-accept-consent"
        >
          ท่านรับรองว่าข้อมูลดังกล่าวเป็นของท่านจริงและยินยอมให้บริษัทเก็บรวบรวมและใช้ข้อมูลข้างต้นในการติดต่อท่านเพื่อให้คำปรึกษาและนำเสนอข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัท
          โปรดอ่านเพิ่มเติมเกี่ยวกับประกาศนโยบายความเป็นส่วนตัวของบริษัทอย่างละเอียดได้ที่
          <b-link :to="{ name: 'DataProtectionPolicy' }" target="_blank"
            >นโยบายคุ้มครองข้อมูลส่วนบุคคล</b-link
          >
          และ
          <b-link :to="{ name: 'CookiePolicy' }" target="_blank"
            >นโยบายคุกกี้</b-link
          >
        </b-form-checkbox>
      </ValidationProvider>

      <div class="d-flex justify-content-center w-100">
        <b-button
          v-if="invalid"
          @click="validate"
          pill
          size="lg"
          class="text-white mt-30px px-100px pt-12px pb-14px mb-40px"
          style="
            font-size: 0.875rem;
            font-weight: 500;
            background-color: #d6d9de;
          "
          variant=""
          >ส่ง</b-button
        >
        <b-button
          v-else
          @click="send(reset)"
          pill
          size="lg"
          type="reset"
          class="text-white bg-primary mt-30px px-100px pt-12px pb-14px mb-40px"
          variant="primary"
          style="font-size: 0.875rem; font-weight: 500"
          >ส่ง</b-button
        >
      </div>
    </ValidationObserver>
    <ConsultExpert />
    <JoinUs />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ConsultExpert from '@/components/ConsultExpert.vue'
import JoinUs from '@/components/JoinUs.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, length, max } from 'vee-validate/dist/rules'
import { postAssessment } from '@/resources/assessment.provider.js'
import { getConsent, acceptConsent } from '@/resources/consent.provider.js'
import { v4 as uuidv4 } from 'uuid'
extend('email', {
  ...email,
  message: 'โปรดกรอกอีเมลที่ถูกต้อง',
})
extend('required', {
  ...required,
  message: 'โปรดระบุ',
})
extend('max', max)
extend('mobileStartWithZero', {
  validate: (value) => /^0/.test(value),
  message: 'โปรดกรอกหมายเลขที่ถูกต้อง 10 หลัก',
})
extend('length', {
  ...length,
})
export default {
  name: 'Contact',
  components: {
    Header,
    Footer,
    ConsultExpert,
    JoinUs,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: '',
      mobile: '',
      email: '',
      isAcceptedConsent: false,
      acceptConsent: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.prevRoute = from
      const { data } = await getConsent(
        'COOKIE_PDPA',
        localStorage.getItem('userId')
      )
      vm.isAcceptedConsent = data.acceptedVersion === data.version
    })
  },
  methods: {
    async send(reset) {
      if (!this.isAcceptedConsent && this.acceptConsent) {
        await this.accept()
      }
      const request = {
        name: this.name,
        mobile: this.mobile.replaceAll('-', ''),
        email: this.email,
        serviceIds: [1],
        q1: [{}],
        q2: [{}],
        q3: [{}],
        q4: [{}],
        q5: [{}],
        q6: [{}],
        userId: localStorage.getItem('userId'),
      }
      try {
        await postAssessment(request)
        this.name = ''
        this.mobile = ''
        this.email = ''
        reset()
        this.$notify({
          type: 'success',
          title: 'ส่งข้อมูลสำเร็จ',
          duration: 2000,
        })
        this.isAcceptedConsent = true
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          title: 'ส่งข้อมูลไม่สำเร็จ',
          duration: 2000,
        })
      }
    },
    isValid(errors) {
      if (errors && errors.length > 0) return false
      return null
    },
    toast(toaster, append = false) {
      this.counter++
      this.$bvToast.toast(`Toast ${this.counter} body content`, {
        title: `Toaster ${toaster}`,
        toaster: toaster,
        variant: 'success',
        solid: true,
        appendToast: append,
      })
    },
    async accept() {
      if (!localStorage.getItem('userId')) {
        localStorage.setItem('userId', uuidv4())
      }
      try {
        await acceptConsent('COOKIE_PDPA', localStorage.getItem('userId'))
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pt-22px {
  padding-top: 22px;
}
.pt-34px {
  padding-top: 34px;
}
.py-20px {
  padding-left: 20px;
  padding-right: 20px;
}
.px-0px {
  padding-top: 0px;
  padding-bottom: 0px;
}
.mt-27px {
  margin-top: 27px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-47px {
  margin-top: 47px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.label-input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #858585;
}
.text-black {
  color: #333333;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}

.label-accept-consent {
  font-family: Sarabun;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.label-accept-consent-invalid {
  color: #f00 !important;
}
</style>
