<template>
  <div>
    <div v-if="isShow">
      <Menu
        style="
          background-color: white;
          position: fixed;
          top: 0;
          min-width: 375px;
          width: 100vw;
          z-index: 100;
        "
        :click-close="clickClose"
      />
    </div>
    <div v-else class="d-flex justify-content-between">
      <div class="ml-24px">
        <img
          alt=""
          src="@/assets/logo/logo-ma-doo-lae.png"
          srcset="
            @/assets/logo/logo-ma-doo-lae@2x.png 2x,
            @/assets/logo/logo-ma-doo-lae@3x.png 3x
          "
        />
      </div>
      <div
        class="cursor-pointer d-flex align-items-center mr-24px"
        @click="clickOpen"
      >
        <img
          alt=""
          src="@/assets/icons/hamberger.png"
          srcset="
            @/assets/icons/hamberger@2x.png 2x,
            @/assets/icons/hamberger@3x.png 3x
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import Menu from '@/views/Menu.vue'
export default {
  components: {
    Menu,
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    clickOpen() {
      this.isShow = true
    },
    clickClose() {
      this.isShow = false
    },
  },
}
</script>
