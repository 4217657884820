<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/nurse.png"
        srcset="@/assets/images/nurse.png 2x, @/assets/images/nurse.png 3x"
      />
    </div>
    <div class="pt-45px pb-32px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          พยาบาล (Nurse)
        </span>
        <span
          class="text-left text-navy mt-20px mx-16px"
          style="font-size: 1rem"
        >
          ให้พยาบาลวิชาชีพดูแลคนที่คุณรักที่บ้าน -
          ให้บริการแบ่งเป็นการดูแลผู้ป่วยกึ่งวิกฤต (ICU) พยาบาลวิชาชีพ (Nurse)
          ผู้ช่วยพยาบาล (PN) ผู้ช่วยเหลือคนไข้ (NA) ผู้ดูแล (CG)
          ซึ่งพยาบาลและทีมเจ้าหน้าที่
          ที่ดูแลคนไข้ของเราจะต้องได้รับใบประกอบวิชาชีพการพยาบาลจากสภาการพยาบาล
          และผู้ดูแลคนไข้จะต้องผ่านหลักสูตรการดูแลคนไข้
          และมีใบประกอบการจบหลักสูตร
          และใบประกาศนียบัตรผ่านการอบรมเท่านั้นจึงสามารถดูแลช่วยเหลือคนไข้ที่คุณรักให้เกิดความเข้าใจและใช้ความรู้ที่ถูกหลักและเหมาะสมในการทำกิจวัตรประจำวันที่บ้าน
          เช่นการเคลื่อนย้าย การเข้าห้องน้ำ การจัดยา การทำแผล การวัดสัญญาณชีพ
          การให้อาหารทางสายยาง หรือคนไข้ที่ต้องได้รับการดูแลอย่างใกล้ชิด
          และประเมินการดูแลโดยพยาบาลวิชาชีพเป็นต้น
        </span>
        <ServiceEvoluation price="2,000" />
      </div>
    </div>
    <ServiceProcess />
    <HealthEvoluation />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ServiceEvoluation from '@/components/ServiceEvoluation.vue'
import ServiceProcess from '@/components/ServiceProcess.vue'
export default {
  name: 'Nurse',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ServiceEvoluation,
    ServiceProcess,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-32px {
  padding-bottom: 32px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-45px {
  padding-top: 45px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-navy {
  color: #01234b;
}
</style>
