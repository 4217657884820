import axios from 'axios'
import router from './../router'
import { refreshToken } from './auth.provider'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
})

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && originalConfig._retry) {
        console.log('logout')
        router.push({ name: 'Login' })
      }
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          await refreshToken()
          return http(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default http
