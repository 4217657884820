<template>
  <div class="pt-70px pb-60px" style="background-color: #deeefe">
    <div class="d-flex flex-column">
      <span class="text-center text-navy" style="font-size: 1.5rem">
        สำหรับพยาบาล ผู้ช่วยพยาบาล <br />
        ผู้ช่วยเหลือคนไข้<br />
        ตลอดจนนักกายภาพบำบัด<br />
        ร่วมงานกับเราได้ที่นี่
      </span>
      <b-button
        pill
        class="text-white bg-primary mt-40px mx-103px"
        size="lg"
        variant="primary"
        style="font-size: 0.875rem; font-weight: 500"
        @click="goToWorkWithUsPage"
        >ร่วมงานกับเราคลิก</b-button
      >
      <img
        class="pt-50px px-24px"
        alt=""
        src="@/assets/images/home-3.png"
        srcset="@/assets/images/home-3.png 2x, @/assets/images/home-3.png 3x"
      />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToWorkWithUsPage() {
      this.$router.push({
        name: 'WorkWithUs',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.mt-40px {
  margin-top: 40px;
}
.mx-103px {
  margin-left: 103px;
  margin-right: 103px;
}
.text-navy {
  color: #01234b;
}
</style>
