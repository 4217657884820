<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/medical-procedure.png"
        srcset="
          @/assets/images/medical-procedure.png 2x,
          @/assets/images/medical-procedure.png 3x
        "
      />
    </div>
    <div class="pt-45px pb-32px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          หัตถการทางการพยาบาล (Medical Procedure)
        </span>
        <span
          class="text-left text-navy mt-20px mx-16px"
          style="font-size: 1rem"
        >
          การทำหัตถการทางการพยาบาล หมายถึงหัตถการขนาดเล็ก อาทิ
          การทำแผลทั่วไปใส่สายให้อาหารทางจมูก
          การเปลี่ยนสายสวนปัสสาวะชนิดสวนคาสายต่อลงถุง และสวนปัสสาวะทิ้ง
          จำเป็นอย่างยิ่งที่ต้องใช้บุคคลากรที่มีความรู้
          และเชี่ยวชาญมุ่งเน้นความปลอดภัยใช้หลักปราศจากเชื้อที่ได้มาตรฐานความสะดวก
          ไม่ต้องไปโรงพยาบาล
        </span>
        <ServiceEvoluation price="1,000" />
      </div>
    </div>
    <ServiceProcess />
    <HealthEvoluation />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ServiceEvoluation from '@/components/ServiceEvoluation.vue'
import ServiceProcess from '@/components/ServiceProcess.vue'
export default {
  name: 'OccupationalTheapist',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ServiceEvoluation,
    ServiceProcess,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-32px {
  padding-bottom: 32px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-45px {
  padding-top: 45px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-navy {
  color: #01234b;
}
</style>
