<template>
  <div class="d-flex flex-column min-vh-100 page-wrapper">
    <NavProfile />

    <!-- Health status card -->
    <div class="px-4 pt-4 page-wrapper">
      <div
        class="card shadow-sm rounded-component p-4 d-flex flex-column mb-4"
        :class="
          latestNurseNote.health === 'RED'
            ? 'danger-gradient'
            : latestNurseNote.health === 'YELLOW'
            ? 'warning-gradient'
            : 'healthy-gradient'
        "
      >
        <div>{{fullName}}</div>
        <div class="d-flex flex-row justify-content-between">
          <div class="align-self-center"
            :class="
            latestNurseNote.health === 'RED'
            ? 'danger-text'
            : latestNurseNote.health === 'YELLOW'
            ? 'warning-text'
            : 'healthy-text'
            ">สถานะผู้ป่วย</div>
          <div
            class="text-center status-pill"
            :class="
              latestNurseNote.health === 'RED'
                ? 'danger-pill'
                : latestNurseNote.health === 'YELLOW'
                ? 'warning-pill'
                : 'healthy-pill'
            "
          >
            {{
              latestNurseNote.health === 'RED'
                ? 'แดง'
                : latestNurseNote.health === 'เหลือง'
                ? 'warning-pill'
                : 'เขียว'
            }}
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">ชีพจร:</div>
                <div v-if="hasLatest">{{ latestPulse }} ครั้ง/นาที</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
              <div v-if="hasLatest">
                <apexchart
                  type="area"
                  :options="pulseOptions"
                  :series="pulseSeries"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">อุณหภูมิ:</div>
                <div v-if="latestTemparature">{{ latestTemparature }} °C</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
              <div v-if="hasLatest">
                <apexchart
                  type="area"
                  :options="temperatureOptions"
                  :series="temperatureSeries"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">ความเข้มข้นออกซิเจนในเลือด:</div>
                <div v-if="hasLatest">{{ latestOxygenSaturation }} %</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
              <div v-if="hasLatest">
                <apexchart
                  type="area"
                  :options="oxygenOptions"
                  :series="oxygenSaturationSeries"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">อัตราการหายใจ:</div>
                <div v-if="hasLatest">{{ latestRespiration }}</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">น้ำเข้า/น้ำออก:</div>
                <div v-if="hasLatest">{{ latestTotalDailyFluid }}</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div class="mr-2">ความดันโลหิต:</div>
                <div v-if="hasLatest">{{ latestBloodPressure }} มม.ปรอท</div>
                <div v-else class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div class="text-muted small">
                {{ formatToTHDate(latestNurseNote.lastUpdated) }}
              </div>
              <div v-if="hasLatest" class="mt-30px">
                <apexchart
                  type="line"
                  :options="upperBloodPressureOptions"
                  :series="upperBloodPressureSeries"
                />
              </div>
              <!-- <div v-if="hasLatest" class="mt-30px">
                <div>ความดันโลหิตล่าง</div>
                <apexchart
                  type="area"
                  :options="lowerBloodPressureOptions"
                  :series="lowerBloodPressureSeries"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div>สรุปบันทึกพยาบาล</div>
                <div v-if="Object.keys(latestNurseNote).length === 0" class="mr-2">:</div>
                <div v-if="Object.keys(latestNurseNote).length === 0" class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div v-if="Object.keys(latestNurseNote).length !== 0">
                <div class="text-muted small">
                  {{ formatToTHDate(latestNurseNote.lastUpdated) }}
                </div>
                <div class="text-muted small mt-4 pb-4 border-bottom">
                  {{ latestNurseNote.summary || '-' }}
                </div>
                <div
                  class="py-3 link-color pointer"
                  @click="goToNurseNoteHistory"
                >
                  ดูประวัติย้อนหลัง
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div>หัวหน้าพยาบาล</div>
                <div v-if="nurseSupervisors.length === 0" class="mr-2">:</div>
                <div v-if="nurseSupervisors.length === 0" class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div
                v-for="(nurse,index) in nurseSupervisors"
                :key="nurse.id"
                class="mt-4 pb-4"
                :class="index === nurseSupervisors.length - 1 ? '' : 'border-bottom'"
              >
                <div class="mb-1">{{ nurse.nurseName }}</div>
                <div class="text-muted small">{{ formatTelNo(nurse.nurseMobile) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card rounded-component shadow-sm">
            <div class="card-body">
              <div class="d-flex title-text">
                <div>พยาบาลดูแลคนไข้</div>
                <div v-if="nurseOnDutys.length === 0" class="mr-2">:</div>
                <div v-if="nurseOnDutys.length === 0" class="text-muted">ไม่มีข้อมูล</div>
              </div>
              <div
                v-for="(nurse,index) in nurseOnDutys"
                :key="nurse.nurseOnDutyId"
                class="mt-4 pb-4"
                :class="index === nurseOnDutys.length - 1 ? '' : 'border-bottom'"

              >
                <div class="mb-1">{{ nurse.nurseName }}</div>
                <div class="text-muted small">{{ formatTelNo(nurse.mobileNumber) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavProfile from '@/components/Layout/NavProfile'
import {
  getACase,
  getNurseNoteRequest,
  getNurseSupervisor,
  getNurseOnDutyList,
} from '@/resources/case.provider.js'
import moment from 'moment'
export default {
  name: 'HomePage',
  components: {
    NavProfile
  },
  data() {
    return {
      caseDetail: {},
      latestNurseNote: {},
      nurseNotes: [],
      nurseSupervisors: [],
      nurseOnDutys: [],
    }
  },
  async mounted() {
    const { caseId } = this.$route.params
    const caseResponse = await getACase(caseId)
    this.caseDetail = caseResponse.data
    const nurseNoteResponse = await getNurseNoteRequest(caseId, {
      sortBy: 'updated_at DESC'
    })
    this.nurseNotes = nurseNoteResponse.data.data
    if (nurseNoteResponse.data.data.length > 0) {
      this.latestNurseNote = nurseNoteResponse.data.data[0]
    }
    const nurseSupervisorResponse = await getNurseSupervisor(caseId)
    this.nurseSupervisors = nurseSupervisorResponse.data.data
    const nurseOnDutyResponse = await getNurseOnDutyList(caseId)
    this.nurseOnDutys = nurseOnDutyResponse.data.data
  },
  computed: {
    fullName() {
      return `${this.caseDetail?.patient?.firstName} ${this.caseDetail?.patient?.lastName}`
    },
    hasLatest() {
      return this.caseDetail?.graphData?.updatedAt !== 0
    },
    latestPulse() {
      if (this.caseDetail?.graphData) {
        return this.caseDetail.graphData.pulses[
          this.caseDetail.graphData.pulses.length - 1
        ]
      }
      return '-'
    },
    pulseOptions() {
      if (this.caseDetail?.graphData) {
        const pOptions = { ...this.getShareGraphOptions() }
        pOptions.yaxis.min = 0
        pOptions.yaxis.max = 150
        pOptions.annotations = {
          // yaxis: [
          //   {
          //     y: 61,
          //     y2: 99,
          //     borderColor: '#00E396',
          //     fillColor: '#00E396',
          //   },
          // ],
          points: [ ...this.generatePoints(this.caseDetail.graphData.pulses) ],
        }

        return pOptions
      }
      return {}
    },
    pulseSeries() {
      if (this.caseDetail?.graphData) {
        return [
          {
            name: 'Pulses',
            data: [...this.caseDetail.graphData.pulses],
          },
        ]
      }
      return []
    },
    latestTemparature() {
      if (this.caseDetail?.graphData) {
        return this.caseDetail.graphData.temperatures[
          this.caseDetail.graphData.temperatures.length - 1
        ]
      }
      return '-'
    },
    temperatureSeries() {
      if (this.caseDetail?.graphData) {
        return [
          {
            name: 'temperatures',
            data: [...this.caseDetail.graphData.temperatures],
          },
        ]
      }

      return []
    },
    temperatureOptions() {
      if (this.caseDetail?.graphData) {
        const tOptions = { ...this.getShareGraphOptions() }
        tOptions.yaxis.min = 30
        tOptions.yaxis.max = 45
        tOptions.annotations = {
          // yaxis: [
          //   {
          //     y: 36.5,
          //     y2: 37.5,
          //     borderColor: '#00E396',
          //     fillColor: '#00E396',
          //   },
          // ],
          points: this.generatePoints(this.caseDetail.graphData.temperatures),
        }
        return tOptions
      }

      return {}
    },
    latestOxygenSaturation() {
      if (this.caseDetail?.graphData) {
        return this.caseDetail.graphData.oxygenSaturations[
          this.caseDetail.graphData.oxygenSaturations.length - 1
        ]
      }
      return '-'
    },
    oxygenSaturationSeries() {
      if (this.caseDetail?.graphData) {
        return [
          {
            name: 'Oxygen Saturations',
            data: [...this.caseDetail.graphData.oxygenSaturations],
          },
        ]
      }
      return []
    },
    oxygenOptions() {
      if (this.caseDetail?.graphData) {
        const oOptions = { ...this.getShareGraphOptions() }
        oOptions.yaxis.min = 85
        oOptions.yaxis.max = 100
        oOptions.annotations = {
          // yaxis: [
          //   {
          //     y: 95,
          //     y2: 100,
          //     borderColor: '#00E396',
          //     fillColor: '#00E396',
          //   },
          // ],
          points: this.generatePoints(this.caseDetail.graphData.oxygenSaturations),
        }

        return oOptions
      }

      return {}
    },
    latestRespiration() {
      if (this.caseDetail?.graphData) {
        return this.caseDetail.graphData.latestRespiration
      }

      return '-'
    },
    latestTotalDailyFluid() {
      if (this.caseDetail?.graphData) {
        const total =
          this.caseDetail.graphData.totalDailyFliuids[
            this.caseDetail.graphData.totalDailyFliuids.length - 1
          ]
        if (total < 0) {
          return `${total}`
        } else {
          return `+${total}`
        }
      }

      return '-'
    },
    latestBloodPressure() {
      if (this.caseDetail?.graphData) {
        return `${
          this.caseDetail.graphData.upperBloodPressures[
            this.caseDetail.graphData.upperBloodPressures.length - 1
          ]
        }/${
          this.caseDetail.graphData.lowerBloodPressures[
            this.caseDetail.graphData.lowerBloodPressures.length - 1
          ]
        }`
      }

      return '-'
    },
    upperBloodPressureSeries() {
      if (this.caseDetail?.graphData) {
        return [
          {
            name: 'Systolic',
            data: [...this.caseDetail.graphData.upperBloodPressures],
          },
          {
            name: 'Diastolic',
            data: [...this.caseDetail.graphData.lowerBloodPressures],
          },
        ]
      }

      return []
    },
    lowerBloodPressureSeries() {
      if (this.caseDetail?.graphData) {
        return [
          {
            name: 'Diastolic',
            data: [...this.caseDetail.graphData.lowerBloodPressures],
          },
        ]
      }

      return []
    },
    upperBloodPressureOptions() {
      if (this.caseDetail?.graphData) {
        const bOptions = { ...this.getBloodPressureGraphOptions() }
        bOptions.colors = ['#c70a3f', '#6cadec']
        bOptions.stroke.width = [2, 2]
        bOptions.yaxis.min = 35
        bOptions.yaxis.max = 200
        bOptions.annotations = {
          // yaxis: [
          //   {
          //     y: 90,
          //     y2: 140,
          //     borderColor: '#00E396',
          //     fillColor: '#00E396',
          //   },
          // ],
          points: this.generateBloodPressurePoints(this.caseDetail.graphData.upperBloodPressures, this.caseDetail.graphData.lowerBloodPressures)
        }

        return bOptions
      }

      return {}
    },
    lowerBloodPressureOptions() {
      if (this.caseDetail?.graphData) {
        const bOptions = { ...this.getShareGraphOptions() }
        bOptions.yaxis.min = 35
        bOptions.yaxis.max = 130
        bOptions.annotations = {
          yaxis: [
            {
              y: 60,
              y2: 90,
              borderColor: '#00E396',
              fillColor: '#00E396',
            },
          ],
        }

        return bOptions
      }

      return {}
    },
  },
  methods: {
    formatTelNo(telNo) {
      if (telNo[1] === '2') {
        return `${telNo[0]}${telNo[1]}-${telNo[2]}${telNo[3]}${telNo[4]}-${telNo[5]}${telNo[6]}${telNo[7]}${telNo[8]}`
      } else {
        return `${telNo[0]}${telNo[1]}${telNo[2]}-${telNo[3]}${telNo[4]}${telNo[5]}-${telNo[6]}${telNo[7]}${telNo[8]}${telNo[9]}`

      }
    },
    formatToTHDate(unix) {
      if (unix)
        return moment.unix(unix).locale('th').format('DD MMM YYYY HH:mm')
      return ''
    },
    goToNurseNoteHistory() {
      this.$router.push({ name: 'NurseNoteHistoryPage' })
    },
    generatePoints(arr) {
      let xIndex = 1
      const points = arr.map((p) => {
        const result = {
          x: xIndex,
          y: p,
          marker: {
            size: 3,
            colors: ['#fff'],
            strokeColor: '#83B7EA',
            strokeWidth: 4
          },
          label: {
            borderWidth: 0,
            offsetY: 0,
            style: {
              color: '#6697c9'
            },
            text: p
          }
        }

        xIndex++
        return result
      })

      return points
    },
    generateBloodPressurePoints(uppers, lowers) {
      let xIndex = 1
      const upperPoints = uppers.map((u, index) => {
        const result = {
          x: xIndex,
          y: u,
          marker: {
            size: 3,
            colors: ['#fff'],
            strokeColor: '#c70a3f',
            strokeWidth: 4
          },
          label: {
            borderWidth: 0,
            offsetY: 0,
            style: {
              background: 'transparent',
              color: '#6697c9'
            },
            text: `${u}/${lowers[index]}`
          }
        }

        xIndex++
        return result
      })

      xIndex = 1
      const lowerPoints = lowers.map(l => {
        const result = {
          x: 1,
          y: 12,
          marker: {
            size: 3,
            colors: ['#fff'],
            strokeColor: '#83B7EA',
            strokeWidth: 4
          }
        }

        result.x = xIndex
        result.y = l
        xIndex++

        return result
      })
      return [...upperPoints, ...lowerPoints]
    },
    getShareGraphOptions() {
      const options = {
        chart: {
          height: 280,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        grid: {
          strokeDashArray: 5,
        },
        markers: {
          size: 6,
          colors: ['#83B7EA'],
          strokeColor: '#83B7EA',
          strokeWidth: 6,
          strokeOpacity: 0.25,
          fillOpacity: 0.25,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          colors: '#C0DDFA',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.9,
            stops: [65, 100],
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          categories: [],
        },
        yaxis: {
          opposite: true,
          tickAmount: 1,
        },
      }

      options.xaxis.categories = this.caseDetail.graphData.categories.map(
        (c) => {
          const t = moment.unix(c)
          return [t.format('DD MMM HH:mm')]
        }
      )

      // const self = this

      // options.tooltip.y.formatter = function (value, { dataPointIndex }) {
      //   const t = moment.unix(
      //     self.caseDetail?.graphData.categories[dataPointIndex]
      //   )
      //   return `${value} (${t.format('DD MMM HH:mm')})`
      // }
      return options
    },
    getBloodPressureGraphOptions() {
      const options = {
        chart: {
          height: 280,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          strokeDashArray: 5
        },
        markers: {
          size: [6, 6],
          colors: ['#c70a3f', '#83B7EA'],
          strokeColors: ['#c70a3f', '#83B7EA'],
          strokeWidth: [6, 6],
          strokeOpacity: [0.25, 0.25],
          fillOpacity: [0.25, 0.25]
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          categories: []
        },
        yaxis: {
          opposite: true,
          tickAmount: 1
        }
      }

      options.xaxis.categories = this.caseDetail.graphData.categories.map((c) => {
        const t = moment.unix(c)
        return [t.format('DD MMM HH:mm')]
      })

      return options
    }
  },
}
</script>
<style scoped>
.healthy-gradient {
  background-image: linear-gradient(to right, #9fe7a9, #d8f5dd);
}
.warning-gradient {
  background-image: linear-gradient(to right, #f8e8a4, #f9f3db);
}
.danger-gradient {
  background-image: linear-gradient(to right, #d98684, #e3a3a2);
}
.healthy-pill {
  background-color: #82cfa5;
  color: #effbf1;
}
.warning-pill {
  background-color: #f7ce47;
  color: #effbf1;
}
.danger-pill {
  background-color: #c76a67;
  color: #effbf1;
}
.healthy-text {
  color: #effbf1;
}
.warning-text {
  color: #bcb7a3;
}
.danger-text {
  color: #effbf1;
}
.status-pill {
  width: 4.375rem;
  height: 1.375rem;
  /* padding: 1px 19px 3px 22px; */
  border-radius: 10.5px;

  font-family: Sarabun;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}
.status-green {
  background-color: #67d1a2;
}
.card-green {
  background-image: linear-gradient(
    74deg,
    #81e89e 0%,
    rgba(129, 232, 158, 0.35) 100%
  );
}

.status-yellow {
  background-color: #ffcc0a;
}
.card-yellow {
  background-image: linear-gradient(
    74deg,
    rgba(250, 220, 106, 0.72) 0%,
    rgba(247, 236, 194, 0.58) 100%
  );
}

.status-red {
  background-color: #d56464;
}
.card-red {
  background-image: linear-gradient(
    74deg,
    #e77d7d,
    rgba(231, 125, 125, 0.71) 100%
  );
}
.rounded-component {
  border-radius: 15px;
  border-width: 0;
}
div.apexcharts-canvas > svg {
  overflow: visible !important;
}
.page-wrapper {
  background-color: #f4f5f7;
}
.content-wrapper {
  background-color: white;
}
.link-color {
  color: #60aadb;
}
.pointer {
  cursor: pointer;
}
.title-text {
  font-size: 16px;
}
.subtitle-text {
  font-size: 14px;
}
</style>
