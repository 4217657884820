<template>
  <b-modal
    ref="my-modal"
    dialog-class="modal-dialog-consent"
    content-class="modal-content-consent"
    body-class="modal-body-consent"
    hide-footer
    hide-header
    @shown="calculateHeight"
  >
    <div class="d-flex flex-column" style="height: 100%">
      <div class="px-8px mb-12px" style="font-size: 0.875rem">
        เว็บไซต์นี้ใช้คุกกี้เพื่อวัตถุประสงค์ในการปรับปรุงประสบการณ์ของผู้ใช้งานให้ดียิ่งขึ้น
        โปรดศึกษารายละเอียดเกี่ยวกับการใช้คุกกี้
      </div>
      <div ref="content-over" class="d-flex flex-column" style="flex: 1">
        <div
          class="d-flex flex-column pt-24px pb-24px bg-white px-17px mb-14px"
          :class="{ 'pb-18px': showPDPA }"
          style="border-radius: 10px; border: solid 1px #f2f3f4"
        >
          <div
            class="d-flex justify-content-between"
            type="button"
            v-b-toggle.collapse-pdpa
          >
            <div style="font-size: 16px; font-weight: 500; color: #333">
              นโยบายคุ้มครองข้อมูลส่วนบุคคล
            </div>
            <IconArrowChevronUp v-if="showPDPA"></IconArrowChevronUp>
            <IconArrowChevronDown v-else></IconArrowChevronDown>
          </div>
          <b-collapse
            accordion="content"
            id="collapse-pdpa"
            class="mt-16px pr-12px"
            :style="`overflow-y: auto; overflow-x: hidden;max-height: ${contentHeight}px`"
            @hide="showPDPA = false"
            @show="showPDPA = true"
            :visible="showPDPA"
          >
            <PDPA v-show="showPDPA" />
          </b-collapse>
        </div>
        <div
          class="d-flex flex-column pt-24px pb-24px bg-white px-17px mb-24px"
          :class="{ 'pb-18px': showCookie }"
          style="border-radius: 10px; border: solid 1px #f2f3f4"
        >
          <div
            class="d-flex justify-content-between"
            type="button"
            v-b-toggle.collapse-cookie
          >
            <div style="font-size: 16px; font-weight: 500; color: #333">
              นโยบายคุกกี้
            </div>
            <IconArrowChevronUp v-if="showCookie"></IconArrowChevronUp>
            <IconArrowChevronDown v-else></IconArrowChevronDown>
          </div>
          <b-collapse
            accordion="content"
            id="collapse-cookie"
            class="mt-16px pr-12px"
            :style="`overflow-y: auto; overflow-x: hidden;max-height: ${contentHeight}px`"
            @hide="showCookie = false"
            @show="showCookie = true"
            :visible="showCookie"
          >
            <COOKIE v-show="showCookie" />
          </b-collapse>
        </div>
      </div>

      <div
        class="d-flex position-absolute mb-32px w-100 px-24px"
        style="bottom: 0px; left: 0px"
      >
        <b-button
          pill
          size="lg"
          class="w-100 bg-white"
          variant="primary"
          style="font-size: 0.875rem; font-weight: 500; color: #1b71d2"
          @click="refuse"
          >ไม่ตกลง</b-button
        >
        <div class="pr-17px"></div>
        <b-button
          pill
          size="lg"
          class="w-100"
          variant="primary"
          :style="`font-size: 0.875rem; font-weight: 500; `"
          @click="accept"
          >ตกลง</b-button
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
import IconArrowChevronUp from '../Icons/IconArrowChevronUp'
import IconArrowChevronDown from '../Icons/IconArrowChevronDown.vue'
import PDPA from './contents/001/DataProtectionPolicy.vue'
import COOKIE from './contents/001/CookiePolicy.vue'
import { getConsent, acceptConsent } from '@/resources/consent.provider.js'
import { v4 as uuidv4 } from 'uuid'
export default {
  async mounted() {
    let userId = localStorage.getItem('userId')
    if (!userId) {
      userId = uuidv4()
      localStorage.setItem('userId', userId)
    }
    let wait = this.$route.name == null
    let count = 0
    while (wait) {
      count++
      await this.delay(200)
      wait = this.$route.name == null
      if (count === 20) {
        wait = false
      }
    }
    if (!this.$route.meta.disableConsent) {
      const { data } = await getConsent(
        'COOKIE_PDPA',
        localStorage.getItem('userId')
      )
      this.isAcceptedConsent = data.acceptedVersion === data.version
      if (!this.isAcceptedConsent) {
        this.$refs['my-modal'].show()
      }
    }
  },
  data() {
    return {
      showPDPA: false,
      showCookie: false,
      isAcceptedConsent: false,
      contentHeight: 0,
    }
  },
  methods: {
    async accept() {
      try {
        await acceptConsent('COOKIE_PDPA', localStorage.getItem('userId'))
        this.$refs['my-modal'].hide()
      } catch (error) {
        console.log(error)
      }
    },
    refuse() {
      this.$refs['my-modal'].hide()
    },
    calculateHeight() {
      this.contentHeight = this.$refs['content-over'].clientHeight - 200
    },
    delay(msec) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, msec)
      })
    },
  },
  computed: {},
  components: { IconArrowChevronDown, IconArrowChevronUp, PDPA, COOKIE },
}
</script>
<style lang="scss">
.modal-body-consent {
  padding-top: 2rem !important;
  padding-bottom: 4.5rem !important;
  height: 100% !important;
  // height: 100% !important;
  // padding: 32px 24px !important;
  // border-radius: 12px !important;

  // height: auto !important;
  // background-color: #f3f5f8 !important;
  // background-color: red !important;
  // height: 50%;
}

.modal-content-consent {
  border: solid 1px #f2f3f4 !important;
  background-color: #f3f5f8 !important;
  border-radius: 12px !important;
  padding: 0;

  height: calc(100vh - 6rem) !important;
  // min-height: vh !important;
  // height: auto !important;
  // background-color: #f3f5f8;
}

.modal-dialog-consent {
  // max-height: 20vh !important;
  // border: solid 1px #f2f3f4 !important;
  // background-color: #f3f5f8 !important;
  // padding: 32px 24px 149px;

  // border: solid 1px #f2f3f4;
  // background-color: #f3f5f8;
}

@media (min-width: 0) and (max-width: 575px) {
  .modal-dialog-consent {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
}
.modal-dialog-consent {
  margin-top: 3.5rem !important;
  margin-bottom: 0 !important;
}

.modal-backdrop {
  opacity: 0.7 !important;
}
</style>
