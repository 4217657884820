<template>
  <div class="d-flex flex-column py-50px">
    <span
      class="text-center text-black"
      style="font-size: 1.25rem; font-weight: bold"
    >
      ปรึกษาเจ้าหน้าที่ผู้เชี่ยวชาญ
      <span class="text-primary">ฟรี</span>
    </span>
    <span class="text-center text-gray mt-19px" style="font-size: 0.875rem">
      เพียงโทรหาเราที่ เบอร์ 092-498-9779 หรือติดต่อ <br />
      เราผ่านทาง Line Official Account @madoolae <br />
      เพื่อขอคำแนะนำจากทีมพยาบาลผู้ชำนาญการดูแลคนไข้
    </span>
    <b-button
      class="d-flex align-items-center mx-15px mt-35px"
      href="tel:092-498-9779"
      style="
        min-width: 343px;
        min-height: 82px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
        background-color: #f3f5f8;
      "
    >
      <img
        alt=""
        src="@/assets/icons/contact.png"
        srcset="@/assets/icons/contact.png 2x, @/assets/icons/contact.png 3x"
        style="min-width: 40px; min-height: 40px"
      />
      <div class="d-flex flex-column w-100 ml-22px">
        <span class="text-left text-navy" style="font-size: 1.0625rem"
          >โทรหาเรา
        </span>
      </div>
      <img
        alt=""
        src="@/assets/icons/icons-chevron-right.png"
        srcset="
          @/assets/icons/icons-chevron-right.png 2x,
          @/assets/icons/icons-chevron-right.png 3x
        "
        style="min-width: 20px; min-height: 20px"
      />
    </b-button>
    <b-button
      class="d-flex align-items-center mx-15px mt-20px"
      style="
        min-width: 343px;
        min-height: 82px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
        background-color: #f3f5f8;
      "
      @click="openLineUrl"
    >
      <img
        alt=""
        src="@/assets/icons/line.png"
        srcset="@/assets/icons/line.png 2x, @/assets/icons/line.png 3x"
        style="min-width: 40px; min-height: 40px"
      />
      <div class="d-flex flex-column w-100 ml-22px">
        <span class="text-left text-navy" style="font-size: 1.0625rem"
          >ติดต่อผ่าน LINE
        </span>
      </div>
      <img
        alt=""
        src="@/assets/icons/icons-chevron-right.png"
        srcset="
          @/assets/icons/icons-chevron-right.png 2x,
          @/assets/icons/icons-chevron-right.png 3x
        "
        style="min-width: 20px; min-height: 20px"
      />
    </b-button>
  </div>
</template>
<script>
export default {
  methods: {
    openLineUrl() {
      const url = 'https://lin.ee/plTmL3Z'
      if (window.liff.getOS() === 'web') {
        window.open(url, '_blank')
      } else {
        window.liff.openWindow({
          url,
          external: false,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.mt-19px {
  margin-top: 19px;
}
.mt-29px {
  margin-top: 29px;
}
.ml-22px {
  margin-left: 22px;
}
.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}
.text-black {
  color: #333333;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
