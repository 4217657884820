<template>
  <div class="card w-100">
    <div class="status-card pt-30px px-20px pb-20px" :class="cardClass">
      <div class="title">{{ name }}</div>
      <div class="d-flex justify-content-between">
        <div class="subtitle">สถานะผู้ป่วย</div>
        <div class="status-pill text-center" :class="statusClass">
          {{ statusText }}
        </div>
      </div>
    </div>

    <div class="px-20px py-10px link cursor-pointer" @click="goToCaseDetail">
      รายละเอียดสถานะผู้ป่วย >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    caseId: {
      type: Number,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    status: {
      require: true,
      type: String,
      default: 'green',
    },
  },
  computed: {
    cardClass() {
      if (this.status === 'green') return 'card-green'
      if (this.status === 'yellow') return 'card-yellow'
      if (this.status === 'red') return 'card-red'
      return `card-green`
    },
    statusClass() {
      if (this.status === 'green') return 'status-green'
      if (this.status === 'yellow') return 'status-yellow'
      if (this.status === 'red') return 'status-red'
      return `status-green`
    },
    statusText() {
      if (this.status === 'green') return 'เขียว'
      if (this.status === 'yellow') return 'เหลือง'
      if (this.status === 'red') return 'แดง'
      return 'เขียว'
    },
  },
  methods: {
    goToCaseDetail() {
      this.$router.push({'name': 'CaseDetailPage', params: { caseId: this.caseId }})
    }
  }
}
</script>
<style scoped>
.card {
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
  background-color: #fff;
}
.title {
  font-family: Sarabun;
  font-size: 1rem;
  font-weight: 500;
  color: #2f2f2f;
}

.subtitle {
  font-family: Sarabun;
  font-size: 0.813rem;
  font-weight: 300;
  color: #fff;
}
.link {
  font-family: Sarabun;
  font-size: 0.875rem;
  color: #0090d4;
}
.status-card {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.status-pill {
  width: 4.375rem;
  height: 1.375rem;
  /* padding: 1px 19px 3px 22px; */
  border-radius: 10.5px;

  font-family: Sarabun;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}
.status-green {
  background-color: #67d1a2;
}
.card-green {
  background-image: linear-gradient(
    74deg,
    #81e89e 0%,
    rgba(129, 232, 158, 0.35) 100%
  );
}

.status-yellow {
  background-color: #ffcc0a;
}
.card-yellow {
  background-image: linear-gradient(
    74deg,
    rgba(250, 220, 106, 0.72) 0%,
    rgba(247, 236, 194, 0.58) 100%
  );
}

.status-red {
  background-color: #d56464;
}
.card-red {
  background-image: linear-gradient(
    74deg,
    #e77d7d,
    rgba(231, 125, 125, 0.71) 100%
  );
}
</style>
