<template>
  <div class="profile">
    <NavProfile />
    <!-- <div class="bbb w-100 d-flex justify-content-center px-28px">
      <div class="aaa px-48px py-13px">aaa</div>
    </div> -->
    <!-- <Alert text="เข้าสู่ระบบสำเร็จ" /> -->
    <div class="d-flex justify-content-center">
      <div class="mt-68px" v-if="cases.length === 0">
        <div
          class="py-58px px-51px"
          style="
            border-radius: 8px;
            box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
            background-color: #fff;
          "
        >
          <!-- <b-avatar
            size="7.5rem"
            src="https://placekitten.com/300/300"
          ></b-avatar> -->
          <img
            src="@/assets/icons/no-data/image.png"
            srcset="
              @/assets/icons/no-data/image@2x.png 2x,
              @/assets/icons/no-data/image@3x.png 3x
            "
            class="Image"
          />
        </div>
        <div
          class="text-center mt-30px"
          style="
            font-family: Sarabun;
            font-size: 1.375rem;
            font-weight: 500;
            color: #000;
          "
        >
          ไม่พบข้อมูลผู้ป่วย
        </div>
      </div>
      <div v-else class="w-100 px-20px pb-20px">
        <CaseCard
          v-for="_case in cases"
          :key="_case.id"
          class="mt-20px"
          :caseId="_case.id"
          :name="_case.patientName"
          :status="_case.health.toLowerCase()"
        />
        <!-- <CaseCard
          class="mt-20px"
          caseId="2"
          name="ฉัตรชัย แซ่แซ่"
          status="yellow"
        />
        <CaseCard class="mt-20px" caseId="3" name="ลาเต้ เย็น" status="red" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import NavProfile from '@/components/Layout/NavProfile'
// import Alert from '@/components/Alert'
import CaseCard from '@/components/CaseCard'
import jwtDecode from 'jwt-decode'
import { getCase } from '@/resources/case.provider.js'
export default {
  components: {
    NavProfile,
    CaseCard,
    // Alert,
  },
  props: {},
  data() {
    return {
      cases: [],
      user: {},
    }
  },
  async mounted() {
    const token = localStorage.getItem('token')
    this.user = jwtDecode(token)
    try {
      const { data } = await getCase()
      console.log(data)
      this.cases = data.data
    } catch (error) {
      console.log(error)
    }
    await getCase()
  },
  methods: {},
}
</script>

<style scoped>
.profile {
  background-color: #f4f5f7;
  min-height: 100vh;
}

/* .bbb {
  position: absolute;
  max-width: 640px;
  padding-top: 15px;
}
.aaa {
  z-index: 100;
  width: 100%;
  border-radius: 8px;
  background-color: #79ba27;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
} */
</style>
