<template>
  <div class="py-50px" style="background-color: #01234b">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column align-self-center mx-20px">
        <img
          class="cover-image-mx20"
          alt=""
          src="@/assets/images/service-3.png"
          srcset="
            @/assets/images/service-3.png 2x,
            @/assets/images/service-3.png 3x
          "
        />
        <div
          class="bg-white d-flex flex-column align-self-center w-100"
          style="
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 10px;
          "
        >
          <span
            class="text-center text-black pt-50px"
            style="font-size: 1.25rem; font-weight: 600"
            >ให้เราดูแลคุณ
            <br />
            คลิกเพื่อทำแบบประเมินเลย
          </span>
          <b-button
            pill
            class="text-white bg-primary mt-30px"
            size="lg"
            variant="primary"
            style="
              font-size: 0.875rem;
              font-weight: 500;
              margin-left: 58px;
              margin-right: 58px;
              margin-bottom: 41px;
            "
            @click="goToPreAssessmentPage"
            >ทำแบบประเมินการให้บริการ</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToPreAssessmentPage() {
      this.$router.push({
        name: 'PreAssessment',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.cover-image-mx20 {
  width: calc(100vw - 40px);
  max-width: 600px;
  min-width: 335px;
}
</style>
