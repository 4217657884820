<template>
  <div class="d-flex flex-column bg-primary pt-50px pb-footer">
    <div class="pl-16px">
      <div class="d-flex pb-20px">
        <img
          alt=""
          src="@/assets/logo/logo-ma-doo-lae.png"
          srcset="
            @/assets/logo/logo-ma-doo-lae@2x.png 2x,
            @/assets/logo/logo-ma-doo-lae@3x.png 3x
          "
        />
        <!-- <div class="d-flex flex-column w-100 pl-10px">
          <span
            class="text-white"
            style="font-size: 0.875rem; font-weight: bold"
            >MA-DOO-LAE</span
          >
          <span class="text-white" style="font-size: 0.625rem">มา-ดู-แล</span>
        </div> -->
      </div>
      <span class="text-white" style="font-size: 1rem; font-weight: 600"
        >เราหวังเป็นอย่างยิ่งว่า เราสามารถ <br />
        ‘มา-ดู-แล’ คนที่คุณรักของท่าน <br />
        ให้กลับมามีคุณภาพชีวิตที่ดี
      </span>
    </div>
    <div v-if="isShowMenu">
      <div class="line mt-50px mb-20px"></div>
    </div>
    <div v-else>
      <div class="line mt-50px mb-15px"></div>
    </div>
    <b-container>
      <b-row
        class="px-16px d-flex align-items-center justify-content-between"
        @click="clickShowMenu"
      >
        <span class="text-white" style="font-size: 1.25rem">เมนู</span>
        <div v-if="isShowMenu">
          <img
            alt=""
            src="@/assets/icons/arrow-black-chevron-up.png"
            srcset="
              @/assets/icons/arrow-black-chevron-up.png 2x,
              @/assets/icons/arrow-black-chevron-up.png 3x
            "
            style="min-width: 24px; min-height: 24px"
          />
        </div>
        <div v-else>
          <img
            alt=""
            src="@/assets/icons/arrow-black-chevron-down.png"
            srcset="
              @/assets/icons/arrow-black-chevron-down.png 2x,
              @/assets/icons/arrow-black-chevron-down.png 3x
            "
            style="min-width: 24px; min-height: 24px"
          />
        </div>
      </b-row>
      <b-container v-if="isShowMenu">
        <b-row>
          <span
            class="cursor-pointer text-white mt-16px"
            style="font-size: 0.875rem"
            @click="goToHomePage"
          >
            หน้าเเรก
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToAboutPage"
          >
            เกี่ยวกับเรา
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToDataProtectionPolicyPage"
          >
            นโยบายคุ้มครองข้อมูลส่วนบุคคล
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToCookiePolicyPage"
          >
            นโยบายคุกกี้
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToContactPage"
          >
            ติดต่อเรา
          </span>
        </b-row>
      </b-container>
    </b-container>
    <!-- <div class="line my-20px"></div> -->
    <div v-if="isShowMenu">
      <div class="line mt-20px mb-15px"></div>
    </div>
    <div v-else-if="isShowService">
      <div class="line mt-15px mb-20px"></div>
    </div>
    <div v-else>
      <div class="line my-15px"></div>
    </div>
    <b-container>
      <b-row
        class="px-16px d-flex justify-content-between"
        @click="clickShowService"
      >
        <span class="text-white" style="font-size: 1.25rem">บริการของเรา</span>
        <div v-if="isShowService">
          <img
            alt=""
            src="@/assets/icons/arrow-black-chevron-up.png"
            srcset="
              @/assets/icons/arrow-black-chevron-up.png 2x,
              @/assets/icons/arrow-black-chevron-up.png 3x
            "
            style="min-width: 24px; min-height: 24px"
          />
        </div>
        <div v-else>
          <img
            alt=""
            src="@/assets/icons/arrow-black-chevron-down.png"
            srcset="
              @/assets/icons/arrow-black-chevron-down.png 2x,
              @/assets/icons/arrow-black-chevron-down.png 3x
            "
            style="min-width: 24px; min-height: 24px"
          />
        </div>
      </b-row>
      <b-container v-if="isShowService">
        <b-row>
          <span
            class="cursor-pointer text-white mt-16px"
            style="font-size: 0.875rem"
            @click="goToNursePage"
          >
            พยาบาล
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToNutritionistPage"
          >
            นักโภชนาการ
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToOccupationalTherapistPage"
          >
            นักกายภาพบำบัด
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToPhysicalTherapistPage"
          >
            นักกิจกรรมบำบัด
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToAmbulancePage"
          >
            รถรับส่ง
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-16px"
            style="font-size: 0.875rem"
            @click="goToVaccinationPage"
          >
            ฉีดวัคซีน
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToTakeMeToHospitalPage"
          >
            พาไปหาหมอ
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToMedicalProcedurePage"
          >
            หัตถการทางการพยาบาล
          </span>
        </b-row>
        <b-row>
          <span
            class="cursor-pointer text-white mt-10px"
            style="font-size: 0.875rem"
            @click="goToSpecimenCollectionPage"
          >
            เจาะเลือดและเก็บสิ่งส่งตรวจ
          </span>
        </b-row>
      </b-container>
    </b-container>
    <div v-if="isShowService">
      <div class="line my-20px"></div>
    </div>
    <div v-else>
      <div class="line mt-15px mb-20px"></div>
    </div>
    <!-- <div class="line my-20px"></div> -->
    <span class="text-white text-center" style="font-size: 0.625rem"
      >© Copyright 2021 MA-DOO-LAE. All right reserved.</span
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowMenu: false,
      isShowService: false,
    }
  },
  methods: {
    clickShowMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    clickShowService() {
      this.isShowService = !this.isShowService
    },
    goToHomePage() {
      this.$router.push({
        name: 'Home',
      })
    },
    goToAboutPage() {
      this.$router.push({
        name: 'About',
      })
    },
    goToDataProtectionPolicyPage() {
      this.$router.push({
        name: 'DataProtectionPolicy',
      })
    },
    goToCookiePolicyPage() {
      this.$router.push({
        name: 'CookiePolicy',
      })
    },
    goToContactPage() {
      this.$router.push({
        name: 'Contact',
      })
    },
    goToNursePage() {
      this.$router.push({
        name: 'Nurse',
      })
    },
    goToNutritionistPage() {
      this.$router.push({
        name: 'Nutritionist',
      })
    },
    goToOccupationalTherapistPage() {
      this.$router.push({
        name: 'OccupationalTherapist',
      })
    },
    goToPhysicalTherapistPage() {
      this.$router.push({
        name: 'PhysicalTherapist',
      })
    },
    goToMedicalProcedurePage() {
      this.$router.push({
        name: 'MedicalProcedure',
      })
    },
    goToSpecimenCollectionPage() {
      this.$router.push({
        name: 'SpecimenCollection',
      })
    },
    goToVaccinationPage() {
      this.$router.push({
        name: 'Vaccination',
      })
    },
    goToTakeMeToHospitalPage() {
      this.$router.push({
        name: 'TakeMeToHospital',
      })
    },
    goToAmbulancePage() {
      this.$router.push({
        name: 'Ambulance',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.mb-15px {
  margin-bottom: 15px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-16px {
  margin-top: 16px;
}
.my-15px {
  margin-top: 15px;
  margin-bottom: 15px;
}
.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}
.pl-16px {
  padding-left: 16px;
}
.pb-footer {
  padding-bottom: calc(13px + env(safe-area-inset-bottom));
}
.line {
  width: 100%;
  height: 1px;
  border: solid 1px #beddff;
}
</style>
