import http from './withToken'

export function getCase(params) {
  return http.get('/api/customer-dashboard/case', {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

export function getACase(caseId) {
    return http.get(`/api/customer-dashboard/case/${caseId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    }, {})
}

export function getNurseNoteRequest (caseId, params) {
    return http.get(`/api/customer-dashboard/case/${caseId}/nurse-note`, {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    })
}

export function getNurseSupervisor (caseId, params) {
    return http.get(`/api/customer-dashboard/case/${caseId}/nurse-supervisor`, {
        params: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
    })
}

export function getNurseOnDutyList (caseId, params) {
  return http.get(`/api/customer-dashboard/case/${caseId}/nurse-on-duty`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    params
  })
}

export function getPrevNextCaseNurseNote (caseId, params) {
  return http.get(`/api/customer-dashboard/case/${caseId}/nurse-note/prev-next`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    params
  })
}