import Vue from 'vue'
import VueRouter from 'vue-router'
import Nurse from '../views/Nurse'
import Nutritionist from '../views/Nutritionist'
import OccupationalTherapist from '../views/OccupationalTherapist'
import PhysicalTherapist from '../views/PhysicalTherapist'
import MedicalProcedure from '../views/MedicalProcedure'
import SpecimenCollection from '../views/SpecimenCollection'
import Vaccination from '../views/Vaccination'
import TakeMeToHospital from '../views/TakeMeToHospital'
import Ambulance from '../views/Ambulance'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import WorkWithUs from '../views/WorkWithUs.vue'
import Contact from '../views/Contact.vue'
import Service from '../views/Service.vue'
import PreAssessment from '../views/PreAssessment'
import PreAssessmentForm from '../views/PreAssessment/Form'
import PreAssessmentContact from '../views/PreAssessment/Contact'
import PreAssessmentResult from '../views/PreAssessment/Result'
import PreAssessmentSuccess from '../views/PreAssessment/Success'
import Login from '../views/Login'
import OTP from '../views/Login/OTP'
import Passcode from '../views/Login/Passcode'
import SetPasscode from '../views/Login/SetPasscode'
import Profile from '../views/Profile'
import ProfileSetting from '../views/Profile/Setting'
import CaseDetailPage from '../views/CaseDetailPage'
import NurseNoteHistoryPage from '../views/CaseDetailPage/NurseNoteHistory'
import CookiePolicy from '../views/CookiePolicy'
import DataProtectionPolicy from '../views/DataProtectionPolicy'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/work-with-us',
    name: 'WorkWithUs',
    component: WorkWithUs,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { disableConsent: true },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
  },
  {
    path: '/nurse',
    name: 'Nurse',
    component: Nurse,
  },
  {
    path: '/nutritionist',
    name: 'Nutritionist',
    component: Nutritionist,
  },
  {
    path: '/occupational-therapist',
    name: 'PhysicalTherapist',
    component: PhysicalTherapist,
  },
  {
    path: '/physical-therapist',
    name: 'OccupationalTherapist',
    component: OccupationalTherapist,
  },
  {
    path: '/medical-procedure',
    name: 'MedicalProcedure',
    component: MedicalProcedure,
  },
  {
    path: '/specimen-collection',
    name: 'SpecimenCollection',
    component: SpecimenCollection,
  },
  {
    path: '/vaccination',
    name: 'Vaccination',
    component: Vaccination,
  },
  {
    path: '/take-me-to-hospital',
    name: 'TakeMeToHospital',
    component: TakeMeToHospital,
  },
  {
    path: '/ambulance',
    name: 'Ambulance',
    component: Ambulance,
  },
  {
    path: '/preassessment',
    name: 'PreAssessment',
    component: PreAssessment,
  },
  {
    path: '/preassessment/form/:page',
    name: 'PreAssessmentForm',
    component: PreAssessmentForm,
    meta: { requiresServices: true },
  },
  {
    path: '/preassessment/contact',
    name: 'PreAssessmentContact',
    component: PreAssessmentContact,
    meta: { requiresServices: true, disableConsent: true },
  },
  {
    path: '/preassessment/result',
    name: 'PreAssessmentResult',
    component: PreAssessmentResult,
    props: (route) => ({ nurseLevel: route.query.nurseLevel }),
  },
  {
    path: '/preassessment/success',
    name: 'PreAssessmentSuccess',
    component: PreAssessmentSuccess,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { disableConsent: true },
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OTP,
    props: (route) => ({
      username: route.query.username,
      setPasscode: route.query.setPasscode,
    }),
    meta: { disableConsent: true },
  },
  {
    path: '/passcode',
    name: 'Passcode',
    component: Passcode,
    props: (route) => ({ username: route.query.username }),
    meta: { disableConsent: true },
  },
  {
    path: '/set-passcode',
    name: 'SetPasscode',
    component: SetPasscode,
    props: (route) => ({
      username: route.query.username,
      setPasscode: route.query.setPasscode,
      pin: route.query.pin,
      token: route.query.token,
    }),
    meta: { disableConsent: true },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true, disableConsent: true },
  },
  {
    path: '/profile/setting',
    name: 'ProfileSetting',
    component: ProfileSetting,
    meta: { requiresAuth: true, disableConsent: true },
  },
  {
    path: '/case/:caseId',
    name: 'CaseDetailPage',
    component: CaseDetailPage,
    meta: { disableConsent: true },
  },
  {
    path: '/case/:caseId/nurse-note-history',
    name: 'NurseNoteHistoryPage',
    component: NurseNoteHistoryPage,
    meta: { disableConsent: true },
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy,
    meta: { disableConsent: true },
  },
  {
    path: '/data-protection-policy',
    name: 'DataProtectionPolicy',
    component: DataProtectionPolicy,
    meta: { disableConsent: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    next({ name: 'Login' })
  }
  if (to.matched.some((record) => record.meta.requiresServices)) {
    if (!store.getters.hasSelectedService) {
      next({
        name: 'PreAssessment',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
