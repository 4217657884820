<template>
  <div class="min-vh-100 h-100 position-relative bg-primary">
    <div class="bg-primary px-20px pt-60px d-flex pb-26px justify-content-end">
      <div style="width: 24px">
        <b-icon icon="x" font-scale="2" variant="white"></b-icon>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-70px">
      <b-icon
        @click="close"
        icon="check-circle-fill"
        style="width: 95px; height: 95px"
        variant="white"
      ></b-icon>
    </div>
    <div
      class="px-20px py-30px h-100 position-relative"
      style="
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #f4f5f7;
      "
    >
      <div
        class="text-center mb-26px"
        style="font-size: 1.5rem; font-weight: 600; color: #000"
      >
        มา-ดู-แล ได้รับข้อมูลของคุณเรียบร้อยแล้ว
      </div>
      <div class="text-center mb-26px" style="font-size: 1.25rem color: #000">
        เราจะติดต่อกลับภายใน 12 ชั่วโมง
      </div>
      <div
        class="d-flex justify-content-center pb-30px w-100"
        style="bottom: 1"
      >
        <b-button
          pill
          class="mt-70px align-self-center"
          size="lg"
          variant="primary"
          href="tel:092-498-9779"
          style="font-size: 0.875rem; font-weight: 500"
          >สนใจบริการโทร 092-498-9779</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import liff from '@line/liff'
export default {
  name: 'Success',
  methods: {
    close() {
      liff.closeWindow()
    },
  },
}
</script>
<style lang="scss" scoped></style>
