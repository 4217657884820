<template>
  <div class="px-27px py-25px d-flex flex-column">
    <div class="mb-23px d-flex">
      <div
        class="px-8px py-5px cursor-pointer"
        style="
          border-radius: 8px;
          border: solid 1px #e2e6e8;
          background-color: #fff;
        "
      >
        <b-icon
          @click="back"
          icon="chevron-left"
          font-scale="1"
          style="color: #0f0a39"
        ></b-icon>
      </div>
    </div>
    <span class="otp mb-15px">กรอกรหัส OTP</span>
    <span class="otp- mb-42px">
      รหัส OTP จะถูกส่งไปที่เบอร์โทรศัพท์มือถือลงท้ายด้วย<span class="phone">
        -{{ lastPhoneNumber }}.</span
      >
    </span>
    <div class="align-self-center d-flex flex-column mb-108px">
      <div class="mb-10px">
        <input
          data-index="1"
          ref="otp-1"
          v-model="otp._1"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield mr-8px"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
        <input
          data-index="2"
          ref="otp-2"
          v-model="otp._2"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield mr-8px"
          @keydown="onKeyDown"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
        <input
          data-index="3"
          ref="otp-3"
          v-model="otp._3"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield mr-8px"
          @keydown="onKeyDown"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
        <input
          data-index="4"
          ref="otp-4"
          v-model="otp._4"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield mr-8px"
          @keydown="onKeyDown"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
        <input
          data-index="5"
          ref="otp-5"
          v-model="otp._5"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield mr-8px"
          @keydown="onKeyDown"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
        <input
          data-index="6"
          ref="otp-6"
          v-model="otp._6"
          max="9"
          maxlength="1"
          type="number"
          class="otp-textfield"
          @keydown="onKeyDown"
          pattern="[0-9]*"
          @input="focusNextOncePopulated($event)"
        />
      </div>
      <div class="d-flex justify-content-between">
        <span
          style="
            font-family: Sarabun;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #7b7890;
          "
        >
          Ref. Code {{ refNo }}
        </span>
        <div class="d-flex flex-column">
          <span
            @click="reload"
            class="cursor-pointer"
            style="
              font-family: Sarabun;
              font-size: 0.875rem;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: right;
              color: #0090d4;
            "
          >
            ขอรหัสใหม่
          </span>
          <span
            style="
              font-family: Sarabun;
              font-size: 0.875rem;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: right;
              color: #7b7890;
            "
          >
            <countdown
              ref="countdown"
              :auto-start="false"
              :time="countdownTime"
              :transform="transform"
            >
              <template slot-scope="props">
                ( {{ props.minutes }}:{{ props.seconds }} )</template
              >
            </countdown></span
          >
        </div>
      </div>
    </div>
    <button type="button" class="base btn w-100" @click="next">ตกลง</button>
  </div>
</template>
<script>
import { requestOTP, verifyOTP } from '@/resources/auth.provider.js'
export default {
  props: {
    username: {
      type: String,
      require: true,
    },
    setPasscode: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      otp: {
        _1: '',
        _2: '',
        _3: '',
        _4: '',
        _5: '',
        _6: '',
      },
      refNo: '',
      token: '',
      countdownTime: 5 * 60 * 1000,
    }
  },
  async mounted() {
    this.$refs['otp-1'].focus()
    try {
      await this.requestOTP()
      // this.$refs.countdown.start()
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    lastPhoneNumber() {
      if (this.username.length > 5) {
        return this.username.substr(this.username.length - 4)
      }
      return ''
    },
  },
  methods: {
    reload() {
      this.$router.go()
    },
    async requestOTP() {
      try {
        const { data } = await requestOTP({ username: this.username })
        this.$refs.countdown.start()
        this.refNo = data.refNo
        this.token = data.token
      } catch (error) {
        console.log(error)
      }
    },
    focusNextOncePopulated(event) {
      if (event.target.value.length >= 6) {
        const codes = event.target.value.split('')
        let lastIndex = 1
        for (let index = 0; index < codes.length && index < 6; index++) {
          this.otp['_' + (index + 1)] = codes[index]
          lastIndex = index + 1
        }
        const lastElement = this.$refs?.[`otp-${Number(lastIndex)}`]
        if (lastElement) lastElement.focus()
      } else {
        if (event.target.value.length > 1) {
          this.otp['_' + event.target.dataset.index] =
            event.target.value.substr(event.target.value.length - 1)
        }
        if (event.target.value.length >= 1 && event.target.dataset.index != 6) {
          const nextElement =
            this.$refs?.[`otp-${Number(event.target.dataset.index) + 1}`]
          if (nextElement) nextElement.focus()
        }
      }
    },
    onKeyDown(event) {
      if (
        (event.key === 'Backspace' || event.key === 'Delete') &&
        event.target.value === ''
      ) {
        const pevElement =
          this.$refs?.[`otp-${Number(event.target.dataset.index) - 1}`]
        if (pevElement) pevElement.focus()
      }
    },
    back() {
      this.$router.back()
    },
    async next() {
      try {
        await verifyOTP({
          otpPin: Object.values(this.otp).join(''),
          otpToken: this.token,
        })
        this.$router.push({
          name: 'SetPasscode',
          query: {
            username: this.username,
            pin: Object.values(this.otp).join(''),
            token: this.token,
            setPasscode: this.setPasscode,
          },
        })
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.data &&
          error.response.status === 400 &&
          error.response.data.error === 'Incorrect otp'
        ) {
          this.$notify({
            type: 'error',
            title: 'OTP ไม่ถูกต้อง',
            duration: 3000,
          })
        } else {
          this.$notify({
            type: 'error',
            title: 'มีข้อผิดพลาดเกิดขึ้น โปรดลองใหม่อีกครั้งภายหลัง',
            duration: 2000,
          })
        }
        console.log(error, 'sadad')
      }
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value

        // uses singular form when the value is less than 2
        // const word = value < 2 ? key.replace(/s$/, '') : key

        props[key] = `${digits}`
      })

      return props
    },
  },
}
</script>
<style scoped>
.otp {
  /* font-family: Thonburi; */
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0f0a39;
}
.otp- {
  /* font-family: Sarabun; */
  font-family: Sarabun;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7b7890;
}
.otp- .phone {
  font-weight: 500;
  color: #0090d4;
}

.otp-textfield {
  width: 44px;
  height: 44px;
  padding: 6px 16px;
  border-radius: 10px;
  background-color: #f5f6fa;
  border: none;
  caret-color: #0093d4;
  color: #0093d4;
  font-family: Avenir;
  font-size: 1.25rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #0093d4;
}

input.otp-textfield:focus {
  outline: 0;
}

input.base-input {
  width: 100%;
  height: 44px;
  padding: 11px 20.875rem 12px 17px;
  opacity: 0.8;
  border-radius: 10px;
  background-color: #f4f5f7;
  border: none;
}
input.base-input:focus {
  outline: 0;
}
button.base {
  height: 44px;
  color: #fff;
  padding: 10px 139px;
  border-radius: 8px;
  background-color: #0090d4;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
