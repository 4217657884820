<template>
  <ValidationObserver
    tag="div"
    class="min-vh-100 position-relative border"
    ref="form"
    v-slot="{ invalid, validate }"
  >
    <div class="bg-primary px-20px pt-60px d-flex pb-26px">
      <div @click="prev" class="cursor-pointer">
        <IconArrowBack class="align-self-center" />
      </div>
      <div
        class="text-white w-100 text-center"
        style="font-size: 1.25rem font-weight: 600"
      >
        แบบประเมินค่าบริการ
      </div>
      <div style="width: 24px"></div>
    </div>
    <div class="px-20px pb-100px d-flex flex-column" v-if="questionnaire">
      <div class="pt-20px mb-24px d-flex flex-column">
        <div class="mb-6px" style="font-size: 0.75rem; color: #aaa">
          {{ page - 1 }} จาก {{ total }} ข้อ
        </div>
        <b-progress
          height="0.5rem"
          variant="success"
          :value="progress"
        ></b-progress>
      </div>
      <div
        class="mb-18px"
        style="
          font-size: 1.25rem;
          color: #333333;
          font-weight: 600;
          line-height: 1.5;
          white-space: pre-wrap;
        "
      >
        {{ page }}. {{ questionnaire.name }}
      </div>
      <b-form-group v-if="questionnaire.type === 'radio'">
        <div
          @click.self="onClickRadio(option)"
          v-for="option in questionnaire.options"
          :key="option.id"
          class="mb-20px service-checkbox py-13px px-18px d-flex flex-column cursor-pointer"
          style="
            border-radius: 4px;
            border: solid 1px #f2f3f4;
            background-color: #f3f5f8;
          "
        >
          <div class="d-flex" @click.self="onClickRadio(option)">
            <b-form-radio v-model="selected" :value="option"></b-form-radio>
            <div class="mb-10 ml-10px" @click.self="onClickRadio(option)">
              {{ option.name }}
            </div>
          </div>
          <!-- <div
            v-if="choice.remark"
            class="px-14px py-10px mt-17px"
            style="background-color: #f9f9f9; font-size: 0.875rem; color: #aaa"
          >
            {{ choice.remark }}
          </div> -->
        </div>
      </b-form-group>
      <b-form-group v-if="questionnaire.type === 'checkbox'">
        <b-form-checkbox-group v-model="selected">
          <div
            @click.self="onClickCheckBox(option)"
            v-for="option in questionnaire.options"
            :key="option.id"
            class="mb-20px service-checkbox py-13px px-18px d-flex flex-column cursor-pointer"
            style="
              border-radius: 4px;
              border: solid 1px #f2f3f4;
              background-color: #f3f5f8;
            "
          >
            <div
              class="d-flex"
              :class="{ 'mb-3': option.options }"
              @click.self="onClickCheckBox(option)"
            >
              <b-form-checkbox :value="option"></b-form-checkbox>
              <div class="mb-10 ml-10px" @click.self="onClickCheckBox(option)">
                {{ option.name }}
              </div>
            </div>
            <!-- <div
              v-if="choice.remark"
              class="px-14px py-10px mt-17px"
              style="
                background-color: #f9f9f9;
                font-size: 0.875rem;
                color: #aaa;
              "
            >
              {{ choice.remark }}
            </div> -->

            <ValidationProvider
              v-if="option.id == 999"
              vid="other"
              name="other"
              :rules="hasSelected(option) ? 'required' : null"
              v-slot="{ errors }"
            >
              <b-form-input
                class="mt-20px"
                :disabled="!hasSelected(option)"
                v-model="option.value"
                placeholder="โปรดระบุ"
                :state="isValid(errors)"
              ></b-form-input>
            </ValidationProvider>
            <ValidationProvider
              v-if="option.options"
              :vid="`${option.id}`"
              :name="`${option.id}co`"
              :rules="hasSelected(option) ? 'required' : null"
              :skipIfEmpty="false"
              v-slot="{ errors }"
            >
              <b-form-select
                v-model="option.selected"
                :disabled="!hasSelected(option)"
                class=""
                :state="isValid(errors)"
              >
                <b-form-select-option
                  :key="op.id"
                  v-for="op in option.options"
                  :value="op"
                  >{{ op.name }}</b-form-select-option
                >
              </b-form-select>
            </ValidationProvider>
          </div>
        </b-form-checkbox-group>
      </b-form-group>
    </div>
    <div
      class="d-flex position-absolute pb-35px w-100 px-20px"
      :class="[
        prevPage > 0 ? 'justify-content-between' : 'justify-content-center',
      ]"
      style="bottom: 0"
    >
      <b-button
        v-if="prevPage > 0"
        @click="prev"
        pill
        size="lg"
        class="px-35px"
        variant="outline-primary"
        style="font-size: 0.875rem; font-weight: 500; width: 48%"
        >ย้อนกลับ</b-button
      >
      <b-button
        @click="validate"
        v-if="!selected || selected.length === 0 || invalid"
        disabled
        pill
        size="lg"
        class="px-35px"
        variant="primary"
        :style="`font-size: 0.875rem; font-weight: 500; ${
          prevPage > 0 ? 'width: 48%' : 'width: 80%'
        }`"
        >ต่อไป</b-button
      >
      <b-button
        v-else
        @click="next"
        pill
        size="lg"
        class="px-35px"
        variant="primary"
        :style="`font-size: 0.875rem; font-weight: 500; ${
          prevPage > 0 ? 'width: 48%' : 'width: 80%'
        }`"
        >ต่อไป</b-button
      >
    </div>
  </ValidationObserver>
</template>
<script>
import IconArrowBack from '@/assets/icons/ic-arrow-back.vue'
import { mapActions, mapGetters } from 'vuex'
import { getQuestionnaires } from '@/resources/assessment.provider.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
extend('required', {
  ...required,
  message: 'โปรดระบุ',
})

export default {
  name: 'Form',
  components: { IconArrowBack, ValidationProvider, ValidationObserver },
  data() {
    return {
      selected: [],
      questionnaires: [],
    }
  },
  async mounted() {
    try {
      const { data } = await getQuestionnaires()
      this.questionnaires = data.data.map((d) => {
        return {
          id: d.id,
          ...{ ...JSON.parse(d.options) },
        }
      })
    } catch (error) {
      console.log(error)
    }
    if (this.questionnairesById(this.page)) {
      this.selected = this.questionnairesById(this.page)
    }
  },
  methods: {
    ...mapActions(['addQuestionnaire']),
    async next() {
      this.addQuestionnaire({
        id: this.questionnaire.id,
        answers: this.selected,
      })
      if (this.nextPage <= this.total) {
        this.$router.push({
          name: 'PreAssessmentForm',
          params: { page: this.nextPage },
        })
        this.selected = []
      } else {
        this.$router.push({
          name: 'PreAssessmentContact',
        })
      }
    },
    prev() {
      if (this.prevPage < 1) {
        this.$router.push({
          name: 'PreAssessment',
        })
      } else {
        this.$router.push({
          name: 'PreAssessmentForm',
          params: { page: this.prevPage },
        })
      }
    },
    isValid(errors) {
      if (errors && errors.length > 0) return false
      return null
    },
    hasSelected(value) {
      if (Array.isArray(this.selected)) {
        return !!this.selected.find((s) => s == value)
      }
      return this.selected == value
    },
    onClickCheckBox(key) {
      const index = this.selected.indexOf(key)
      if (index >= 0) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(key)
      }
    },
    onClickRadio(key) {
      this.selected = key
    },
  },
  computed: {
    ...mapGetters(['questionnairesById']),
    questionnaire() {
      return this.questionnaires.find(
        (questionnaire) => questionnaire.id === Number(this.page)
      )
    },
    progress() {
      return ((Number(this.page) - 1) / this.questionnaires.length) * 100
    },
    total() {
      return this.questionnaires.length
    },
    page() {
      return Number(this.$route.params.page)
    },
    nextPage() {
      return this.page + 1
    },
    prevPage() {
      return this.page - 1
    },
  },
  watch: {
    page(page) {
      if (this.questionnairesById(page)) {
        this.selected = this.questionnairesById(page)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.service-checkbox {
  font-size: 18px;
  color: #444;
}
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-284 -64)'%3E%3Cg transform='translate(16 56)'%3E%3Cpath d='M268 8h24v24h-24z'/%3E%3Cpath d='M280.04 22.49a.75.75 0 0 0 .53-.22l4.72-4.5a.75.75 0 0 0-1.06-1.06l-4.19 4-4.25-4a.75.75 0 0 0-1.06 1.06l4.78 4.5c.14.141.33.22.53.22z' fill='%23AAA'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
    right 0.75rem center/8px 10px no-repeat;
  background-size: 24px 24px;
  // background-image: url(/assets/material-arrow-black-chevron-right.svg) !important;
}
</style>
