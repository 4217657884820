<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/about-1.png"
        srcset="@/assets/images/about-1.png 2x, @/assets/images/about-1.png 3x"
      />
    </div>
    <div class="pt-50px pb-50px">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          เกี่ยวกับเรา
        </span>
        <span
          class="text-left text-gray mt-20px mx-16px"
          style="font-size: 1rem"
        >
          จากประสบการณ์ ที่ต้องให้การดูแล พ่อแม่ และ คนที่เรารัก
          ขณะที่เจ็บป่วยในขณะที่ญาติไม่มีความรู้เกี่ยวกับพยาบาล
          หรือการดูแลผู้ป่วยมาก่อนเป็นเรื่องที่ไม่ง่ายเลย
          การดูแลที่ดีเพื่อให้คนไข้กลับมามีคุณภาพชีวิตที่ดีขึ้นจึงจำเป็นที่ต้องมีทีมงานที่มีประสบการณ์
          ‘มา-ดู-แล’ ทดแทนให้ นอกจากจะเป็นทีมที่ดูแลแทนญาติได้อย่างน่าวางใจแล้ว
          ยังทำให้ญาติสามารถปฏิบัติงานในหน้าที่ความรับผิดชอบ ได้อย่างคลายกังวล
          ทำให้ทั้งคนไข้และญาติ คลายความกังวล มีสภาพชีวิต และจิตใจดีขึ้น<br /><br />
          ‘มา-ดู-แล’ เป็นทีมงานที่มาดูแลโดยทีมงานพยาบาลมืออาชีพ
          มีจุดประสงค์เพื่อปรับคุณภาพชีวิตของผู้ป่วย
          ลูกหลานที่ต้องดูแลในขณะเจ็บป่วยให้กลับมาใช้ชีวิต ที่มีคุณภาพเสมือน
          และใกล้เคียงก่อนหน้าที่บุคคลที่เป็นที่รักจะเกิดเจ็บป่วยขึ้น ‘มา-ดู-แล’
          เป็นทีมงานที่ประกอบไปด้วยทีมพยาบาล
          และแพทย์ที่สามารถให้คำปรึกษาในขณะที่ออกจากโรงพยาบาล
          หรือต้องฟื้นฟูอยู่ที่บ้าน
        </span>
      </div>
    </div>
    <div class="pt-40px pb-50px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <img
          class="px-24px"
          alt=""
          src="@/assets/images/about-2.png"
          srcset="
            @/assets/images/about-2.png 2x,
            @/assets/images/about-2.png 3x
          "
        />
        <span
          class="text-center text-navy mt-30px mx-24px"
          style="font-size: 1rem; font-weight: 300"
        >
          ‘มา-ดู-แล’ เป็นทีมงานรุ่นใหม่
          ที่สามารถรายงานสุขภาพคนไข้ในแต่ละวันให้ญาติรับทราบผ่านระบบ Mobile
          Application และสามารถรายงานผลประจำวันของคนไข้ผ่าน Line@
          เพื่อให้ญาติคนไข้สามารถรับรู้ และรับทราบสถานะของคนไข้ตลอดเวลา
          ทีมงานยังให้บริการด้วยทีมงานอื่นๆ ที่ต้องดูแลด้านกายภาพ
          และการให้อาหารคนไข้ตามคำสั่งของแพทย์อีกด้วย
        </span>
        <span
          class="text-center text-navy pt-20px mx-24px"
          style="font-size: 1rem; font-weight: 300"
        >
          เราหวังเป็นอย่างยิ่งว่า เราสามารถมาดูแล<br />
          คนที่คุณรักของท่านให้กลับมามีคุณภาพชีวิตที่ดี
        </span>
        <span class="text-center" style="color: #4a9afa; font-size: 5rem">
          ,,
        </span>
      </div>
    </div>
    <HealthEvoluation />
    <ConsultExpert />
    <JoinUs />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ConsultExpert from '@/components/ConsultExpert.vue'
import JoinUs from '@/components/JoinUs.vue'
export default {
  name: 'Ambulance',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ConsultExpert,
    JoinUs,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-50px {
  padding-bottom: 50px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-20px {
  padding-top: 20px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-40px {
  padding-top: 40px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
