<template>
  <div class="border">
    <div class="bg-primary px-20px pt-60px d-flex flex-column min-vh-100">
      <div @click="prev" class="cursor-pointer">
        <IconArrowBack class="align-self-center" />
      </div>
      <div class="align-self-center mt-50px mb-70px">
        <img
          alt=""
          src="@/assets/logo/madoolae/black/group.png"
          srcset="
            @/assets/logo/madoolae/black/group@2x.png 2x,
            @/assets/logo/madoolae/black/group@3x.png 3x
          "
        />
      </div>
      <span
        class="text-white align-self-center"
        style="font-size: 1.25rem; font-weight: 600"
        >ให้เราดูแลคุณ</span
      >
      <span
        class="text-white align-self-center"
        style="font-size: 1.25rem; font-weight: 600"
        >คลิกเพื่อทำแบบประเมินเลย
      </span>
      <div class="align-self-center">
        <b-button
          v-scroll-to="'#content'"
          pill
          class="text-primary bg-white py-12px px-65px mt-70px mb-100px"
          size="lg"
          variant="primary"
          style="font-size: 0.875rem; font-weight: 500"
          >เริ่มต้น</b-button
        >
      </div>
    </div>
    <div class="pt-65px px-20px d-flex flex-column" id="content">
      <div class="mb-18px" style="font-size: 20px">
        เลือกบริการที่สนใจ<br />
        (สามารถตอบได้มากกว่า 1 ข้อ)
      </div>
      <b-form-checkbox-group v-model="selected">
        <div
          v-for="service in services"
          :key="service.id"
          @click.self="onClick(service)"
          class="mb-20px service-checkbox py-13px px-18px d-flex flex-column cursor-pointer"
          style="
            border-radius: 4px;
            border: solid 1px #f2f3f4;
            background-color: #f3f5f8;
          "
        >
          <div class="d-flex" @click.self="onClick(service)">
            <b-form-checkbox
              :ref="`${service.name}#${service.id}`"
              :value="service"
            ></b-form-checkbox>
            <div class="mb-10 ml-10px" @click.self="onClick(service)">
              {{ service.name }}
            </div>
          </div>
          <div
            v-if="service.id === 5"
            @click.self="onClick(service)"
            class="px-14px py-10px mt-17px"
            style="background-color: #f9f9f9; font-size: 0.875rem; color: #aaa"
          >
            ทำแผล,ใส่สายสวนปัสสาวะ,ใส่สายให้อาหารทางจมูกและปาก
          </div>
        </div>
      </b-form-checkbox-group>
    </div>
    <div class="d-flex justify-content-center mt-50px mb-17px">
      <button
        :disabled="!selected || selected.length === 0"
        @click="contact"
        type="button"
        class="btn btn-link"
      >
        สนใจให้ติดต่อกลับ
      </button>
    </div>
    <div class="d-flex justify-content-center mb-56px">
      <b-button
        :disabled="!selected || selected.length === 0"
        @click="next"
        pill
        size="lg"
        class="px-35px"
        variant="primary"
        style="font-size: 0.875rem; font-weight: 500"
        >ตอบคำถามเพิ่มเติมเพื่อประเมินค่าบริการ</b-button
      >
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import IconArrowBack from '@/assets/icons/ic-arrow-back.vue'
import { getServices } from '@/resources/assessment.provider.js'
import liff from '@line/liff'
export default {
  name: 'PreAssessment',
  components: { IconArrowBack },
  data() {
    return {
      selected: [],
      services: [],
    }
  },
  methods: {
    ...mapActions(['setServices']),
    prev() {
      liff.closeWindow()
      this.$router.go(-1)
    },
    next() {
      this.setServices(this.selected)
      this.selected = []
      if (this.hasSelectedServiceNurse) {
        this.$router.push({
          name: 'PreAssessmentForm',
          params: { page: 1 },
        })
      } else {
        this.$router.push({
          name: 'PreAssessmentContact',
        })
      }
    },
    contact() {
      this.setServices(this.selected)
      this.selected = []
      this.$router.push({
        name: 'PreAssessmentContact',
      })
    },
    onClick(key) {
      const index = this.selected.indexOf(key)
      if (index >= 0) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(key)
      }
    },
    scrollTo() {
      // this.$refs.content.scrollTop = 100
      // var container = this.$el
      // console.log(container)
      // container.scrollTop = 200
      this.$refs.content.scrollTop = 200
    },
  },
  computed: {
    ...mapGetters({
      selectedServices: 'services',
      hasSelectedServiceNurse: 'hasSelectedServiceNurse',
    }),
  },
  async mounted() {
    try {
      const { data } = await getServices()
      this.services = data.data
    } catch (error) {
      console.log(error)
    }
    this.selected = this.selectedServices
  },
}
</script>
<style lang="scss" scoped>
.service-checkbox {
  font-size: 18px;
  color: #444;
}
</style>
