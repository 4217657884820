<template>
  <div class="px-27px py-25px d-flex flex-column">
    <div
      class="align-self-center py-12px mb-40px d-flex justify-content-center"
      style="width: 210px; height: 165px"
    >
      <img
        height="100%"
        src="@/assets/logo/madoolae/color/group.png"
        srcset="
          @/assets/logo/madoolae/color/group@2x.png 2x,
          @/assets/logo/madoolae/color/group@3x.png 3x
        "
        class="Group"
        alt=""
      />
    </div>
    <div>
      <div
        class="label-input mb-12px"
        style="
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #7b7890;
        "
      >
        เบอร์โทรศัพท์มือถือ
      </div>
      <input
        id="username"
        class="base-input mb-20px"
        type="number"
        pattern="[0-9]*"
        v-model="username"
        :maxlength="15"
      />
      <button type="button" class="base btn w-100" @click="checkUserState">
        ตกลง
      </button>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-column pt-29px"
    >
      <span
        style="
          font-family: Sarabun;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #0f0a39;
        "
        >ไม่สามารถเข้าใช้งานได้?</span
      >
      <span
        style="
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #7b7890;
        "
      >
        ติดต่อเจ้าหน้าที่โทร 092-498-9779 หรือ
      </span>
      <span
        style="
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #7b7890;
        "
        >Line Official @madoolae</span
      >
    </div>
  </div>
</template>
<script>
import { loginState, getUserByLine } from '@/resources/auth.provider.js'
import liff from '@line/liff'
export default {
  data() {
    return {
      username: '',
    }
  },
  async beforeRouteEnter(to, from, next) {
    await liff.init({
      liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    })
    if (liff.isLoggedIn()) {
      let username = ''
      try {
        const { userId } = await liff.getProfile()
        const { data } = await getUserByLine(userId)
        username = data.username
        try {
          const {
            data: { state },
          } = await loginState({
            username: username,
          })
          switch (state) {
            case 'success':
              // password
              next({
                name: 'Passcode',
                query: { username: username },
              })
              break
            case 'set-password':
              // password
              next({
                name: 'OTP',
                query: { username: username },
              })
              break
            case 'invalid-username':
              this.$notify({
                type: 'error',
                title: 'ไม่พบข้อมูล',
                duration: 2000,
              })
              break
            default:
              break
          }
        } catch (error) {
          this.$notify({
            type: 'success',
            title: 'เข้าสู่ระบบสำเร็จ',
            duration: 2000,
          })
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    }
    next()
  },
  async mounted() {
    // // get access token
    // await liff.init({
    //   liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    // })
    // if (liff.isLoggedIn()) {
    //   try {
    //     const { userId } = await liff.getProfile()
    //     const { data } = await getUserByLine(userId)
    //     this.username = data.username
    //     await this.checkUserState()
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  },
  methods: {
    notifySometingWentWrong() {
      this.$notify({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น โปรดลองใหม่อีกครั้งภายหลัง',
        duration: 2000,
      })
    },
    async checkUserState() {
      try {
        const { data } = await loginState({
          username: this.username,
        })
        switch (data.state) {
          case 'success':
            // password
            this.$router.push({
              name: 'Passcode',
              query: { username: this.username },
            })
            break
          case 'set-password':
            // password
            this.$router.push({
              name: 'OTP',
              query: { username: this.username },
            })
            break
          case 'invalid-username':
            this.$notify({
              type: 'error',
              title: 'ไม่พบข้อมูล',
              duration: 2000,
            })
            break
          default:
            this.notifySometingWentWrong()
            break
        }
        console.log(data)
      } catch (error) {
        this.notifySometingWentWrong()
      }
    },
  },
}
</script>
<style scoped>
input.base-input {
  width: 100%;
  height: 44px;
  padding: 11px 214px 12px 17px;
  opacity: 0.8;
  border-radius: 10px;
  background-color: #f4f5f7;
  border: none;
}
input.base-input:focus {
  outline: 0;
}
button.base {
  height: 44px;
  color: #fff;
  padding: 10px 139px;
  border-radius: 8px;
  background-color: #0090d4;
  border: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* button.base-input:focus {
  outline: 0;
} */
</style>
