<template>
  <div class="px-27px py-25px d-flex flex-column" @click="focusPasscode">
    <div class="mb-75px d-flex">
      <div
        class="px-8px py-5px cursor-pointer"
        style="
          border-radius: 8px;
          border: solid 1px #e2e6e8;
          background-color: #fff;
        "
      >
        <b-icon
          @click="back"
          icon="chevron-left"
          font-scale="1"
          style="color: #0f0a39"
        ></b-icon>
      </div>
    </div>
    <span class="password mb-58px align-self-center">{{ title }}</span>
    <div
      class="align-self-center d-flex flex-column"
      :class="{ 'mb-132px': invalid, 'mb-174px': !invalid }"
    >
      <InputPasscode
        ref="inputPasscode"
        :invalid="invalid"
        v-model="passcode"
      />
      <div
        v-if="invalid"
        class="pt-18px align-self-center"
        style=" font-size: 0.875rem;
  line-height: 1.71;
  color: #f00;˝"
      >
        รหัสไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง
      </div>
    </div>
    <button
      type="button"
      :disabled="disabled"
      class="base btn w-100 cursor-pointer"
      @click="next"
    >
      ตกลง
    </button>
  </div>
</template>
<script>
import InputPasscode from '@/components/InputPasscode'
import { changePassword, login } from '@/resources/auth.provider.js'
// import IconArrowChevronLeft from '@/components/Icons/IconArrowChevronLeft'
export default {
  components: {
    InputPasscode,
    // IconArrowChevronLeft,
  },
  props: {
    username: {
      type: String,
      require: true,
    },
    pin: {
      type: String,
      require: true,
    },
    token: {
      type: String,
      require: true,
    },
    setPasscode: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      page: 'passcode',
      passcode: '',
      store: {
        passcode: '',
        confirm: '',
      },
      invalid: false,
    }
  },
  mounted() {
    this.focusPasscode()
  },
  methods: {
    focusPasscode() {
      this.$refs.inputPasscode.focus()
    },
    async next() {
      if (this.page === 'passcode') {
        this.page = 'confirm'
        this.store.passcode = this.passcode
        this.passcode = ''
      } else {
        if (this.store.passcode !== this.passcode) {
          this.invalid = true
          return
        }
        this.store.confirm = this.passcode
        this.passcode = ''

        try {
          await changePassword({
            username: this.username,
            password: this.store.passcode,
            otpPin: this.pin,
            otpToken: this.token,
          })
          const { data } = await login({
            username: this.username,
            password: this.store.passcode,
          })
          localStorage.setItem('token', data.token)
          localStorage.setItem('refreshToken', data.refreshToken)
          if (!this.setPasscode) {
            this.$notify({
              type: 'success',
              title: 'เข้าสู่ระบบสำเร็จ',
              duration: 2000,
            })
          }

          this.$router.push({ name: 'Profile' })
        } catch (error) {
          console.log(error)
        }
      }
    },
    back() {
      if (this.page === 'passcode') {
        this.$router.back()
      } else {
        this.page = 'passcode'
        this.passcode = this.store.passcode
      }
    },
  },
  watch: {
    passcode(passcode) {
      if (this.page === 'confirm' && passcode.length !== 6) {
        this.invalid = false
      }
    },
  },
  computed: {
    title() {
      if (this.page === 'passcode') return 'กรุณาตั้งรหัสผ่าน'
      return 'ยืนยันรหัสผ่าน'
    },
    disabled() {
      return this.passcode.length !== 6
      // if (this.page === 'passcode') return this.passcode.length !== 6
      // return this.passcode.length !== 6 || this.store.passcode !== this.passcode
    },
    // invalid() {
    //   if (this.page === 'passcode') return false
    //   return this.passcode.length === 6 && this.store.passcode !== this.passcode
    // },
  },
}
</script>

<style scoped>
.password {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0f0a39;
}
.password- {
  font-family: Sarabun;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7b7890;
}
.password- .phone {
  font-weight: 500;
  color: #0090d4;
}

.passcode {
  width: 27px;
  height: 27px;
  border-radius: 100px;
  background-color: #f5f6fa;
  border: none;
}

.passcode.active {
  background-color: #0090d4;
}

button.base {
  height: 44px;
  color: #fff;
  padding: 10px 139px;
  border-radius: 8px;
  background-color: #0090d4;
  border: none;
}

button.base:disabled {
  opacity: 1;
  background-color: #d6d9de;
}
</style>
