<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/physical-therapist.png"
        srcset="
          @/assets/images/physical-therapist.png 2x,
          @/assets/images/physical-therapist.png 3x
        "
      />
    </div>
    <div class="pt-45px pb-32px" style="background-color: #deeefe">
      <div class="d-flex flex-column">
        <span class="text-left text-navy pl-16px" style="font-size: 1.75rem">
          นักกิจกรรมบำบัด <br />(Occupational Therapist - OT)
        </span>
        <span
          class="text-left text-navy mt-20px mx-16px"
          style="font-size: 1rem"
        >
          เป็นวิชาชีพทางวิทยาศาสตร์สุขภาพที่เกี่ยวข้องกับการส่งเสริมสุขภาพ
          และความเป็นอยู่ที่ดีโดยอาศัยกิจกรรมเป็นสื่อเป้าหมายเบื้องต้นของกิจกรรมบำบัด
          คือ การส่งเสริมให้บุคคลต่างๆ ไม่ว่าจะเป็นผู้ป่วย
          ผู้ที่มีความผิดปกติทางกาย เด็กที่มีปัญหาด้านพัฒนาการหรือการเรียนรู้
          ผู้ป่วยจิตเวช ผู้สูงอายุ ฯลฯ สามารถทำกิจกรรมต่างๆ
          ในชีวิตประจำวันได้อย่างเต็มความสามารถ
          ซึ่งนักกิจกรรมบำบัดจะต้องอาศัยความรู้หลายๆ ด้าน
          เพื่อช่วยลดข้อจำกัดทั้งทางร่างกาย จิตใจ และสังคมของบุคคลเหล่านั้น
          อีกทั้งยังอาจต้องช่วยปรับ/ให้คำแนะนำในการปรับสภาพแวดล้อม
          เพื่อช่วยให้พวกเขาสามารถทำกิจกรรมต่างๆ
          ในชีวิตประจำวันได้อย่างเต็มศักยภาพ ผู้ประกอบวิชาชีพกิจกรรมบำบัด
          ต้องสอบใบอนุญาตประกอบโรคศิลปะ สาขากิจกรรมบำบัดก่อน
          จึงจะสามารถปฏิบัติงานได้
        </span>
        <ServiceEvoluation price="1,500" />
      </div>
    </div>
    <ServiceProcess />
    <HealthEvoluation />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HealthEvoluation from '@/components/HealthEvoluation.vue'
import ServiceEvoluation from '@/components/ServiceEvoluation.vue'
import ServiceProcess from '@/components/ServiceProcess.vue'
export default {
  name: 'PhysicalTherapist',
  components: {
    Header,
    Footer,
    HealthEvoluation,
    ServiceEvoluation,
    ServiceProcess,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-32px {
  padding-bottom: 32px;
}
.pl-16px {
  padding-left: 16px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-45px {
  padding-top: 45px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-navy {
  color: #01234b;
}
</style>
