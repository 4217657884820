<template>
  <div
    class="profile-info d-flex justify-content-between pt-13px pl-24px pb-28px pr-20px"
    id="nav-profile"
  >
    <div>
      <img
        src="@/assets/logo/madoolae/color/logo.png"
        srcset="
          @/assets/logo/madoolae/color/logo@2x.png 2x,
          @/assets/logo/madoolae/color/logo@3x.png 3x
        "
        class="Logo"
      />
    </div>
    <div id="aaa" @click="hover" class="cursor-pointer">
      <b-avatar
        class="mr-6px"
        size="2.5rem"
        :src="user.profileImage"
      ></b-avatar>
      <IconArrowChevronDown v-if="show" style="border: none" />
      <IconArrowChevronUp v-else />
    </div>
    <b-popover
      target="aaa"
      placement="topleft"
      triggers=""
      :show="show"
      container="nav-profile"
      custom-class="popper pt-34px px-20px pb-20px"
      ref="popover"
    >
      <div class="d-flex pb-14px" style="">
        <b-avatar
          class="mr-10px"
          size="3.125rem"
          :src="user.profileImage"
        ></b-avatar>
        <div class="">
          <div class="title">บัญชี</div>
          <div class="sub-title">{{ fullName }}</div>
        </div>
      </div>
      <div
        class="d-flex justify-content-between pt-23px"
        style="border-top: solid 1px #e3e8f1"
      >
        <b-button variant="link" class="p-0 link" @click="logout"
          >ออกจากระบบ</b-button
        >
        <b-button
          variant="outline-primary setting px-20px py-6px"
          @click="toSetting"
          >ตั้งค่าบัญชี</b-button
        >
      </div>
    </b-popover>
  </div>
</template>

<script>
import IconArrowChevronUp from '../Icons/IconArrowChevronUp'
import IconArrowChevronDown from '../Icons/IconArrowChevronDown'
import jwtDecode from 'jwt-decode'
export default {
  components: {
    IconArrowChevronUp,
    IconArrowChevronDown,
  },
  data() {
    return {
      show: false,
      user: {},
    }
  },
  mounted() {
    const token = localStorage.getItem('token')
    this.user = jwtDecode(token)
  },
  methods: {
    hover() {
      this.show = !this.show
    },
    toSetting() {
      this.$router.push({
        name: 'ProfileSetting',
      })
    },
    logout() {
      localStorage.setItem('token', '')
      localStorage.setItem('refreshToken', '')
      this.$router.push({ name: 'Login' })
    },
  },
  computed: {
    fullName() {
      if (this.user) {
        if (this.user.firstName && this.user.lastName) {
          return this.user.firstName + ' ' + this.user.lastName
        }
      }
      return ''
    },
  },
}
</script>

<style scoped>
.profile-info {
  background-color: #ffffff;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  box-shadow: 0 -4px 10px 0 rgba(169, 168, 168, 0.5);
  background-color: #fff;
}
div >>> .popover-body {
  padding: 0px 0px 0px 0px !important;
}
.popper {
  width: 270px;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
  background-color: #fff;
  border: none;
}
div >>> .arrow {
  left: 88% !important;
  /* height: 100px; */
}
div >>> .arrow::after {
  /* display: none; */
}
div >>> .arrow::before {
  border-bottom-color: rgba(169, 168, 168, 0.24);
}
/* div.arrow ::before {
  border: none !important;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24) !important;
}

div.arrow ::after {
  border: none !important;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24) !important;
} */
/* div >>> .popover-body {
  width: 270px;
  padding: 0.875rem 0.5rem 0.5rem 0.5rem;
  border-radius: 12px;
  box-shadow: 0 0 5px 0 rgba(169, 168, 168, 0.24);
  background-color: #fff;
  border: none;
} */
.title {
  font-family: Sarabun;
  font-size: 0.875rem;
  color: #7b7890;
}
.sub-title {
  font-family: Sarabun;
  font-size: 1rem;
  font-weight: 500;
  color: #2f2f2f;
}

.link {
  font-family: Sarabun;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.71;
  color: #0090d4;
}
.setting {
  border-radius: 8px;
  border: solid 1px #0090d4;
  background-color: #dbedff;
}
</style>
