<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="py-40px">
      <div class="d-flex flex-column">
        <span
          class="text-left text-navy pl-16px"
          style="font-weight: bold; font-size: 1.25rem"
        >
          นโยบายคุกกี้ (Cookie Policy)
        </span>
        <span
          class="text-left text-gray mt-20px mx-16px"
          style="font-size: 1rem"
        >
          นโยบายการใช้เทคโนโลยีการติดตาม(คุกกี้) ถูกใช้งานบน www.ma-doo-lae.com
          เว็บไซต์ ดำเนินงานและผลิตโดย บริษัทมาดูแล จำกัด
          ซึ่งเว็บไซต์นี้มีการใช้คุกกี้และเทคโนโลยีอื่นๆ ที่คล้ายๆ กัน
          แนะนำให้อ่าน นโยบายความเป็นส่วนตัว (Privacy Policy) คู่กับ
          นโยบายคุกกี้ (Cookie Policy) ฉบับนี้
          <br /><br />
          การใช้เทคโนโลยีการติดตาม(คุกกี้) เป็นมาตรฐานทั่วไปบนอินเทอร์เน็ต
          และเบราวเซอร์สำหรับท่องอินเทอร์เน็ตทั่วไปจะตั้งค่ายอมรับการใช้งานคุกกี้อัตโนมัติ
          อย่างไรก็ตามการตัดสินใจว่าจะใช้คุกกี้หรือไม่ขึ้นอยู่กับคุณ
          คุณสามารถเลือกที่จะยอมรับหรือไม่ยอมรับการใช้งานคุกกี้ได้
          หรือจะตั้งค่าเบราวเซอร์เพื่อไม่ยอมรับการใช้งานคุกกี้
          หรือตั้งให้เตือนเวลาที่เว็บไซต์ส่งคุกกี้มาให้คุณ
          <br /><br />
          ในกรณีที่คุณใช้งานเว็บไซต์เราต่อ
          คุณได้ทำการยอมรับการใช้เทคโนโลยีการติดตาม(คุกกี้)ของเราตามนโยบายการใช้เทคโนโลยีการติดตาม(คุกกี้)
          ฉบับนี้ หากคุณไม่ต้องการใช้คุกกี้บนเว็บไซต์เรา
          คุณสามารถตั้งค่าไม่รับคุกกี้บนเบราวเซอร์ หรือไม่ใช้งานเว็บไซต์
          รายละเอียดวิธีการตั้งค่าคุกกี้บนเบราวเซอร์ที่นิยมใช้กันทั่วไปอยู่ในข้อมูลด้านล่าง
          อย่างไรก็ตามหากปิดการคุกกี้ ฟั่งก์ชั่น
          คุณสมบัติบางอย่างของเว็บไซต์อาจทำงานได้ไม่สมบูรณ์
          <br /><br />
          <span style="font-weight: bold">คุกกี้คืออะไร?</span>
          <br /><br />
          คุกกี้เป็นไฟล์ข้อมูลขนาดเล็ก จัดเก็บในลักษณะของ text ไฟล์
          โดยแพลตฟอร์มของเราจะส่งคุกกี้ไปยังเบราว์เซอร์ของท่าน
          และอาจมีการบันทึกลงในเครื่องคอมพิวเตอร์หรืออุปกรณ์ที่ท่านใช้เข้าแพลตฟอร์ม
          ซึ่งคุกกี้มีประโยชน์สำคัญในการทำให้แพลตฟอร์มสามารถจดจำการตั้งค่าต่างๆ
          บนอุปกรณ์ของท่านได้
          แต่คุกกี้จะไม่มีการเก็บข้อมูลที่สามารถใช้ระบุตัวตนท่าน ทั้งนี้
          ท่านสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ได้ที่
          <a href="https://www.allaboutcookies.org" target="_blank"
            >www.allaboutcookies.org</a
          >
          <br /><br />
          <span style="font-weight: bold">คุกกี้ใช้ทำอะไร?</span>
          <br /><br />
          เราใช้คุกกี้เพื่อช่วยให้ประสบการณ์การเข้าเว็บไซต์ของเราดีขึ้น
          ใช้เว็บไซต์ง่ายขึ้น อีกทั้งคุกกี้ยังช่วยให้ฟั่งชั่นต่างๆ
          บนเว็บไซต์ทำงานเร็วขึ้น ตัวอย่างเช่น
          เราใช้คุกกี้เพื่อจดจำการตั้งค่าต่างๆ บนเว็บไซต์ของคุณ (ภาษา,
          ประเทศเป็นต้น) เพื่อให้ประสบการณ์การเข้าเว็บของเราง่ายขึ้นในอนาคต
          นอกจากนี้เรายังใช้คุกกี้เพื่อรวบรวมสถิติโดยรวมที่ไม่ระบุชื่อซึ่งช่วยให้เราเข้าใจว่าผู้คนใช้งานเว็บไซต์ของเราอย่างไรและเพื่อช่วยเราปรับปรุงโครงสร้างและเนื้อหา
          โดยข้อมูลนี้จะไม่เปิดเผยตัวตนของคุณ “ในโลกแห่งความเป็นจริง”
          ในบางครั้งหากเราได้รับความยินยอมจากคุณล่วงหน้าเราอาจใช้คุกกี้,
          แท็กหรือเทคโนโลยีที่คล้ายคลึงกันเพื่อรับข้อมูลที่ทำให้เราสามารถแสดงให้คุณเห็นเว็บไซต์หรือจากเว็บไซต์จากบุคคลที่สาม
          หรือการโฆษณาอื่นๆ ตามการวิเคราะห์พฤติกรรมการใช้งานเว็บไซต์ของคุณ
          <br /><br />
          <span style="font-weight: bold"
            >เราไม่ใช้คุกกี้ทำอะไรบนเว็บไซต์นี้บ้าง?</span
          >
          <br /><br />
          เราจะไม่เก็บข้อมูลส่วนบุคคลที่สำคัญ เช่น ที่อยู่, รหัสผ่าน,
          ข้อมูลบัตรเครดิต/เดบิตของคุณ ฯลฯ ในคุกกี้บนเว็บไซต์นี้
          <br /><br />
          <span style="font-weight: bold">ใครใช้ข้อมูลที่อยู่บนคุกกี้?</span>
          <br /><br />
          ข้อมูลบนคุกกี้ของเว็บไซต์นี้จะถูกใช้โดยเราเท่านั้น
          ยกเว้นแต่คุกกี้อื่นๆ ที่เรียกว่า “คุกกี้จากบุคคลที่สาม”
          ซึ่งจะถูกใช้งานและจัดการโดยหน่วยงานภายนอก
          ซึ่งคุกกี้จากบุคคลที่สามเหล่านี้เป็นบริการที่เราขอนำมาใส่ในเว็บไซต์ด้วยตัวเราเอง
          เพื่อปรับปรุงบริการและประสบการณ์ของผู้ใช้เมื่อเรียกดูเว็บไซต์ของเรา
          บริการหลักที่ใช้ “คุกกี้ของบุคคลที่สาม”
          เหล่านี้ใช้เพื่อรับสถิติการเข้าถึง เช่น
          เราอาจมีการฝังเนื้อหาหรือวีดีโอที่มาจากโซเชียลมีเดียเว็บไซต์ เช่น
          YouTube หรือ Facebook เป็นต้น
          ซึ่งเว็บไซต์เหล่านี้จะมีการกำหนดและตั้งค่าคุกกี้ขึ้นมาเองที่เราไม่สามารถควบคุมหรือรับผิดชอบต่อคุกกี้เหล่านั้นได้
          ท่านควรเข้าไปอ่านและศึกษานโยบายคุกกี้ของบุคคลภายนอกที่เกี่ยวข้องดังกล่าวข้างต้นสำหรับข้อมูลเพิ่มเติมเกี่ยวกับการใช้คุกกี้ของพวกเขา
          <br /><br />
          <span style="font-weight: bold"
            >ประเภทของคุกกี้ที่ใช้แบ่งตามวัตถุประสงค์การใช้งาน</span
          >
          <br /><br />
          <span style="font-weight: bold"
            >คุกกี้ที่จำเป็น (Strictly necessary cookies)</span
          >
          <br /><br />
          คุกกี้เหล่านี้จำเป็นสำหรับการทำงานกับเว็บไซต์ให้ถูกต้องและสามารถทำงานได้ตามปกติ
          เช่น การจดจำการกระทำก่อนหน้านี้
          การเลือกเครือข่ายที่สามารถโหลดข้อมูลได้เร็วที่สุด ฯลฯ
          โดยคุกกี้ประเภทนี้จะไม่ระบุตัวตนของท่านได้
          และไม่สามารถปิดการใช้งานของคุกกี้ประเภทนี้ผ่านระบบของเว็บไซต์ของเราได้เพราะจะส่งกับประสิทธิภาพการเข้าชมเว็บไซต์
          <br /><br />
          <span style="font-weight: bold"
            >คุกกี้เพื่อช่วยในการใช้งาน (Functional Cookies)</span
          >
          <br /><br />
          คุกกี้ประเภทนี้ช่วยในการทำงานของไซต์และการปรับแต่งไซต์ให้เหมาะกับผู้ใช้
          เช่น ภาษาที่แสดงบนเว็บไซต์ ฯลฯ
          เพื่อให้ท่านสามารถใช้งานเว็บไซต์ได้สะดวกยิ่งขึ้น
          โดยไม่ต้องให้ข้อมูลหรือตั้งค่าใหม่ทุกครั้งที่ท่านเข้าใช้เว็บไซต์
          หากไม่ใช้คุกกี้ประเภทนี้
          ท่านอาจใช้งานเว็บไซต์ได้ไม่สะดวกและไม่เต็มประสิทธิภาพ
          <br /><br />
          <span style="font-weight: bold"
            >คุกกี้เพื่อการวิเคราะห์ (Analytical cookies)</span
          >
          <br /><br />
          บริษัทมาดูแล จำกัด ได้มีการใช้เทคโนโลยี Google Analytics
          มีวัตถุประสงค์เพื่อใช้เก็บข้อมูลเชิงลึกเกี่ยวกับรูปแบบการใช้งานของคุณบนเว็บไซต์ของเรา
          โดยข้อมูลนี้ประกอบไปด้วย หน้าเว็บไซต์ที่คุณเข้าชม ลิงค์ที่คุณคลิก
          ระยะเวลาที่คุณเข้าชมแต่ละหน้า
          โดยเราใช้ข้อมูลนี้เพื่อวิเคราะห์รูปแบบการใช้งานของผู้ใช้
          และเพื่อให้เว็บไซต์ทำงานได้อย่างถูกต้อง
          เราขอยืนยันว่าจะไม่ใช้ข้อมูลนี้เพื่อการตลาดหรือการโฆษณา
          และจะไม่เปิดเผยกับบุคคลที่สามแต่อย่างใด
          <br /><br />
          <span style="font-weight: bold">คุณจะลบคุกกี้ได้อย่างไร</span>
          <br /><br />
          ท่านสามารถลบคุกกี้ได้โดยศึกษาวิธีการต่างๆที่ระบุตามแต่ละเว็บเบราว์เซอร์ที่ท่านเลือกใช้ตามลิงก์ดังนี้
          <br /><br />
          &emsp;● Chrome คลิกอ่านรายละเอียด
          <a
            href="https://support.google.com/chrome/answer/95647?hl=th"
            target="_blank"
            >https://support.google.com/chrome/answer/95647?hl=th</a
          >
          <br /><br />
          &emsp;● Firefox คลิกอ่านรายละเอียด
          <a
            href="https://support.mozilla.org/th/kb/cookies-information-websites-store-on-your-computer"
            target="_blank"
            >https://support.mozilla.org/th/kb/cookies-information-websites-store-on-your-computer</a
          >
          <br /><br />
          &emsp;● Internet Explorer คลิกอ่านรายละเอียด
          <a
            href="https://support.microsoft.com/th/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11"
            target="_blank"
            >https://support.microsoft.com/th/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11</a
          >
          <br /><br />
          &emsp;● Safari for ios คลิกอ่านรายละเอียด
          <a href="https://support.apple.com/en-us/HT201265" target="_blank"
            >https://support.apple.com/en-us/HT201265</a
          >
          <br /><br />
          &emsp;● Chrome for android คลิกอ่านรายละเอียด
          <a
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=th&amp;oco=0"
            target="_blank"
            >https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=th&amp;oco=0</a
          >
          <br /><br />
          การเปลี่ยนแปลงของนโยบายของเรา
          <br /><br />
          นโยบายCookies นี้จะมีการปรับปรุงแก้ไขตามโอกาสเพื่อปฎิบัติตามกฎระเบียบ
          เราขอแนะนำให้ท่านตรวจเช็คเพื่อให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงข้อกำหนดดังกล่าว
          <br /><br />
          * แก้ไขล่าสุด : 1 พฤษภาคม 2565
        </span>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'CookiePolicy',
  components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pl-16px {
  padding-left: 16px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
