ContactUs
<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-34px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/work-with-us.png"
        srcset="
          @/assets/images/work-with-us.png 2x,
          @/assets/images/work-with-us.png 3x
        "
      />
    </div>
    <div class="pt-28px">
      <div class="d-flex flex-column">
        <span
          class="text-left text-navy pl-16px"
          style="font-size: 1.25rem; font-weight: bold"
        >
          ร่วมงานกับเราวันนี้
        </span>
        <span
          class="text-left text-gray mt-20px mx-16px"
          style="font-size: 1rem; font-weight: 300"
        >
          ร่วมงานกับเราวันนี้ พร้อมการดูแลที่ให้ความสำคัญกับการดูแลคนไข้
          ที่เป็นมากกว่าการดูแลระบบเดิม ทีมงานสามารถเข้าถึง
          การวางแผนจัดเวรผ่านแอปพลิเคชัน หรือ Line@ ตลอดจนรับทราบ
          และทีมพยาบาลสามารถบันทึกการดูแลคนไข้ผ่าน ‘มา-ดู-แล’ เว็บไซต์
          ทำให้การดูแลเป็นเรื่องที่สะดวก
          และให้การดูแลคนไข้มีประสิทธิภาพมากยิ่งขึ้น
          สามารถสื่อสารให้ทีมงานและญาติคนไข้ ทราบสถานะของคนไข้อย่างเป็นระบบ
          ทั้งปัจจุบัน และสถานการณ์ของคนไข้ก่อนหน้า
        </span>
      </div>
    </div>
    <div
      class="d-flex flex-column bg-primary mt-50px pb-40px mx-20px"
      style="border-radius: 10px"
    >
      <span
        class="text-left text-white mt-30px pl-20px"
        style="font-size: 1.125rem; font-weight: 600"
      >
        ตำแหน่งงานที่รับสมัคร
      </span>
      <span class="text-left text-white text-weight-500 mt-20px pl-20px">
        พยาบาลวิชาชีพ (Registered Nurse)<br />
        <span class="pl-16px text-weight-300">
          ● สำเร็จการศึกษาพยาบาลศาสตรบัณฑิต<br />
        </span>
        <span class="pl-16px text-weight-300">
          ● มีใบอนุญาติประกอบวิชาชีพการพยาบาลและ<br />
          &emsp;&emsp;การผดุงครรภ์ชั้น1
        </span>
      </span>
      <span class="text-left text-white text-weight-500 mt-20px pl-20px">
        ผู้ช่วยพยาบาล (PN)<br />
        <span class="pl-16px text-weight-300">
          ● มีใบประกาศนียบัตรผู้ช่วยพยาบาล 1 ปี
        </span>
      </span>
      <span class="text-left text-white text-weight-500 mt-20px pl-20px">
        พนักงานช่วยเหลือผู้ป่วย (NA)<br />
        <span class="pl-16px text-weight-300">
          ● มีใบประกาศนียบัตร นักเรียนบริบาล หลักสูตร 6 เดือน
        </span>
      </span>
      <span class="text-left text-white text-weight-500 mt-20px pl-20px">
        ผู้ดูแล (CG)<br />
        <span class="pl-16px text-weight-300">
          ● มีใบผ่านหลักสูตรการอบรม ตามจำนวน <br />
          &emsp;&emsp;420 ชั่วโมงขึ้นไป ประสบการณ์ทำงานมากกว่า 2 ปี<br />
        </span>
        <span class="pl-16px text-weight-300"> ● อายุไม่เกิน 40 ปี<br /> </span>
        <span class="pl-16px text-weight-300">
          ● ไม่เป็นโรคติดต่อร้ายแรงตามประกาศ<br />
          &emsp;&emsp;(พรบ โรคติดต่อ)<br />
        </span>
        <span class="pl-16px text-weight-300">
          ● มีทัศนคติที่ดีต่องานบริการ / สัมพันธภาพที่<br />
          &emsp;&emsp;ดีต่อเพื่อนร่วมงาน<br />
        </span>
        <span class="pl-16px text-weight-300">
          ● ไม่เป็นผู้ต้องคดี / ไม่มีประวัติอาชญากรรม
        </span>
      </span>
    </div>
    <div
      class="d-flex flex-column mt-20px mx-20px"
      style="border-radius: 10px; background-color: #01234b"
    >
      <span
        class="text-left text-white mt-30px pl-20px"
        style="font-size: 1.125rem; font-weight: 600"
      >
        เอกสารที่ต้องใช้
      </span>
      <span class="text-left text-white text-weight-300 mt-20px pl-34px">
        ● ใบสมัคร<br />
        ● สำเนาบัตรประชาชน<br />
        ● สำเนาทะเบียนบ้าน 2 ชุด<br />
        ● สำเนาใบปริญญาบัตร<br />
        ● สำเนาใบประกอบวิชาชีพ<br />
        ● สำเนารับรองการผ่านงาน (ถ้ามี)<br />
        ● รูปถ่าย 1-2 นิ้ว จำนวน 2 รูป<br />
        ● ใบตรวจสอบประวัติอาชญากรรม<br />
      </span>
      <span class="text-left text-white text-weight-300 mt-47px pl-20px">
        เพียงส่งอีเมล์มาที่ management@ma-doo-lae.com
        ทางเราจะทำการยืนยันตัวตนและเอกสารภายใน 3 วันทำการ
      </span>
      <b-button
        pill
        class="text-white bg-primary mt-40px"
        href="mailto:management@ma-doo-lae.com"
        size="lg"
        variant="primary"
        style="
          font-size: 0.875rem;
          font-weight: 500;
          margin-left: 32px;
          margin-right: 32px;
          margin-bottom: 40px;
        "
        >ร่วมงานกับเราคลิก</b-button
      >
    </div>
    <ConsultExpert />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ConsultExpert from '@/components/ConsultExpert.vue'
export default {
  name: 'WorkWithUs',
  components: {
    Header,
    Footer,
    ConsultExpert,
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.pb-40px {
  padding-bottom: 40px;
}
.pb-50px {
  padding-bottom: 50px;
}
.pl-16px {
  padding-left: 16px;
}
.pl-34px {
  padding-left: 34px;
}
.pt-20px {
  padding-top: 20px;
}
.pt-28px {
  padding-top: 28px;
}
.pt-34px {
  padding-top: 34px;
}
.pt-40px {
  padding-top: 40px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-47px {
  margin-top: 47px;
}
.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
.text-weight-500 {
  font-size: 0.875rem;
  font-weight: 500;
}
.text-weight-300 {
  font-size: 0.875rem;
  font-weight: 300;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
