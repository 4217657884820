<template>
  <div class="d-flex flex-column min-vh-100">
    <NavProfile />
    <div class="p-4 page-wrapper">
      <div class="d-flex mb-4">
        <div class="mr-4 card shadom-sm caret-wrapper text-center">
          <div @click="goBack" class="pointer">&#60;</div>
        </div>
        <div class="align-self-center title"><b>สรุปบันทึกพยาบาล</b></div>
      </div>
      <div
        class="d-flex flex-row justify-content-between align-items-center card shadow-sm px-4 py-3 rounded-8"
      >
        <div @click="toYesterday" class="pointer date-changer">&#60;</div>
        <div class="date-container date py-1">
          {{ date ? date.format('DD/MM/YYYY') : '-' }}
        </div>
        <div @click="toTomorrow" class="pointer date-changer">&#62;</div>
      </div>
    </div>
    <div class="px-3 py-4 content-wrapper">
      <div v-for="nurseNote in nurseNotes" :key="nurseNote.id" class="mb-4">
        <div>{{ formatToHHmm(nurseNote.lastUpdated) }}</div>
        <div class="summary">
          {{ nurseNote.summary ? nurseNote.summary : '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavProfile from '@/components/Layout/NavProfile'
import { getNurseNoteRequest, getPrevNextCaseNurseNote } from '@/resources/case.provider.js'
import moment from 'moment'

export default {
  components: {
    NavProfile,
  },
  data() {
    return {
      nurseNotes: [],
      date: null,
      prevDate: null,
      nextDate: null,
    }
  },
  async mounted() {
    const { caseId } = this.$route.params

    
    const resp = await getNurseNoteRequest(caseId, 
    {
      sortBy: 'updated_at desc',
    })
    if (resp.data.data.length > 0) {
      this.date = moment.unix(resp.data.data[0].lastUpdated)
      const nurseNotesResp = await getNurseNoteRequest(caseId, 
      {
        date: this.date.format('YYYY-MM-DD'),
        sortBy: 'updated_at desc',
      })
      this.nurseNotes = nurseNotesResp.data.data
      const prevNextResp = await getPrevNextCaseNurseNote(caseId, {
        date: this.date.format('YYYY-MM-DD')
      })
      if (prevNextResp.data.prev) {
        this.prevDate = moment.unix(prevNextResp.data.prev)
      }
      if (prevNextResp.data.next) {
        this.nextDate = moment.unix(prevNextResp.data.next)
      }
    } else {
      this.date = moment()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async toYesterday() {
      if (!this.prevDate) {
        return
      }
      const { caseId } = this.$route.params

      this.date = this.prevDate

      const resp = await getNurseNoteRequest(caseId, {
        date: this.date.format('YYYY-MM-DD'),
        sortBy: 'updated_at desc',
      })
      const prevNextResp = await getPrevNextCaseNurseNote(caseId, {
        date: this.date.format('YYYY-MM-DD')
      })
      if (prevNextResp.data.prev) {
        this.prevDate = moment.unix(prevNextResp.data.prev)
      }
      if (prevNextResp.data.next) {
        this.nextDate = moment.unix(prevNextResp.data.next)
      }
      this.nurseNotes = resp.data.data
    },
    async toTomorrow() {
      if (!this.nextDate) {
        return
      }
      const { caseId } = this.$route.params
      this.date = this.nextDate

      const resp = await getNurseNoteRequest(caseId, {
        date: this.date.format('YYYY-MM-DD'),
        sortBy: 'updated_at desc',
      })
      const prevNextResp = await getPrevNextCaseNurseNote(caseId, {
        date: this.date.format('YYYY-MM-DD')
      })
      if (prevNextResp.data.prev) {
        this.prevDate = moment.unix(prevNextResp.data.prev)
      }
      if (prevNextResp.data.next) {
        this.nextDate = moment.unix(prevNextResp.data.next)
      }
      this.nurseNotes = resp.data.data
    },
    formatToHHmm(unix) {
      return moment.unix(unix).format('HH:mm')
    },
  },
}
</script>

<style>
.date-container {
  width: 75%;
  text-align: center;
  background-color: #deecfd;
  border-radius: 50px;
}
.content-wrapper {
  background-color: white;
}

.page-wrapper {
  background-color: #f4f5f7;
}

.title {
  font-weight: 500;
}

.date {
  font-size: 17px;
}

.date-changer {
  color: #bcbcbc
}

.pointer {
  cursor: pointer;
}

.summary {
  color : #7b7890;
  font-size: 14px;
}

.title {
  font-size: 22px;
}

.caret-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 8px !important;
}

.rounded-8 {
  border-radius: 8px !important;
}
</style>
