import http from './index'

export function getServices() {
  return http.get('/api/service', {}, {})
}

export function getQuestionnaires() {
  return http.get('/api/questionnaire', {}, {})
}

export function getPrices() {
  return http.get('/api/price', {}, {})
  // return [
  //   {
  //     nurseLevel: 'ICU',
  //     price: 50000,
  //     priceUnit: 'MONTH',
  //   },
  //   {
  //     nurseLevel: 'NURSE',
  //     price: 20000,
  //     priceUnit: 'TIME',
  //   },
  //   {
  //     nurseLevel: 'PN',
  //     price: 100000,
  //     priceUnit: 'SHIFT',
  //   },
  //   {
  //     nurseLevel: 'NA',
  //     price: 800,
  //     priceUnit: 'DAY',
  //   },
  //   {
  //     nurseLevel: 'CG',
  //     price: 200,
  //     priceUnit: 'WEEK',
  //   },
  // ]
}

export function postAssessment(req) {
  return http.post('/api/lead', req, {})
}
