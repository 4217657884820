<template>
  <div class="pt-20px d-flex flex-column min-vh-100">
    <Header />
    <div class="pt-46px align-self-center">
      <img
        class="cover-image"
        alt=""
        src="@/assets/images/home-1.png"
        srcset="@/assets/images/home-1.png 2x, @/assets/images/home-1.png 3x"
      />
    </div>
    <span
      class="align-self-center text-black mt-48px"
      style="font-size: 2rem; font-weight: bold"
      >MA-DOO-LAE
    </span>
    <span class="align-self-center" style="color: #4a9afa; font-size: 1.5rem"
      >มา-ดู-แล
    </span>
    <div
      class="text-center text-gray mt-29px ml-25px mr-34px"
      style="font-size: 1rem"
    >
      ‘มา-ดู-แล’ เข้าใจถึงความต้องการของผู้รับบริการ
      และเห็นถึงความสำคัญของศูนย์กลางในการดูแลผู้ป่วยและผู้สูงอายุที่จะต้องได้รับการดูแล
      จากทีมพยาบาลมืออาชีพ ประสบการณ์สูง
      เพื่อให้เกิดความเข้าใจและการดูแลที่ถูกต้อง เหมาะสม ได้มาตรฐาน
      ทั้งก่อนเข้าโรงพยาบาล และหลังออกจากโรงพยาบาล ‘มา-ดู-แล’
      มีทีมให้บริการจากหลากหลายวิชาชีพแบบ ครบวงจร ให้คำปรึกษาและพาพบแพทย์
      ให้บริการการดูแลจากหัวหน้าทีมที่มีประสบการณ์สูงในการดูแลผู้ป่วยแต่ละท่าน
      ที่ต้องการการดูแลเป็นพิเศษ
    </div>
    <span
      class="align-self-center text-black mt-50px"
      style="font-size: 1.25rem; font-weight: bold"
      >บริการทั้งหมด
    </span>
    <b-button
      v-b-hover="handleHoverN"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredN ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToNursePage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredN"
          alt=""
          src="@/assets/icons/nurse-white.png"
          srcset="
            @/assets/icons/nurse-white@2x.png 2x,
            @/assets/icons/nurse-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/nurse.png"
          srcset="
            @/assets/icons/nurse@2x.png 2x,
            @/assets/icons/nurse@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredN ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >พยาบาล
          </span>
          <span
            class="text-left"
            :class="isHoveredN ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >ทีมงานประสบการณ์สูงของเราจะดูแลคนที่คุณรักแทนคุณ
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverNN"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredNN ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToNutritionistPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredNN"
          alt=""
          src="@/assets/icons/nutritionist-white.png"
          srcset="
            @/assets/icons/nutritionist-white@2x.png 2x,
            @/assets/icons/nutritionist-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/nutritionist.png"
          srcset="
            @/assets/icons/nutritionist@2x.png 2x,
            @/assets/icons/nutritionist@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredNN ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >นักโภชนาการ
          </span>
          <span
            class="text-left"
            :class="isHoveredNN ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >ทีมงานคุณภาพจะดูแล จัดการอาหารที่เหมาะสมแก่ผู้ป่วย
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverOT"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredOT ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToOccupationalTherapistPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredOT"
          alt=""
          src="@/assets/icons/physio-therapist-white.png"
          srcset="
            @/assets/icons/physio-therapist-white@2x.png 2x,
            @/assets/icons/physio-therapist-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/physio-therapist.png"
          srcset="
            @/assets/icons/physio-therapist@2x.png 2x,
            @/assets/icons/physio-therapist@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredOT ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >นักกายภาพบำบัด
          </span>
          <span
            class="text-left"
            :class="isHoveredOT ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >นักกายภาพจะช่วยเหลือผู้ป่วยให้มีคุณภาพชีวิตที่ดีขึ้น
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverPT"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredPT ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToPhysicalTherapistPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredPT"
          alt=""
          src="@/assets/icons/occupational-therapist-white.png"
          srcset="
            @/assets/icons/occupational-therapist-white@2x.png 2x,
            @/assets/icons/occupational-therapist-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/occupational-therapist.png"
          srcset="
            @/assets/icons/occupational-therapist@2x.png 2x,
            @/assets/icons/occupational-therapist@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredPT ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >นักกิจกรรมบำบัด
          </span>
          <span
            class="text-left"
            :class="isHoveredPT ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >สามารถประเมินและจัดกิจกรรมส่งเสริมให้ผู้ป่วยมีคุณภาพชีวิตที่ดีขึ้น
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverMP"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredMP ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToMedicalProcedurePage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredMP"
          alt=""
          src="@/assets/icons/nursing-procedures-white.png"
          srcset="
            @/assets/icons/nursing-procedures-white@2x.png 2x,
            @/assets/icons/nursing-procedures-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/nursing-procedures.png"
          srcset="
            @/assets/icons/nursing-procedures@2x.png 2x,
            @/assets/icons/nursing-procedures@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredMP ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >หัตถการทางการพยาบาล
          </span>
          <span
            class="text-left"
            :class="isHoveredMP ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >การใช้เครื่องมือและอุปกรณ์ตามแพทย์สั่ง พร้อมให้ดูแลอยู่ที่บ้าน
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverSC"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredSC ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToSpecimenCollectionPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredSC"
          alt=""
          src="@/assets/icons/blood-draw-white.png"
          srcset="
            @/assets/icons/blood-draw-white@2x.png 2x,
            @/assets/icons/blood-draw-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/blood-draw.png"
          srcset="
            @/assets/icons/blood-draw@2x.png 2x,
            @/assets/icons/blood-draw@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredSC ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >เจาะเลือดและเก็บสิ่งส่งตรวจ
          </span>
          <span
            class="text-left"
            :class="isHoveredSC ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >ช่วยให้บริการเจาะเลือด ฉีดวัคซีน และอื่นๆ
            ที่จำเป็นตามคำสั่งแพทย์โดยผู้ป่วยไม่ต้องไปโรงพยาบาล
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverV"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredV ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToVaccinationPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredV"
          alt=""
          src="@/assets/icons/vaccinate-white.png"
          srcset="
            @/assets/icons/vaccinate-white@2x.png 2x,
            @/assets/icons/vaccinate-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/vaccinate.png"
          srcset="
            @/assets/icons/vaccinate@2x.png 2x,
            @/assets/icons/vaccinate@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredV ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >ฉีดวัคซีน
          </span>
          <span
            class="text-left"
            :class="isHoveredV ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >ให้บริการจัดหาวัคซีนทั่วไป กับบุคคลทั่วไป หน่วยงานต่างๆ
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverTMH"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredTMH ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToTakeMeToHospitalPage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredTMH"
          alt=""
          src="@/assets/icons/take-to-doctor-white.png"
          srcset="
            @/assets/icons/take-to-doctor-white@2x.png 2x,
            @/assets/icons/take-to-doctor-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/take-to-doctor.png"
          srcset="
            @/assets/icons/take-to-doctor@2x.png 2x,
            @/assets/icons/take-to-doctor@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredTMH ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >พาไปหาหมอ
          </span>
          <span
            class="text-left"
            :class="isHoveredTMH ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >ทีมงานมืออาชีพ
            ประสบการณ์สูงจะเป็นผู้จัดการนำผู้ป่วยไปพบและปรึกษาแพทย์แทนท่าน
          </span>
        </div>
      </div>
    </b-button>
    <b-button
      v-b-hover="handleHoverA"
      class="mx-20px mt-15px"
      style="
        min-width: 335px;
        min-height: 80px;
        border-radius: 10px;
        border: solid 1px #f2f3f4;
      "
      :style="
        isHoveredA ? 'background-color: #4a9afa;' : 'background-color: white;'
      "
      @click="goToAmbulancePage"
    >
      <div class="d-flex align-items-center">
        <img
          v-if="isHoveredA"
          alt=""
          src="@/assets/icons/ambulance-service-white.png"
          srcset="
            @/assets/icons/ambulance-service-white@2x.png 2x,
            @/assets/icons/ambulance-service-white@3x.png 3x
          "
        />
        <img
          v-else
          alt=""
          src="@/assets/icons/ambulance-service.png"
          srcset="
            @/assets/icons/ambulance-service@2x.png 2x,
            @/assets/icons/ambulance-service@3x.png 3x
          "
        />
        <div class="d-flex flex-column ml-20px">
          <span
            class="text-left"
            :class="isHoveredA ? 'text-white' : 'text-black'"
            style="font-size: 1rem; font-weight: 500"
            >บริการรถพยาบาล
          </span>
          <span
            class="text-left"
            :class="isHoveredA ? 'text-white' : 'text-gray'"
            style="font-size: 0.75rem"
            >เรียกใช้บริการรถฉุกเฉินที่ผ่านการรับรองจาก สพฉ.
            สถาบันการแพทย์ฉุกเฉินแห่งชาติ
          </span>
        </div>
      </div>
    </b-button>
    <div class="bg-primary mt-70px pb-63px">
      <div class="d-flex flex-column">
        <span class="text-center text-navy" style="font-size: 5rem"> ,, </span>
        <span class="text-center text-white mt-40px" style="font-size: 1.75rem">
          เหตุผลที่ผู้ป่วยและ <br />ผู้สูงอายุ ต้องได้รับ<br />
          การดูแลจากผู้เชี่ยวชาญ
        </span>
        <span
          class="text-center text-white mx-30px mt-30px"
          style="font-size: 1rem"
        >
          ผู้ป่วยแต่ละคน จำเป็นต้องได้รับการวินิจฉัย รับการรักษาอย่างถูกต้อง
          และฟื้นฟูเพื่อให้อาการดีขึ้น
          จึงมีความจำเป็นที่ทีมผู้ดูแลจะต้องเป็นผู้มีความรู้เกี่ยวกับอาการของโรคต่างๆเพื่อให้ประเมินผลได้เหมาะสมยิ่งขึ้น
          สามารถให้การพยาบาลก่อนที่จะถึงมือแพทย์
          ทีมพยาบาลที่ได้รับมอบหมายให้ดูแลจะเป็นทีมที่ถูกประเมินแล้ว
          ว่าเหมาะสมกับคนไข้แต่ละท่าน สามารถให้การดูแลทั้งกลางวันและกลางคืน (24
          ชม) เพื่อให้ญาติคลายความกังวลในการให้การดูแลอย่างเต็มที่
        </span>
        <div class="d-flex flex-column align-self-center mx-20px mt-40px">
          <img
            class="cover-image-mx20"
            alt=""
            src="@/assets/images/home-2.png"
            srcset="
              @/assets/images/home-2.png 2x,
              @/assets/images/home-2.png 3x
            "
          />
          <div
            class="bg-white d-flex flex-column align-self-center w-100"
            style="
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 10px;
            "
          >
            <span
              class="text-center text-black pt-50px"
              style="font-size: 1.25rem; font-weight: 600"
              >ให้เราดูแลคุณ
              <br />
              คลิกเพื่อทำแบบประเมินเลย
            </span>
            <b-button
              pill
              class="text-white bg-primary mt-30px mb-41px mx-58px"
              size="lg"
              variant="primary"
              style="font-size: 0.875rem; font-weight: 500"
              @click="goToPreAssessmentPage"
              >ทำแบบประเมินการให้บริการ</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <ConsultExpert />
    <JoinUs />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ConsultExpert from '@/components/ConsultExpert.vue'
import JoinUs from '@/components/JoinUs.vue'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    ConsultExpert,
    JoinUs,
  },
  data() {
    return {
      isHoveredN: false,
      isHoveredNN: false,
      isHoveredOT: false,
      isHoveredPT: false,
      isHoveredMP: false,
      isHoveredSC: false,
      isHoveredV: false,
      isHoveredTMH: false,
      isHoveredA: false,
    }
  },
  methods: {
    handleHoverN(hovered) {
      this.isHoveredN = hovered
    },
    handleHoverNN(hovered) {
      this.isHoveredNN = hovered
    },
    handleHoverOT(hovered) {
      this.isHoveredOT = hovered
    },
    handleHoverPT(hovered) {
      this.isHoveredPT = hovered
    },
    handleHoverMP(hovered) {
      this.isHoveredMP = hovered
    },
    handleHoverSC(hovered) {
      this.isHoveredSC = hovered
    },
    handleHoverV(hovered) {
      this.isHoveredV = hovered
    },
    handleHoverTMH(hovered) {
      this.isHoveredTMH = hovered
    },
    handleHoverA(hovered) {
      this.isHoveredA = hovered
    },
    goToNursePage() {
      this.$router.push({
        name: 'Nurse',
      })
    },
    goToNutritionistPage() {
      this.$router.push({
        name: 'Nutritionist',
      })
    },
    goToOccupationalTherapistPage() {
      this.$router.push({
        name: 'OccupationalTherapist',
      })
    },
    goToPhysicalTherapistPage() {
      this.$router.push({
        name: 'PhysicalTherapist',
      })
    },
    goToMedicalProcedurePage() {
      this.$router.push({
        name: 'MedicalProcedure',
      })
    },
    goToSpecimenCollectionPage() {
      this.$router.push({
        name: 'SpecimenCollection',
      })
    },
    goToVaccinationPage() {
      this.$router.push({
        name: 'Vaccination',
      })
    },
    goToTakeMeToHospitalPage() {
      this.$router.push({
        name: 'TakeMeToHospital',
      })
    },
    goToAmbulancePage() {
      this.$router.push({
        name: 'Ambulance',
      })
    },
    goToPreAssessmentPage() {
      this.$router.push({
        name: 'PreAssessment',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100vw;
  max-width: 640px;
}
.cover-image-mx20 {
  width: calc(100vw - 40px);
  max-width: 600px;
  min-width: 335px;
}
.pt-46px {
  padding-top: 46px;
}
.pb-63px {
  padding-bottom: 63px;
}
.pl-16px {
  padding-left: 16px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-19px {
  margin-top: 19px;
}
.mt-29px {
  margin-top: 29px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-46px {
  margin-top: 46px;
}
.mt-48px {
  margin-top: 48px;
}
.mb-41px {
  margin-bottom: 41px;
}
.ml-22px {
  margin-left: 22px;
}
.ml-25px {
  margin-left: 25px;
}
.mr-34px {
  margin-right: 34px;
}
.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}
.mx-58px {
  margin-left: 58px;
  margin-right: 58px;
}
.mx-103px {
  margin-left: 103px;
  margin-right: 103px;
}
.text-black {
  color: #333333;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
