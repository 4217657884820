<template>
  <div>
    <input
      maxlength="6"
      type="number"
      class="position-absolute fixed-top"
      style="opacity: 0; width: 0px; height: 0px"
      :value="value"
      @input="onInputPasscode"
      pattern="[0-9]*"
      ref="input"
    />
    <div class="d-flex">
      <div
        class="passcode mr-15px"
        :class="{ active: value.length > 0, invalid: invalid }"
      ></div>
      <div
        class="passcode mr-15px"
        :class="{ active: value.length > 1, invalid: invalid }"
      ></div>
      <div
        class="passcode mr-15px"
        :class="{ active: value.length > 2, invalid: invalid }"
      ></div>
      <div
        class="passcode mr-15px"
        :class="{ active: value.length > 3, invalid: invalid }"
      ></div>
      <div
        class="passcode mr-15px"
        :class="{ active: value.length > 4, invalid: invalid }"
      ></div>
      <div class="passcode" :class="{ active: value.length > 5, invalid: invalid }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      passcode: '',
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    onInputPasscode(event) {
      const value = event.target.value
      if (String(value).length <= 6) {
        this.$emit('input', value)
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped>
.passcode {
  width: 27px;
  height: 27px;
  border-radius: 100px;
  background-color: #f5f6fa;
  border: none;
}

.passcode.invalid {
  background-color: #ff0000 !important;
}

.passcode.active {
  background-color: #0090d4;
}
</style>
