<template>
  <div class="d-flex flex-column bg-white pb-60px">
    <span
      class="text-left text-black mt-40px pl-20px"
      style="font-size: 1.25rem"
    >
      ขั้นตอนการใช้บริการ
    </span>
    <span
      class="text-left text-navy mt-40px pl-20px"
      style="font-size: 1.0625rem"
    >
      1.ปรึกษาเจ้าหน้าที่ผู้เชี่ยวชาญ
    </span>
    <span
      class="text-left text-gray mt-13px px-20px"
      style="font-size: 0.875rem"
    >
      เพียงโทรหาเราที่ เบอร์ 092-498-9779 หรือติดต่อ เราผ่านทาง Line Official
      Account @madoolae เพื่อขอคำแนะนำจากทีมพยาบาลผู้ชำนาญการดูแลผู้ป่วย
    </span>
    <span
      class="text-left text-navy mt-40px pl-20px"
      style="font-size: 1.0625rem"
    >
      2.วางแผนการดูแล
    </span>
    <span
      class="text-left text-gray mt-13px px-20px"
      style="font-size: 0.875rem"
    >
      ทีมงานพยาบาลผู้เชี่ยวชาญของเราจะออกแบบ วางแผนการดูแลที่ถูกต้องเหมาะสม
      และได้มาตรฐานร่วมกับผู้รับบริการ หรือตามที่แพทย์แนะนำ
    </span>
    <span
      class="text-left text-navy mt-40px pl-20px"
      style="font-size: 1.0625rem"
    >
      3.เริ่มต้นการดูแล
    </span>
    <span
      class="text-left text-gray mt-13px px-20px"
      style="font-size: 0.875rem"
    >
      พยาบาลของเราจะเข้าไปดูแลคนที่คุณรักตามแผนที่ได้จัดไว้
      พร้อมกับให้คุณสามารถติดตามรายละเอียด ผลการดูแล
      และบันทึกทางการพยาบาลได้ผ่านไลน์แอปพลิเคชัน
    </span>
    <span
      class="text-left text-navy mt-40px pl-20px"
      style="font-size: 1.0625rem"
    >
      4.ติดตามอาการผ่าน LINE ง่ายๆ
    </span>
    <span
      class="text-left text-gray mt-13px px-20px"
      style="font-size: 0.875rem"
    >
      ‘มา-ดู-แล’ ให้คุณติดตามสถานะ และดูบันทึกทางการพยาบาลของคนที่คุณรักได้ง่ายๆ
      สะดวกทุกที่ทุกเวลาผ่านไลน์แอปพลิเคชัน
    </span>
    <b-button
      pill
      class="text-white bg-primary mt-40px mx-88px"
      size="lg"
      variant="primary"
      style="font-size: 0.875rem; font-weight: 500"
      @click="openLineUrl"
      >LINE OFFICIAL</b-button
    >
  </div>
</template>
<script>
export default {
  methods: {
    openLineUrl() {
      const url = 'https://lin.ee/plTmL3Z'
      if (window.liff.getOS() === 'web') {
        window.open(url, '_blank')
      } else {
        window.liff.openWindow({
          url,
          external: false,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.mt-40px {
  margin-top: 40px;
}
.mx-88px {
  margin-left: 88px;
  margin-right: 88px;
}
.text-gray {
  color: #6b6b6b;
}
.text-navy {
  color: #01234b;
}
</style>
