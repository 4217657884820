import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    assessment: {
      services: [],
      questionnaires: {},
    },
  },
  mutations: {
    setServices(state, services) {
      state.assessment.services = services
    },
    addQuestionnaire(state, { id, answers }) {
      state.assessment.questionnaires[id] = answers
    },
    clearAssessment(state) {
      state.assessment = {
        services: [],
        questionnaires: {},
      }
    },
  },
  actions: {
    setServices({ commit }, services) {
      commit('setServices', services)
    },
    addQuestionnaire({ commit }, { id, answers }) {
      commit('addQuestionnaire', { id, answers })
    },
    clearAssessment({ commit }) {
      commit('clearAssessment')
    },
  },
  getters: {
    services: (state) => {
      return state.assessment.services
    },
    questionnairesById: (state) => (id) => {
      return state.assessment.questionnaires[id]
    },
    assessment: (state) => {
      return state.assessment
    },
    hasSelectedServiceNurse: (state) => {
      return (
        state.assessment.services.findIndex((service) => service.id === 1) >= 0
      )
    },
    hasSelectedService: (state) => {
      return (
        state.assessment.services != null &&
        state.assessment.services.length > 0
      )
    },
  },
  modules: {},
})
