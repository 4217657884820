<template>
  <div class="px-27px py-25px d-flex flex-column" @click="focusPasscode">
    <div class="mb-75px d-flex">
      <div
        class="px-8px py-5px cursor-pointer"
        style="
          border-radius: 8px;
          border: solid 1px #e2e6e8;
          background-color: #fff;
        "
      >
        <b-icon
          @click="back"
          icon="chevron-left"
          font-scale="1"
          style="color: #0f0a39"
        ></b-icon>
      </div>
    </div>
    <span class="password mb-58px align-self-center">กรุณาใส่รหัสผ่าน</span>
    <div
      class="align-self-center d-flex flex-column"
      :class="{ 'mb-144px': invalid, 'mb-174px': !invalid }"
    >
      <InputPasscode
        ref="inputPasscode"
        :invalid="invalid"
        class="mb-30px"
        v-model="passcode"
      />
      <div
        v-if="invalid"
        class="align-self-center mb-12px"
        style=" font-size: 0.875rem;
  line-height: 1.71;
  color: #f00;˝"
      >
        รหัสไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง
      </div>
      <div
        @click="forgotPassword"
        class="align-self-center forgot-passcode cursor-pointer"
      >
        ลืมรหัสผ่าน?
      </div>
    </div>
    <button
      type="button"
      :disabled="disabled"
      class="base btn w-100"
      @click="next"
    >
      ตกลง
    </button>
  </div>
</template>
<script>
import InputPasscode from '@/components/InputPasscode'
import { login } from '@/resources/auth.provider.js'
export default {
  components: {
    InputPasscode,
  },
  props: {
    username: {
      type: String,
    },
  },
  data() {
    return {
      page: 'passcode',
      passcode: '',
      invalid: false,
    }
  },
  mounted() {
    this.focusPasscode()
  },
  methods: {
    focusPasscode() {
      this.$refs.inputPasscode.focus()
    },
    async next() {
      await this.login()
    },
    back() {
      this.$router.back()
    },
    async forgotPassword() {
      this.$router.push({
        name: 'OTP',
        query: { username: this.username },
      })
    },
    async login() {
      try {
        const { data } = await login({
          username: this.username,
          password: this.passcode,
        })
        localStorage.setItem('token', data.token)
        localStorage.setItem('refreshToken', data.refreshToken)
        this.$notify({
          type: 'success',
          title: 'เข้าสู่ระบบสำเร็จ',
          duration: 2000,
        })
        this.$router.push({
          name: 'Profile',
        })
      } catch (error) {
        this.invalid = true
      }
    },
  },
  computed: {
    disabled() {
      return this.passcode.length !== 6
    },
  },
  watch: {
    passcode(passcode) {
      if (passcode.length !== 6) {
        this.invalid = false
      }
    },
  },
}
</script>

<style scoped>
.password {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0f0a39;
}
.forgot-passcode {
  font-family: SukhumvitSet-Text;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #0089da;
}

button.base {
  height: 44px;
  color: #fff;
  padding: 10px 139px;
  border-radius: 8px;
  background-color: #0090d4;
  border: none;
}

button.base:disabled {
  opacity: 1;
  background-color: #d6d9de;
}
</style>
